import { Box, Container, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useParams, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import BoxInfoPatient from '../../components/BoxInfoPatient/BoxInfoPatient';
import { SideMenu } from '../../components/SideMenu';
import { Citologia } from './Exams/Citologia';
import { HpvForm } from './Exams/Hpv';
import { useEffect, useMemo, useState } from 'react';
import Colposcopia from './Exams/Colposcopia';
import { Histopatologia } from './Exams/Histopatologia';
import api from '../../api';
import { CategoriaProcedimentoEnum } from '../../types/CategoriaProcedimento';
import Template from '../../components/Template';

interface StateProps {
  examData: {
    categoria: {
      id: number;
      nome: string;
    };
    citologia: {} | null;
    colposcopia: {} | null;
    histopatologia: {} | null;
    hpv: {} | null;
    id?: number;
  };
  patientData: {
    id: number;
  };
}

interface PatientData {
  id: number;
}

export const ViewExam = () => {
  // const styles = useStyles();
  const location = useLocation();
  const state = location.state
    ? (location.state as StateProps)
    : JSON.parse(String(localStorage.getItem('state')));

  const { id: patientId } = useParams();
  // const navigate = useNavigate();
  const [headerTitle, setHeaderTitle] = useState('');
  const [patientData, setPatientData] = useState({} as PatientData);

  const examType: number = useMemo(() => {
    if (state?.examData?.categoria?.id) {
      return state?.examData?.categoria?.id;
    }
    return 0;
  }, [state?.examData?.categoria?.id]);

  const examName: string = useMemo(() => {
    if (state?.examData?.categoria?.nome) {
      return state?.examData?.categoria?.nome;
    }
    return '';
  }, [state?.examData?.categoria?.nome]);

  const renderExamForm = () => {
    switch (examType) {
      case CategoriaProcedimentoEnum.Citologia_Como_Rastreio:
        return (
          <Citologia
            data={state.examData.citologia}
            examId={state.examData.id}
          />
        );
      case CategoriaProcedimentoEnum.Citologia_Reflexo:
        return (
          <Citologia
            data={state.examData.citologia}
            examId={state.examData.id}
          />
        );
      case CategoriaProcedimentoEnum.Teste_de_HPV:
        return <HpvForm data={state.examData.hpv} examId={state.examData.id} />;
      case CategoriaProcedimentoEnum.Colposcopia:
        return (
          <Colposcopia
            data={state.examData.colposcopia}
            examId={state.examData.id}
          />
        );
      case CategoriaProcedimentoEnum.Histopatologia:
        return (
          <Histopatologia
            // data={state.examData.histopatologia}
            examId={state.examData.histopatologia?.id}
            procedimentoId={state.examData.id}
          />
        );
      default:
        return <div>Exame não encontrado</div>;
    }
  };

  useEffect(() => {
    setHeaderTitle(examName);
  }, [examName]);

  useEffect(() => {
    async function loadData(id: any) {
      await api
        .get(`/pessoas/${patientId}`)
        .then((response) => {
          setPatientData(response.data);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    loadData(patientId);
  }, [patientId]);

  return (
    <SideMenu>
      <Container>
        <Box mt={5}>
          <Box
            mb={3}
            justifyContent="space-between"
            display="flex"
            flexWrap="wrap"
          >
            <div>
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{ marginBottom: '5px' }}
              >
                Exame: {headerTitle}
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/pacientes"
                >
                  Listagem de Pacientes
                </Link>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to={`/pacientes/${patientData.id}`}
                >
                  Ficha Individual da Paciente
                </Link>
                <Typography key="3" color="text.primary">
                  Exame: {headerTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </Box>

          <Box mb={3}>
            <BoxInfoPatient
              data={patientData}
              viewButton={false}
            ></BoxInfoPatient>
          </Box>

          {renderExamForm()}
        </Box>
      </Container>
    </SideMenu>
  );
};
