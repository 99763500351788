import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../../../components/StyledAsteriskTextField/styles';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';
import api from '../../../../../api';
import MultipleFilters from '../../../../../components/MultipleFilters';
import useStatesFields from '../../../../../components/MultipleFilters/hooks/useStatesFields';
import { getUserToken } from '../../../../../lib/auth';

const FiltersVisaoGeral = ({
  control,
  setPayload,
  payload,
  getAllCharts,
  setListFilter,
  initialStateListFilter,
  setShowListFilter,
  listFilter,
  estabelecimentosSelected,
  estadosSelected,
  municipiosSelected,
  setEstabelecimentosSelected,
  setEstadosSelected,
  setMunicipiosSelected,
  valueInputPeriod,
  setValueInputPeriod,
  setPeriodToDisplay,

  setOrigemSelecionada,

  setTotal,
  setBenignLesionsData,
  setGlandularPreNeoplasticData,
  setHistopathologyOriginData,
  setSquamousPreNeoplasticData,
  setHistopatologiaDataTable,
  setPaginationState,
  setTotalTestsPerMonth,
  setNeedsRequiredFilters,
  period,
  setPeriod,
  valuePeriodoModal,
  setValuePeriodoModal,
  origemSelected,
  setOrigemSelected,
}) => {
  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [year, setYear] = useState<any>('');
  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState<any>();
  const [firstLoadPage, setFirstLoadPage] = useState(true);

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,

    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    disableInputEstado,

    errorsFields,
    setErrorsFields,
  } = useStatesFields();
  const [isFilterButtonEnabled, setIsFilterButtonEnabled] = useState(false);

  const optionsPeriodo = [
    { label: 'Todos', value: 'all' },
    {
      label: 'Últimos 12 meses',
      value: 'twelve_months',
    },
    {
      label: 'Quadrimestral',
      value: 'four_months',
    },
    { label: 'Semestral', value: 'six_months' },
    { label: 'Anual', value: 'yearly' },
  ];
  const RequiredLabel = ({ children }) => (
    <Box component="span">
      {children}
      <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>
        *
      </Box>
    </Box>
  );
  const optionsQuadrimestre = [
    '1º Quadrimestre',
    '2º Quadrimestre',
    '3º Quadrimestre',
  ];
  const optionsSemestre = ['1º Semestre', '2º Semestre'];

  const optionsOrigem = ['Biópsia', 'Peça Cirúrgica'];

  const setValuesInLastTwelveMonths = () => {
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    setPeriod({
      periodo_fim: today,
      periodo_inicio: twelveMonths,
    });
  };

  const setValuesAllPeriodSelected = () => {
    setValueInputPeriod('Todos');

    setPeriod({ periodo_inicio: '2022-01-01' });
  };

  useEffect(() => {
    if (period && period.periodo_inicio && period.periodo_fim) {
      handleSetFilter(
        moment(period.periodo_inicio, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        'periodo_inicio'
      );
      handleSetFilter(
        moment(period.periodo_fim, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        'periodo_fim'
      );
    } else {
      handleSetFilter('Período: Todos os períodos', 'periodo_inicio');
      handleSetFilter('', 'periodo_fim');
    }
  }, [period]);

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    setValueInputPeriod(newValue?.label || null);

    if (!newValue) {
      resetPeriodValues();
      setIsFilterButtonEnabled(false);
      return;
    }

    switch (newValue.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        setIsFilterButtonEnabled(true);
        break;
      case 'four_months':
      case 'six_months':
      case 'yearly':
        setPeriod(undefined);
        setYear(null);
        setValueInputQuadrimestreSemestre(null);
        setIsFilterButtonEnabled(false);
        break;
      case 'all':
        setValuesAllPeriodSelected();
        setIsFilterButtonEnabled(true);
        break;
      default:
        setIsFilterButtonEnabled(false);
        break;
    }
  };

  const getSearch = () => {
    getAllCharts({ cleared: false, payload: payload });
    setShowListFilter(listFilter);
    setPeriodToDisplay(valueInputPeriod);
    setOrigemSelecionada(origemSelected);
  };

  const verifyIfHasPeriodSelected = () => {
    if (!period?.periodo_fim && !period?.periodo_inicio) {
      setValuesAllPeriodSelected();
      setValuePeriodoModal({
        label: 'Todos',
        value: 'all',
      });
    }
  };

  const verifyIfHasEstabelecimentoSelected = () => {
    if (estabelecimentosSelected.length === 0) {
      const arrIds = optionsEstabelecimentos
        .filter((estab) => estab.id !== 'null')
        .map((estab) => estab.id);

      setEstabelecimentosSelected(arrIds);

      return arrIds;
    }
  };

  const verifyIfHasOrigemSelected = () => {
    if (!origemSelected) {
      setOrigemSelected('Biópsia');
      setOrigemSelecionada('Biópsia');
    }
  };

  const handleSearch = () => {
    const selectedMoreThanOneEstado = estadosSelected.length > 1;
    const selectedAllEstados = estadosSelected.length >= estadosOptions.length;
    const emptyEstado = estadosSelected.length === 0;
    const emptyMunicipio = municipiosSelected?.length === 0;

    if (isSuperAdmin) {
      if (emptyEstado) {
        setErrorsFields((prev) => [...prev, 'uf']);
      }

      if (
        emptyMunicipio &&
        (!selectedAllEstados || !selectedMoreThanOneEstado)
      ) {
        setErrorsFields((prev) => [...prev, 'municipio']);
      }

      if (
        !emptyEstado &&
        (!emptyMunicipio || selectedAllEstados || selectedMoreThanOneEstado)
      ) {
        setErrorsFields([]);
        verifyIfHasPeriodSelected();
        verifyIfHasEstabelecimentoSelected();
        verifyIfHasOrigemSelected();
        getSearch();
      }
    }

    if (isEstadual) {
      if (emptyMunicipio) {
        setErrorsFields((prev) => [...prev, 'municipio']);
      } else {
        setErrorsFields([]);
        verifyIfHasPeriodSelected();
        verifyIfHasEstabelecimentoSelected();
        verifyIfHasOrigemSelected();

        getSearch();
      }
    }

    if (isMunicipal) {
      setErrorsFields([]);
      verifyIfHasPeriodSelected();
      verifyIfHasEstabelecimentoSelected();
      verifyIfHasOrigemSelected();

      getSearch();
    }

    if (isAdminLaboratorio) {
      if (emptyMunicipio) {
        setErrorsFields((prev) => [...prev, 'municipio']);
      } else {
        setErrorsFields([]);
        verifyIfHasPeriodSelected();
        verifyIfHasEstabelecimentoSelected();
        verifyIfHasOrigemSelected();

        getSearch();
      }
    }
  };

  const clearData = () => {
    setTotal('');
    setBenignLesionsData([]);
    setGlandularPreNeoplasticData([]);
    setHistopathologyOriginData([]);
    setSquamousPreNeoplasticData([]);
    setHistopatologiaDataTable({
      data: [],
      totals: {},
      totalItems: 0,
      currentPage: 1,
      itemsPerPage: 25,
      localizacaoType: '',
    });
    setTotalTestsPerMonth([]);
    setPaginationState({ currentPage: 1, itemsPerPage: 25, totalItems: 0 });
  };

  const handleClearFilter = () => {
    // setValuesAllPeriodSelected();
    setPeriod(null);
    setValueInputPeriod(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
    setYear(null);

    setPeriodToDisplay('');
    setOrigemSelecionada();

    if (!isPadrao) {
      setEstabelecimentosSelected([]);
    }

    if (!isMunicipal && !isPadrao) {
      setMunicipiosSelected([]);
    }

    if (!isMunicipal && !isPadrao && !isEstadual) {
      setEstadosSelected([]);
    }
    setSearchEquipes('');
    setSearchEstabelecimentos('');
    setSearchEstado('');
    setSearchMunicipio('');
    setOrigemSelected('');

    if (!isMunicipal) {
      setShowListFilter([]);
    }
    if (isSuperAdmin) {
      const listFilter = [
        {
          inputName: 'estado',
          value: ``,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        {
          inputName: 'estabelecimento_saude',
          value: '',
        },
        { inputName: 'equipe', value: '' },
        {
          inputName: 'periodo_inicio',
          value: '',
        },
        { inputName: 'periodo_fim', value: '' },
        { inputName: 'origem', value: '' },
      ];

      setListFilter(listFilter);
    }
    if (isEstadual || isAdminLaboratorio) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        {
          inputName: 'estabelecimento_saude',
          value: '',
        },
        { inputName: 'equipe', value: '' },
        {
          inputName: 'periodo_inicio',
          value: '',
        },
        { inputName: 'periodo_fim', value: '' },
        { inputName: 'origem', value: '' },
      ];

      setListFilter(listFilter);
    }

    if (isMunicipal) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: `Município: ${userToken.estabelecimento_saude?.endereco.municipio.nome}`,
        },
        { inputName: 'estabelecimento_saude', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: '' },
        { inputName: 'periodo_fim', value: '' },
        { inputName: 'origem', value: '' },
      ];

      setListFilter(listFilter);
      setShowListFilter(listFilter);
    }

    if (isSuperAdmin || isEstadual || isAdminLaboratorio) {
      clearData();
    } else {
      getAllCharts({ cleared: true });
    }
  };

  const userToken = getUserToken();

  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  const isPadrao = userToken.permissao_atual?.id === 1;
  const isMunicipal = userToken.permissao_atual?.id === 3;
  const isEstadual = userToken.permissao_atual?.id === 4;
  const isAdminLaboratorio = userToken.permissao_atual?.id === 6;

  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    if (
      valueInputPeriod === 'Quadrimestral' ||
      valueInputPeriod === 'Semestral'
    ) {
      setIsFilterButtonEnabled(!!valueInputQuadrimestreSemestre);
    } else if (valueInputPeriod === 'Anual') {
      setIsFilterButtonEnabled(true);
      setPeriod({
        periodo_inicio: `${newValue}-01-01`,
        periodo_fim: `${newValue}-12-31`,
      });
    }
  };

  useEffect(() => {
    if (
      valueInputPeriod === 'Quadrimestral' ||
      valueInputPeriod === 'Semestral' ||
      valueInputPeriod === 'Anual'
    ) {
      if (!year) {
        setIsFilterButtonEnabled(false);
      }
    }
  });
  const handleChangeSemestreOrQuadrimestre = (
    newValue,
    selectedYear = year
  ) => {
    setValueInputQuadrimestreSemestre(newValue);
    if (!selectedYear || !newValue) {
      setIsFilterButtonEnabled(false);
      return;
    }
    setIsFilterButtonEnabled(true);
    const periods = {
      '1º Semestre': { inicio: '01-01', fim: '06-30' },
      '2º Semestre': { inicio: '07-01', fim: '12-31' },
      '1º Quadrimestre': { inicio: '01-01', fim: '04-30' },
      '2º Quadrimestre': { inicio: '05-01', fim: '08-31' },
      '3º Quadrimestre': { inicio: '09-01', fim: '12-31' },
    };

    const selectedPeriod = periods[newValue];
    if (selectedPeriod) {
      setPeriod({
        periodo_inicio: `${selectedYear}-${selectedPeriod.inicio}`,
        periodo_fim: `${selectedYear}-${selectedPeriod.fim}`,
      });
    } else {
      console.error('Período inválido selecionado');
      setIsFilterButtonEnabled(false);
    }
  };

  const handleCloseModal = () => {
    if (valueInputPeriod && year) {
      if (
        valueInputPeriod === 'Quadrimestral' ||
        valueInputPeriod === 'Semestral'
      ) {
        handleChangeSemestreOrQuadrimestre(valueInputQuadrimestreSemestre);
      } else if (valueInputPeriod === 'Anual') {
        setPeriod({
          periodo_inicio: `${year}-01-01`,
          periodo_fim: `${year}-12-31`,
        });
      }
    } else if (valueInputPeriod === 'Últimos 12 meses') {
      setValuesInLastTwelveMonths();
    } else if (valueInputPeriod === 'Todos') {
      setValuesAllPeriodSelected();
    } else {
      resetPeriodValues();
    }
    setOpenModalPeriodo(false);
  };

  const handleSetFilter = (newValue, inputName) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };
  useEffect(() => {
    if (
      year &&
      (valueInputPeriod === 'Quadrimestral' || valueInputPeriod === 'Semestral')
    ) {
      handleChangeSemestreOrQuadrimestre(valueInputQuadrimestreSemestre, year);
    }
  }, [year, valueInputPeriod, valueInputQuadrimestreSemestre]);

  const resetPeriodValues = () => {
    setValuesAllPeriodSelected();
    // setPeriod({});
    // setYear('');
    // setValueInputPeriod('Todos');
    // setValuePeriodoModal(null);
    // setValueInputQuadrimestreSemestre('');
  };

  const handleSetFiltersInMultiplesInputs = ({
    value,
    fieldName,
    arrayValues,
  }) => {
    const convertedFieldNames = {
      estado: 'Estados',
      municipio: 'Municípios',
      estabelecimento_saude: 'Estabelecimentos',
      equipe: 'Equipes',
      origem: 'Origem',
    };
    if (arrayValues.length > 1) {
      handleSetFilter(`${convertedFieldNames[fieldName]}: Vários`, fieldName);
    } else {
      handleSetFilter(`${convertedFieldNames[fieldName]}: ${value}`, fieldName);
    }
  };

  useEffect(() => {
    if (estadosSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: estadosSelected[0] ?? '',
        fieldName: 'estado',
        arrayValues: estadosSelected,
      });
      setDisabledMunicipioInput(isPadrao || isMunicipal ? true : false);
    } else {
      setDisabledMunicipioInput(true);
      setDisabledEstabelecimentoInput(true);
      setDisableEquipeInput(true);
      handleSetFilter(null, 'estado');
    }

    if (estadosSelected.length > 1 || estadosSelected.length === 0) {
      if (!isPadrao) {
        setEstabelecimentosSelected([]);
      }
      setMunicipiosSelected([]);
      setEquipesSelected([]);
      setDisabledMunicipioInput(true);
      setDisabledEstabelecimentoInput(true);
      setDisableEquipeInput(true);
    } else {
      setDisabledMunicipioInput(isPadrao || isMunicipal ? true : false);
    }
  }, [estadosSelected]);

  useEffect(() => {
    if (!isPadrao) {
      setEstabelecimentosSelected([]);
    }
    if (municipiosSelected.length > 0) {
      const municipioNome = optionsMunicipios?.find(
        (municipio) => municipio.id === municipiosSelected[0]
      )?.nome;
      handleSetFiltersInMultiplesInputs({
        value: municipioNome ?? '',
        fieldName: 'municipio',
        arrayValues: municipiosSelected,
      });
      setDisabledEstabelecimentoInput(isPadrao || isMunicipal ? true : false);
    } else {
      setDisabledEstabelecimentoInput(true);
      setDisableEquipeInput(true);
      handleSetFilter(null, 'municipio');
    }

    if (municipiosSelected.length > 1 || municipiosSelected?.length === 0) {
      setDisabledEstabelecimentoInput(true);
    } else {
      setDisabledEstabelecimentoInput(isPadrao || isMunicipal ? true : false);
    }
  }, [municipiosSelected]);

  useEffect(() => {
    if (estabelecimentosSelected.length === 0) {
      handleSetFilter(
        'Estabelecimento de Saúde: Vários',
        'estabelecimento_saude'
      );
    }
  }, [estabelecimentosSelected]);

  useEffect(() => {
    if (!origemSelected) {
      handleSetFilter('Origem: Biópsia', 'origem');
    }
  }, [origemSelected]);

  // useEffect(() => {
  //   const estabelecimentoNome =
  //     optionsEstabelecimentos?.find(
  //       (item) => estabelecimentosSelected[0] === item.id
  //     )?.nome ?? '';

  //   if (estabelecimentosSelected.length > 0) {
  //     handleSetFiltersInMultiplesInputs({
  //       value: estabelecimentoNome,
  //       fieldName: 'estabelecimento_saude',
  //       arrayValues: estabelecimentosSelected,
  //     });
  //     setDisableEquipeInput(false);
  //   } else {
  //     setDisableEquipeInput(true);
  //     handleSetFilter(null, 'estabelecimento_saude');
  //   }

  //   const hasSelectedNoBind = !!estabelecimentosSelected.find(
  //     (item) => item === 'null'
  //   );

  //   if (
  //     estabelecimentosSelected.length > 1 ||
  //     estabelecimentosSelected.length === 0
  //   ) {
  //     setDisableEquipeInput(true);
  //   } else {
  //     setDisableEquipeInput(false);
  //   }
  //   if (hasSelectedNoBind) {
  //     setDisableEquipeInput(true);
  //   }
  // }, [estabelecimentosSelected]);

  useEffect(() => {
    if (equipesSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: equipesSelected[0] ?? '',
        fieldName: 'equipe',
        arrayValues: equipesSelected,
      });
    } else {
      handleSetFilter(null, 'equipe');
    }
  }, [equipesSelected]);

  // useEffect(() => {
  //   handleSetFiltersInMultiplesInputs({
  //     value: origemSelected,
  //     fieldName: 'origem',
  //     arrayValues: [origemSelected],
  //   });
  // }, [origemSelected]);

  useEffect(() => {
    const getEquipes = async () => {
      const hasSelectedNoBind = !!estabelecimentosSelected.find(
        (item) => item === 'null'
      );
      if (estabelecimentosSelected.length === 1) {
        if (!hasSelectedNoBind) {
          const response = await api.get('/equipe/estabelecimento/get', {
            params: {
              estabelecimentoId: estabelecimentosSelected[0],
            },
          });
          setArrayEquipes([
            {
              id: 'null',
              municipio: '',
              nome: 'SEM VINCULAÇÃO',
              quantidade: '',
              label: '',
            },
            ...response.data,
          ]);
          setAllEquipesOptions([
            {
              id: 'null',
              municipio: '',
              nome: 'SEM VINCULAÇÃO',
              quantidade: '',
              label: '',
            },
            ...response.data,
          ]);
        }
      }

      if (estabelecimentosSelected.length === 0) {
        setArrayEquipes([]);
      }
    };

    getEquipes();
  }, [estabelecimentosSelected]);

  useEffect(() => {
    const loadEstados = async () => {
      try {
        const response = await api.get('/estados/histopatologia');
        const estados = response.data;
        setEstadosOptions(estados);
        setAllEstadosOptions(estados);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
        // setEstadosLoadError(true);
      }
    };

    loadEstados();
  }, []);

  useEffect(() => {
    setPayload({
      params: {
        estados:
          isPadrao || isMunicipal || isEstadual
            ? 'PE'
            : estadosSelected.length > 0
            ? JSON.stringify(estadosSelected)
            : undefined,
        municipios:
          isPadrao || isMunicipal
            ? userToken.endereco?.municipio_id
            : municipiosSelected?.length > 0
            ? municipiosSelected.join(', ')
            : undefined,
        estabelecimentos: isPadrao
          ? userToken.estabelecimentoId
          : estabelecimentosSelected.length > 0
          ? estabelecimentosSelected.join(', ')
          : undefined,
        equipe:
          equipesSelected.length > 0 ? equipesSelected.join(', ') : undefined,
        periodo_inicio: period?.periodo_inicio
          ? period.periodo_inicio
          : undefined,
        periodo_fim: period?.periodo_fim ? period.periodo_fim : undefined,
        origem: origemSelected,
      },
    });
  }, [
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    period,
    origemSelected,
  ]);

  useEffect(() => {
    const getOptionsCidades = async () => {
      if (estadosSelected.length === 1) {
        const optionsCidades = await getCidades(estadosSelected[0]);
        setOptionsMunicipios(optionsCidades);
        setAllMunicipiosOptions(optionsCidades);
      }

      if (estadosSelected.length === 0) {
        // setOptionsMunicipios([]);
      }
    };

    getOptionsCidades();
  }, [estadosSelected]);

  useEffect(() => {
    const getOptionsEstabelecimentos = async () => {
      if (municipiosSelected.length === 1) {
        const optionsEstabelecimentos = await api.get(
          'dashboard/exames/histopatologia/panel/estabelecimentos',
          {
            params: {
              estados:
                estadosSelected.length > 0
                  ? JSON.stringify(estadosSelected)
                  : undefined,
              municipios:
                municipiosSelected?.length > 0
                  ? JSON.stringify(municipiosSelected)
                  : undefined,
            },
          }
        );

        setOptionsEstabelecimentos([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);

        setAllEstabelecimentosOptions([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);

        if (isPadrao) {
          setEstabelecimentosSelected([userToken.estabelecimentoId]);
        }

        if (firstLoadPage) {
          setFirstLoadPage(false);

          const estabelecimentosIds = optionsEstabelecimentos.data.map(
            (estab) => estab.id
          );

          setEstabelecimentosSelected([...estabelecimentosIds, 'null']);
        }
      }

      if (municipiosSelected.length === 0) {
        if (!isPadrao) {
          setOptionsEstabelecimentos([]);
        }
      }
    };

    getOptionsEstabelecimentos();
  }, [municipiosSelected]);

  return (
    <>
      <Dialog
        open={openModalPeriodo}
        onClose={() => setOpenModalPeriodo(false)}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Tipo de Período
          </Typography>
          <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
            <Paper
              variant="outlined"
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Selecione o Período para Visualização dos Dados
              </Typography>
              <Autocomplete
                placeholder="Tipo de Período"
                value={valuePeriodoModal}
                onChange={(_, newValue: any) => {
                  handleSelectPeriodModal(newValue);
                }}
                options={optionsPeriodo}
                getOptionLabel={(option) => option.label ?? ''}
                renderInput={(params) => (
                  <StyledAsteriskTextField
                    {...params}
                    fullWidth
                    label="Tipo de Período"
                    variant="outlined"
                  />
                )}
              />
              {valueInputPeriod === 'Últimos 12 meses' ||
              valueInputPeriod === 'Todos' ||
              !valueInputPeriod ? undefined : (
                <Autocomplete
                  options={['2024', '2023', '2022']}
                  onChange={(_, newValue) => {
                    handleChangeWhenYearSelected(newValue);
                  }}
                  value={year}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<RequiredLabel>Selecione o Ano</RequiredLabel>}
                    />
                  )}
                />
              )}
              {}

              {(valueInputPeriod === 'Quadrimestral' ||
                valueInputPeriod === 'Semestral') && (
                <Autocomplete
                  options={
                    valueInputPeriod === 'Quadrimestral'
                      ? optionsQuadrimestre
                      : optionsSemestre
                  }
                  value={valueInputQuadrimestreSemestre}
                  onChange={(_, newValue) => {
                    handleChangeSemestreOrQuadrimestre(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <RequiredLabel>
                          {valueInputPeriod === 'Quadrimestral'
                            ? 'Selecione o Quadrimestre'
                            : 'Selecione o Semestre'}
                        </RequiredLabel>
                      }
                    />
                  )}
                />
              )}
            </Paper>
          </DialogContent>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleCloseModal();
            }}
            startIcon={<ClearIcon />}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModalPeriodo(false)}
            endIcon={<CheckIcon />}
            disabled={!isFilterButtonEnabled && valueInputPeriod != 'Todos'}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <MultipleFilters
          requiredMunicipio={estadosSelected.length > 1 ? false : true}
          setFirstLoadPage={setFirstLoadPage}
          routeGetEstabelecimentos="/dashboard/rastreamento/hpv/estabelecimentos"
          disableInputEstado={disableInputEstado}
          handleSetFilter={handleSetFilter}
          mdEstado={2}
          mdMunicipio={2}
          mdEstabelecimento={2}
          mdEquipe={2}
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
        />
        <Grid item xs={12} md={2}>
          <Autocomplete
            options={optionsOrigem}
            value={origemSelected}
            onChange={(_, newValue) => {
              handleSetFilter(`Origem: ${newValue}`, 'origem');
              setOrigemSelected(newValue);
            }}
            renderInput={(params) => (
              <StyledAsteriskTextField
                {...params}
                fullWidth
                label="Origem"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            name="periodo"
            control={control}
            render={({}) => {
              return (
                <StyledAsteriskTextField
                  data-testid="periodoInput"
                  fullWidth
                  size="small"
                  label="Período"
                  value={valueInputPeriod ? valueInputPeriod : ''}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => setOpenModalPeriodo(true)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            variant="contained"
            data-testid="submitFilterPanelHpv"
            onClick={() => {
              handleSearch();
            }}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            data-testid="clearFilterPanelHpv"
            variant="outlined"
            color="error"
            onClick={() => {
              setNeedsRequiredFilters(true);
              handleClearFilter();
            }}
          >
            Limpar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersVisaoGeral;
