import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ButtonIcon, ButtonUpdate, WarningIcon } from './ModalStatus.style';
import api from '../../api';
import { Check, Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { formatCpf, formatDate } from '../../utils/formatters';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 432,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  padding: '16px 24px',
};

const schema = Yup.object({
  status: Yup.string(),
});

// interface ExamInfo {
//   status: string;
//   updated_at?: Date;
// }

interface Inputs {
  status: string;
}

export interface User {
  id: number;
  usuario: string;
  email: string;
  pessoa: {
    id: number;
    nome: string;
    apelido: string;
    cpf: number;
    dt_nascimento: string;
    cns: string;
    raca: string;
    nacionalidade: string;
    nome_mae: string;
    sexo: string;
    contato_preferencial: string;
    escolaridade: string;
    orientacao: number;
    identidade_genero: number;
  };
}

export interface TokenData {
  aud: string;
  exp: number;
  iat: number;
  scope: string;
  sub: number;
  user: User;
}

export default function ModalDeletePatient({
  setModal,
  open,
  patientData,
}) {
  
  const handleClose = () => setModal(false);
  const [disabled, setDisabled] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [errored, setErrored] = useState<null|string>(null);
  const [examsDeleted, setExamsDeleted] = useState<number>(0);
  const [countdown, setCountdown] = useState(2);

  const navigate = useNavigate();

  const initialize = async () => {
    if(!open)
      return
    setErrored(null);
    try {
      const res: any = await api.get(`/excludes/info/paciente/${patientData?.id}`);
      const data = res.data;
      const exames = (data?.info?.procedimentos?.length || 0) + (data?.info?.hpvs_n_att?.length || 0)
      setExamsDeleted(exames)

    } catch(err: any) {
      setErrored(err?.error?.message);
    }

  }

  useEffect(() => {
    initialize();
  }, [open, patientData])

  useEffect(() => {
    setDisabled(true)
    setCountdown(10)
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      setDisabled(false);
      clearInterval(timer);
    }, 10200);

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
      setDisabled(true);
      setCountdown(2);
    };
  }, [open]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setSubmited(true);
    const res: any = await api.delete(`/excludes/paciente/${patientData?.id}`);

    if (res?.data) {
      handleClose();
      setTimeout(() => {
        navigate('/pacientes')
      }, 200);
    }
    setSubmited(false);
  };

  
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Typography
              id="modal-title"
              variant="h6"
              component="h6"
              fontWeight="bold"
            >
            </Typography>
            <ButtonIcon onClick={handleClose}>
              <CloseIcon fontSize="small" style={{ color: '#BDBDBD' }} />
            </ButtonIcon>
          </Box>

          <Typography
            id="modal-title"
            variant="h5"
            component="h5"
            align='center'
            fontWeight="bold"
          >
            Excluir a Paciente?
          </Typography>

          <WarningIcon color='error'/>

          <form onSubmit={onSubmit}>

            <Typography
              fontSize='14px'
              textAlign='left'
              mb={.2}
            >
              Exclusão da paciente: <b>{patientData?.nome || "Não Informada"}</b>
            </Typography>
            <Typography
              fontSize='14px'
              textAlign='left'
              mb={.2}
            >
              CPF: <b>{patientData?.cpf ? formatCpf(patientData?.cpf) : "Não Cadastrado"}</b>
            </Typography>
            <Typography
              fontSize='14px'
              textAlign='left'
              mb={1.2}
            >
              CNS: <b>{patientData?.cns || "Não Cadastrado"}</b>
            </Typography>
            {
              errored ? (
                <Box>
                  <Typography
                    fontSize='14px'
                    textAlign='center'
                    mb={0.2}
                  >
                    Erro ao excluir paciente.
                  </Typography>
                  <Typography
                    fontSize='14px'
                    textAlign='center'
                    mb={0}
                  >
                    Erro: {errored}
                  </Typography>
                </Box>
              ) : examsDeleted > 0 ? (
                <Typography
                  fontSize='14px'
                  textAlign='center'
                  mb={0}
                >
                  Ao Excluir a Paciente os {examsDeleted} Exame{examsDeleted > 1 ? 's' : ''} Serão Excluídos
                </Typography>
              ) : ''
            }
            <Box mt={4}>
              <Box display="flex" justifyContent="center" gap='5rem'>
                {!errored && (
                  <ButtonUpdate
                    variant="contained"
                    startIcon={<Check />}
                    disabled={disabled || submited || errored !== null}
                    type="submit"
                    data-testid={`button-confirm-delete`}
                  >
                    {disabled ? `Aguarde ${countdown}s..` : "Confirmar"}
                  </ButtonUpdate>
                )}
                <Button
                  variant="outlined"
                  startIcon={<Close />}
                  disabled={submited}
                  onClick={() => {handleClose()}}
                  color='error'
                  data-testid={`button-cancel-delete`}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
