import {
  Divider,
  Table,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import ButtonWithMenu from '../../ButtonWithMenu';
import TableHeaders from './Components/ColumnsHeaders';
import Rows from './Components/Rows';
import useSetPayload from '../../../pages/Reports/ReportsHpv/FiltersReportsHpv/hooks/useSetPayload';
import useRequestFilter from '../../../pages/Reports/ReportsHpv/FiltersReportsHpv/hooks/useRequestFilter';
import { DownloadFilesReportsHPV } from '../../../lib/Reports/downloadFilesReportsHPV';
import { useStyles } from '.';
import { useState } from 'react';
import TotalPatientsTablesReports from '../../TotalPatientsTablesReports';

const TableReportsHpv = ({
  tableData,
  setValue,
  watch,
  setTableData,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  equipesSelected,
  showTableData,
  dtColetaFinal,
  dtColetaIni,
  dtResultadoIni,
  dtResultadoFinal,
  selectedOptinsProfissionalResponsavel,
  selectedOptionsLaboratorio,
}) => {
  const [openModalAmostragem, setOpeModalAmostragem] = useState(false);

  const firstRowDisplayed = (tableData?.page - 1) * tableData?.count + 1;
  const lastRowDisplayed = Math.min(
    tableData?.page * tableData?.count,
    tableData?.totalItems
  );
  const totalItems = tableData?.totalItems;

  const stringToModalExportActualList = `${firstRowDisplayed}–${lastRowDisplayed} de ${totalItems}`;

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    dtColetaFinal,
    dtColetaIni,
    dtResultadoIni,
    dtResultadoFinal,
    selectedOptinsProfissionalResponsavel,
    selectedOptionsLaboratorio,
  });
  const { loadReportsHpv } = useRequestFilter({ setTableData });
  const classes = useStyles();

  const handleSizeChange = (e) => {
    setTableData([]);

    setValue('size', e.target.value);
    setPayload({}).then((payload) => loadReportsHpv(payload));
  };

  const handlePageChange = (e, newPage) => {
    setTableData([]);
    setValue('page', newPage + 1);
    setPayload({}).then((payload) => loadReportsHpv(payload));
  };

  const prepareDownload = async ({ selectedOption, sizeFile }) => {
    setPayload({}).then(async (payload: any) => {
      if (sizeFile === 'all_list') {
        delete payload.size;
        delete payload.page;
      }

      const file = await DownloadFilesReportsHPV({
        fileType: selectedOption,
        payload,
      });

      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `relatorio-hpv.${String(selectedOption).toLowerCase()}`
      );
      document.body.appendChild(link);
      link.click();

      setOpeModalAmostragem(false);
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TotalPatientsTablesReports
          totalItems={tableData?.totalItems}
          watch={watch}
        />
        <ButtonWithMenu
          disabled={!showTableData}
          prepareDownload={prepareDownload}
          openModalAmostragem={openModalAmostragem}
          setOpeModalAmostragem={setOpeModalAmostragem}
          stringToModalExportActualList={stringToModalExportActualList}
          totalItems={totalItems}
          title="Exportar Relatório Geral de Testes de HPV"
        />
      </div>
      <Typography
        variant="h4"
        fontWeight="bold"
        style={{ marginBottom: '30px', fontSize: '24px', marginTop: '35px' }}
      >
        Listagem
      </Typography>
      <Divider />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          {showTableData ? (
            <>
              {tableData?.length === 0 ? (
                <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                  Carregando Dados...
                </div>
              ) : (
                <>
                  <TableHeaders />
                  <Rows tableData={tableData} />
                </>
              )}
            </>
          ) : (
            <>
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                Selecione um filtro.
              </div>
            </>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={tableData?.totalItems || 0}
        rowsPerPage={watch().size || 0}
        page={tableData?.page - 1 || 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleSizeChange}
        labelRowsPerPage={'Itens por página'}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} de ${
            count !== -1 ? count : `mais do que ${to}`
          }`;
        }}
      />
    </>
  );
};

export default TableReportsHpv;
