/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddIcon from '@mui/icons-material/Add';
import {
  Autocomplete,
  Button,
  Container,
  Grid,
  TablePagination,
  TextField,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SideMenu } from '../../components/SideMenu';
import ReturnButton from '../../components/ReturnButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Activity from '../../assets/imgs/activity.svg';
import AlertInfo from '../../assets/imgs/AlertInfo.svg';
import PendenteModal from './modais/PendenteModal';
import AgendadaModal from './modais/AgendadaModal';

import {
  DisplayFilter,
  ItemsDisplayFilter,
  TotalCardsContainer,
} from './styles';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ItemTotalCardConvocation from '../../components/ItemTotalCardConvocation';
import GreenCheck from '../../assets/imgs/green-check.svg';
import YellowDots from '../../assets/imgs/yellow-dots.svg';
import RedXCircle from '../../assets/imgs/red-x-circle.svg';
import PurpleExclamation from '../../assets/imgs/purpe-exclamation.svg';
import CustomizedButton from '../../components/CustomizedButton';
import { getConvocations } from '../../lib/convocation';
import { getProfissionais } from '../../lib/profissionais';
import api from '../../api';
import { regex } from '../../utils/regex';
import NaoAtendida from './modais/NaoAtendidaModal';
import NaoElegivel from './modais/NaoElegivel';
import { PerfilContext } from '../../context/PerfilContext';
import Template from '../../components/Template';

type StateListFilter = { inputName: string; value: string };

const StatusEnum = {
  Agendada: 0,
  Pendente: 1,
  NaoAtendida: 2,
  NaoElegivel: 3,
};

enum ModalType {
  Agendada = 0,
  Pendente = 1,
  NaoAtendida = 2,
  NaoElegivel = 3,
}

export default function Convocation() {
  const [tableData, setTableData] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setFilter] = useState<any>('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsACS, setOptionsACS] = useState<any>([]);
  const [errorAgeMinMax, setErrorAgeMinMax] = useState(false);
  const [stateToSaveFilter, setStateToSaveFilter] = useState({});

  const { hasPermission } = React.useContext(PerfilContext);

  const initialStateListFilter = [
    { inputName: 'acs', value: '' },
    { inputName: 'inicio', value: '' },
    { inputName: 'final', value: '' },
    { inputName: 'exame', value: '' },
    { inputName: 'status', value: '' },
    { inputName: 'periodo', value: '' },
  ];
  const [listFilter, setListFilter] = useState<StateListFilter[]>(
    initialStateListFilter
  );

  const { control, setValue, handleSubmit, reset, watch } = useForm();

  const optionsStatusField = [
    { statusName: 'Agendado', value: 0 },
    { statusName: 'Pendentes', value: 1 },
    { statusName: 'Não Atendidas', value: 2 },
    { statusName: 'Não Elegível', value: 3 },
  ];

  const optionsAgesField: any = [];

  for (let age = 25; age <= 63; age++) {
    optionsAgesField.push({ ageLabel: `${age} anos`, value: age });
  }

  const optionsLastExamDate = [
    {
      dataLabel: 'Há 1 ano',
      value: 1,
    },
    { dataLabel: 'Há 2 anos', value: 3 },
    { dataLabel: 'Há Mais de 3 anos', value: 4 },
  ];

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const handleClearFilter = () => {
    setListFilter(initialStateListFilter);
    listFilter.map((item) => {
      return setValue(item.inputName, '');
    });

    reset();
  };

  const subtractDays = (dateValue) => {
    const currentDate = new Date();
    const newDate = dateValue
      ? new Date(
          currentDate.getFullYear() - dateValue,
          currentDate.getMonth(),
          currentDate.getDate()
        )
      : undefined;

    const formattedDate = newDate ? newDate?.toISOString().slice(0, 10) : '';

    if (dateValue) {
      return formattedDate;
    } else {
      return undefined;
    }
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));

    try {
      const response = await getConvocations({
        getParams: {
          ...stateToSaveFilter,
          size: event.target.value,
          page: String(currentPage),
        },
      });
      setTableData({ ...response, rows: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePage = async (newPage) => {
    setCurrentPage(newPage + 1);
    try {
      const response = await getConvocations({
        getParams: {
          ...stateToSaveFilter,
          page: newPage + 1,
          size: String(rowsPerPage),
        },
      });
      setTableData({ ...response, rows: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  const currentEstablishment = localStorage.getItem('id_last_establishment');
  const getProfissionaisList = async () => {
    const response = await getProfissionais({
      estabelecimentoId: String(currentEstablishment),
    });
    setOptionsACS(response.data);
  };

  const getColorAndLabelStatus = (value) => {
    let color;
    let label;
    switch (value) {
      case StatusEnum.Agendada:
        color = '#4CAF50';
        label = 'Agendada';
        break;
      case StatusEnum.Pendente:
        color = '#FFB547';
        label = 'Pendentes';
        break;
      case StatusEnum.NaoAtendida:
        color = '#FC5555';
        label = 'Não Atendidas';
        break;
      case StatusEnum.NaoElegivel:
        color = '#7986CB';
        label = 'Não Elegível';
        break;
      default:
        break;
    }

    return { color, label };
  };

  const submitForm = async (data) => {
    const nascMin = subtractDays(data.nasc_min?.value);
    const nascMax = subtractDays(data.nasc_max?.value);
    const procedimentoDate = subtractDays(data.ultimo_Procedimento?.value);

    const response = await getConvocations({
      getParams: {
        ...data,
        ultimo_Procedimento: procedimentoDate,
        nasc_min: nascMin,
        nasc_max: nascMax,
        size: String(rowsPerPage),
        page: '1',
      },
    });

    setStateToSaveFilter({
      n_convocacao: data.n_convocacao,
      profissionalId: data.profissionalId?.id,
      nasc_min: nascMin,
      nasc_max: nascMax,
      ultimo_Procedimento: procedimentoDate,
      status: data.status?.value,
    });

    setTableData({ ...response, rows: response.data });
  };

  const getInitialConvocationList = async () => {
    const response = await getConvocations({
      getParams: {
        ...stateToSaveFilter,
        size: String(rowsPerPage),
        page: String(currentPage),
      },
    });
    setTableData({ ...response, rows: response.data });
  };

  function checkIfIsNameOrCPF(inputString) {
    if (regex.CPF.test(inputString)) {
      return 'CPF';
    }

    if (/^[A-Za-z\s]+$/.test(inputString)) {
      return 'NOME';
    }

    return 'Valor invalido';
  }

  const filterByNameOrCpf = async () => {
    const inputType = checkIfIsNameOrCPF(filter);
    if (!filter) {
      const response = await getConvocations({
        getParams: {
          ...stateToSaveFilter,
          nome: undefined,
          size: String(rowsPerPage),
          page: String(currentPage),
        },
      });

      setTableData({ ...response, rows: response.data });
    }
    if (inputType === 'NOME') {
      const response = await getConvocations({
        getParams: {
          ...stateToSaveFilter,
          nome: filter,
          size: String(rowsPerPage),
          page: String(currentPage),
        },
      });
      setTableData({ ...response, rows: response.data });
    } else if (inputType === 'CPF') {
      await api
        .get(`/convocacao/cpf/${filter}`, {})
        .then((response) => {
          const data: any[] = [];
          if (response.data.pessoa) {
            data.push({
              ...response.data,
              pessoa: response.data.pessoa,
            });
          }
          const res = {
            agendadas: '0',
            agendadasNestaSemana: '0',
            count: data.length,
            data,
            naoAtendidas: '0',
            naoAtendidasNestaSemana: '0',
            naoElegiveis: '0',
            naoElegiveisNestaSemana: '0',
            page: data.length,
            pendentes: data.length,
            pendentesNestaSemana: '0',
            totalItems: data.length,
            totalPages: data.length,
          };

          setTableData({ ...res, rows: res.data });
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  };

  useEffect(() => {
    getInitialConvocationList();
    getProfissionaisList();
  }, []);

  const valueAgeMin = watch('nasc_min')?.value;
  const valueAgeMax = watch('nasc_max')?.value;

  const totalCount =
    Number(tableData?.pendentes) +
    Number(tableData?.naoElegiveis) +
    Number(tableData?.naoAtendidas) +
    Number(tableData?.agendadas);

  useEffect(() => {
    if (valueAgeMin > valueAgeMax) {
      setErrorAgeMinMax(true);
    } else {
      setErrorAgeMinMax(false);
    }
  }, [valueAgeMin, valueAgeMax]);

  const formatCpf = (cpf: string) =>
    cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1$2$3-$4');

  const [modalAberto, setModalAberto] = useState<string | null>(null);
  const [registroSelecionado, setRegistroSelecionado] = useState(null);

  const abrirModal = (status, registro) => {
    const modalTypes = Object.values(ModalType);

    if (modalTypes.includes(status)) {
      const modalType = ModalType[status];
      setModalAberto(modalType);
      setRegistroSelecionado(registro);
    } else {
    }
  };

  const fecharModal = () => {
    setModalAberto(null);
  };

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: '30px' }}
          >
            Convocação
          </Typography>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Listagem de Convocações
            </Typography>
            <Divider />
            <form onSubmit={handleSubmit(submitForm)}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={12}>
                  <DisplayFilter>
                    <SearchIcon sx={{ color: '#7e7e7e', cursor: 'pointer' }} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: '100%',
                        gap: '10px',
                        flexWrap: 'wrap',
                      }}
                    >
                      {listFilter.map((item) => (
                        <>
                          {item.value ? (
                            <ItemsDisplayFilter>
                              {item.value}
                            </ItemsDisplayFilter>
                          ) : undefined}
                        </>
                      ))}
                    </div>
                    <CloseIcon
                      fontSize="small"
                      onClick={handleClearFilter}
                      style={{ color: '#7e7e7e', cursor: 'pointer' }}
                    />
                  </DisplayFilter>
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Controller
                    control={control}
                    name="profissionalId"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Autocomplete
                          options={optionsACS}
                          getOptionLabel={(option) => option?.nomeprofissional}
                          value={value ? value : null}
                          onChange={(_, newValue) => {
                            handleSetFilter(newValue?.nomeprofissional, 'acs');
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="ACS" />
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Controller
                    control={control}
                    name="nasc_min"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={[
                          { ageLabel: 'Fora da faixa etária', value: 24 },
                          ...optionsAgesField,
                        ]}
                        getOptionLabel={(option) => option.ageLabel}
                        value={value ? value : null}
                        onChange={(_, newValue) => {
                          handleSetFilter(newValue?.ageLabel, 'inicio');
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Idade Início"
                            error={errorAgeMinMax}
                            helperText={
                              errorAgeMinMax
                                ? 'Idade inicial deve ser menor que idade final'
                                : ''
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Controller
                    control={control}
                    name="nasc_max"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={[
                          { ageLabel: 'Fora da faixa etária', value: 64 },
                          ...optionsAgesField,
                        ]}
                        getOptionLabel={(option) => option.ageLabel}
                        value={value ? value : null}
                        onChange={(_, newValue) => {
                          handleSetFilter(newValue?.ageLabel, 'final');
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Idade Final" />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Controller
                    control={control}
                    name="ultimo_Procedimento"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={optionsLastExamDate}
                        getOptionLabel={(option) => {
                          return option.dataLabel;
                        }}
                        value={value ? value : null}
                        onChange={(_, newValue) => {
                          handleSetFilter(newValue?.dataLabel, 'exame');
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Último Exame" />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Autocomplete
                          options={optionsStatusField}
                          getOptionLabel={(option) => option.statusName}
                          value={value ? value : null}
                          onChange={(_, newValue) => {
                            handleSetFilter(newValue?.statusName, 'status');
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Status" />
                          )}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={1.5}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: '100%' }}
                    // disabled={errorAgeMinMax}
                  >
                    FILTRAR
                  </Button>
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <Button
                    variant="outlined"
                    color="error"
                    style={{ width: '100%' }}
                    onClick={handleClearFilter}
                  >
                    LIMPAR
                  </Button>
                </Grid>
              </Grid>
            </form>

            <TotalCardsContainer>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  lg={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '139px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <img src={Activity} alt="" />
                    <p style={{ margin: 0, color: 'rgba(0, 0, 0, 0.60)' }}>
                      Todas
                    </p>
                    <Tooltip
                      title="Quantidade total de  convocações feitas"
                      placement="top"
                    >
                      <img
                        src={AlertInfo}
                        alt=""
                        style={{ marginLeft: '13px' }}
                      />
                    </Tooltip>
                  </div>
                  <h1
                    style={{
                      fontSize: '96px',
                      margin: 0,
                      lineHeight: '68px',
                      color: 'rgba(0, 0, 0, 0.60)',
                      marginTop: '20px',
                    }}
                  >
                    {totalCount ? totalCount : '0'}
                  </h1>
                  <p style={{ margin: 0, color: 'rgba(0, 0, 0, 0.60)' }}>
                    Convocações
                  </p>
                  <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>
                    Quarta, 26 Julho 2023
                  </span>
                </Grid>
                <Grid item xs={12} lg={2.5}>
                  <ItemTotalCardConvocation
                    color="#4CAF50"
                    title="Agendadas"
                    value={tableData.agendadas}
                    bottomPhrase={`${tableData.agendadasNestaSemana} + essa semana`}
                    icon={GreenCheck}
                    tooltipTitle="Quantidade de convocações  que geraram agendamentos "
                  />
                </Grid>
                <Grid item xs={12} lg={2.5}>
                  <ItemTotalCardConvocation
                    color="#FFB547"
                    title="Pendentes"
                    value={tableData.pendentes}
                    bottomPhrase={`${tableData.pendentesNestaSemana} + essa semana`}
                    icon={YellowDots}
                    tooltipTitle="Quantidade de convocações que ainda não tiveram resposta da convocação"
                  />
                </Grid>
                <Grid item xs={12} lg={2.5}>
                  <ItemTotalCardConvocation
                    color="#FC5555"
                    title="Não Atendidas"
                    value={tableData.naoAtendidas}
                    bottomPhrase={`${tableData.naoAtendidasNestaSemana} + essa semana`}
                    icon={RedXCircle}
                    tooltipTitle="Quantidade de convocações  que não geraram agendamentos"
                  />
                </Grid>
                <Grid item xs={12} lg={2.5}>
                  <ItemTotalCardConvocation
                    color="#7986CB"
                    title="Não Elegíveis"
                    value={tableData.naoElegiveis}
                    bottomPhrase={`${tableData.naoElegiveisNestaSemana} + essa semana`}
                    icon={PurpleExclamation}
                    tooltipTitle="Quantidade de convocações que estavam foram do público-alvo do exame"
                  />
                </Grid>
              </Grid>
            </TotalCardsContainer>

            <Grid container spacing={2} sx={{ marginTop: '26px' }}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Pesquisar"
                  placeholder="Pesquisar por Nome ou CPF da paciente"
                  size="small"
                  onChange={(e) => setFilter(e.target.value)}
                  sx={{
                    width: '100%',
                    '.MuiOutlinedInput-root': {
                      paddingRight: '0',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button onClick={filterByNameOrCpf} type="button">
                        <SearchIcon sx={{ cursor: 'pointer', color: 'gray' }} />
                      </Button>
                    ),
                  }}
                  error={
                    filter.length &&
                    checkIfIsNameOrCPF(filter) === 'Valor invalido'
                      ? true
                      : false
                  }
                  helperText={
                    filter.length &&
                    checkIfIsNameOrCPF(filter) === 'Valor invalido'
                      ? 'O valor não é um nome ou cpf válido'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button sx={{ width: '100%' }} variant="outlined">
                  GERAR RELATÓRIO
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  sx={{ padding: '5px', width: '100%' }}
                  variant="outlined"
                >
                  GERAR DASHBOARD
                </Button>
              </Grid>
            </Grid>

            <p style={{ marginTop: '24px' }}>
              Exibindo {tableData?.rows?.length} pacientes
            </p>

            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          width: '160px',
                        }}
                        fontWeight="bold"
                      >
                        NOME
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          width: '120px',
                        }}
                        fontWeight="bold"
                      >
                        CPF
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: 'none' }}>
                      <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
                        {' '}
                        ENDEREÇO
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: 'none' }}>
                      <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
                        ACS
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: 'none' }}>
                      <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
                        CONVOCAÇÃO
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: 'none' }}>
                      <Typography
                        sx={{ fontSize: '14px', width: '160px' }}
                        fontWeight="bold"
                      >
                        AGENDAMENTO
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: 'none' }}>
                      <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
                        STATUS
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ borderBottom: 'none' }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.rows?.map((row) => {
                    return (
                      <TableRow key={row.pessoa.name}>
                        <TableCell sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body1"
                            fontSize="14px"
                          >
                            {String(row?.pessoa.nome).toUpperCase()}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body1"
                            fontSize="14px"
                          >
                            {formatCpf(row?.pessoa.cpf || '')}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body2"
                            fontSize="14px"
                          >
                            {String(
                              row?.pessoa.endereco
                                ? `${row?.pessoa.endereco.logradouro}, ${
                                    row?.pessoa.endereco.numero
                                  }${
                                    row?.pessoa.endereco.complemento
                                      ? `, ${row?.pessoa.endereco.complemento}`
                                      : ''
                                  } `
                                : ''
                            ).toUpperCase()}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body1"
                            fontSize="14px"
                          >
                            {String(
                              row?.profissional?.pessoa?.nome ?? ''
                            ).toUpperCase()}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body2"
                            fontSize="14px"
                          >
                            <p style={{ margin: 0 }}>
                              {row?.n_convocacao}º Convocação
                            </p>
                            {moment(row?.convocado_em).format('DD/MM/YYYY')}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body1"
                            fontSize="14px"
                          >
                            {moment
                              .utc(row?.agendado_em)
                              .format('DD/MM/YYYY - HH:mm') ?? ''}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: '162px',
                            padding:
                              row?.status === StatusEnum.NaoAtendida
                                ? 0
                                : '16px',
                          }}
                        >
                          <Typography
                            color="white"
                            variant="body2"
                            sx={{
                              backgroundColor: getColorAndLabelStatus(
                                row?.status
                              ).color,
                              borderRadius: '16px',
                              textAlign: 'center',
                              padding: '3px 15px',
                              width: 'fit-content',
                            }}
                            fontSize="13px"
                          >
                            {getColorAndLabelStatus(row?.status).label}
                          </Typography>
                        </TableCell>
                        {((hasPermission('CREATE_CONVOCATORIA_BUSCA_ATIVA') &&
                          row?.status === StatusEnum.Pendente) ||
                          row?.status !== StatusEnum.Pendente) && (
                          <TableCell>
                            <CustomizedButton
                              variant="outlined"
                              onClick={() => abrirModal(row?.status, row)}
                              startIcon={
                                row?.status === StatusEnum.NaoElegivel ||
                                row?.status === StatusEnum.Agendada ||
                                row?.status === StatusEnum.NaoAtendida ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <AddIcon />
                                )
                              }
                              sx={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              BUSCA ATIVA
                            </CustomizedButton>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                  {/* Renderizar o modal correto com base no estado */}
                  {modalAberto === 'Agendada' && registroSelecionado && (
                    <AgendadaModal
                      open={modalAberto === 'Agendada'}
                      onClose={fecharModal}
                      registro={registroSelecionado}
                    />
                  )}
                  {modalAberto === 'NaoAtendida' && registroSelecionado && (
                    <NaoAtendida
                      open={modalAberto === 'NaoAtendida'}
                      onClose={fecharModal}
                      registro={registroSelecionado}
                    />
                  )}
                  {modalAberto === 'Pendente' && registroSelecionado && (
                    <PendenteModal
                      open={modalAberto === 'Pendente'}
                      onClose={fecharModal}
                      registro={registroSelecionado}
                    />
                  )}
                  {modalAberto === 'NaoElegivel' && registroSelecionado && (
                    <NaoElegivel
                      open={modalAberto === 'NaoElegivel'}
                      onClose={fecharModal}
                      registro={registroSelecionado}
                    />
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={tableData.totalItems}
                rowsPerPage={rowsPerPage}
                page={tableData.page - 1}
                onPageChange={(e, newPage) => handleChangePage(newPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={'Itens por página'}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} de ${
                    count !== -1 ? count : `mais do que ${to}`
                  }`;
                }}
              />
            </TableContainer>
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
}
