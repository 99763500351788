import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { SideMenu } from '../../../components/SideMenu';
import ReturnButton from '../../../components/ReturnButton';

import FiltersReportsHpv from './FiltersReportsHpv';
import TableReportsHpv from '../../../components/TableReports/TableHpv/TableReportsHpv';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingContext } from '../../../context/LoadingContext';
import { Link, useLocation } from 'react-router-dom';
import useGetDetailsFilters from './FiltersReportsHpv/hooks/useGetDetailsFilters';
import {
  optionsMotivoNaoAtendida,
  optionsResultadoHPV,
  optionsTiposVirus,
} from '../../../utils/variables';
import useRequestFilter from './FiltersReportsHpv/hooks/useRequestFilter';
import useHandleChangesFilters from './FiltersReportsHpv/hooks/useHandleChangesFilters';
import api from '../../../api';
import { UserToken, getUserToken } from '../../../lib/auth';
import { getCidades } from '../../../lib/getCidadesEstabelecimentos';
import { dataTool } from 'echarts';
import moment from 'moment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useSetPayload from './FiltersReportsHpv/hooks/useSetPayload';
import Template from '../../../components/Template';

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'idade_ini', value: '' },
  { inputName: 'idade_fin', value: '' },
  { inputName: 'realizou_atendimento', value: '' },
  { inputName: 'atendimento_status', value: '' },
  { inputName: 'natureza', value: '' },
  { inputName: 'motivo', value: '' },
  { inputName: 'inspecao_colo', value: '' },
  { inputName: 'responsavel_coleta', value: '' },
  { inputName: 'dt_coleta_inicio', value: '' },
  { inputName: 'dt_coleta_fim', value: '' },
  { inputName: 'resultado', value: '' },
  { inputName: 'tipo_virus', value: '' },
  { inputName: 'estabelecimentoLaudoId', value: '' },
  { inputName: 'dt_resultado_inicio', value: '' },
  { inputName: 'dt_resultado_fim', value: '' },
  { inputName: 'search', value: '' },
  { inputName: 'acs', value: '' },
];

export const ReportsHpv = () => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
      resultado: [],
    },
  });

  const [tableData, setTableData] = useState<any[]>([]);
  const [showTableData, setShowTableData] = useState(false);
  const [payloadFromCharts, setPayloadFromCharts] = useState<any[]>();
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [period, setPeriod] = useState('period');
  const [periodResultado, setPeriodResultado] = useState('period');
  const [estabelecimentoUser, setEstabelecimentoUser] = useState();
  const [dtColetaIni, setDataColetaIni] = useState<any>(null);
  const [dtColetaFinal, setDataColetaFinal] = useState<any>(null);
  const [dtResultadoIni, setDataResultadoIni] = useState<any>(null);
  const [dtResultadoFinal, setDataResultadoFinal] = useState<any>(null);
  const location = useLocation();
  const origin = location?.state?.origin;
  const fromTable =
    origin === 'tables' || !!localStorage.getItem('requestFromTable');
  const payload = location?.state?.payload;
  const clickedCategory = location?.state?.clickedCategory;
  const clickedSeries = location?.state?.clickedSeries;
  const tableOrigin = location?.state?.table;
  const userToken = getUserToken();
  const permissaoId = getUserToken().permissao_atual?.id;
  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState<
    any[]
  >([]);
  const [selectedLaboratorio, setSelectedLaboratorio] = useState<any>();
  const [
    selectedOptinsProfissionalResponsavel,
    setSelectedOptinsProfissionalResponsavel,
  ] = useState<any>([]);

  const [disabledLaboratorioResultado, setDisabledLaboratorioResultado] =
    useState(false);

  // const isPadrao =
  //   permissaoId === 1 ||
  //   permissaoId === 9 ||
  //   permissaoId === 10 ||
  //   permissaoId === 7;

  // const isLaboratorio = userToken.permissao_atual?.contexto === 'LABORATÓRIO';

  // const isMunicipal = userToken.permissao_atual?.id === 3;
  const {
    estadosSelected,
    setEstadosSelected,
    municipiosSelected,
    setMunicipiosSelected,
    setOptionsEstabelecimentos,
    optionsEstabelecimentos,
    estabelecimentosSelected,
    setEstabelecimentosSelected,
    equipesSelected,
    setDisableInputEstado,
    disableInputEstado,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    optionsMunicipios,
    setOptionsMunicipios,
    setEstadosOptions,
    setAllEstadosOptions,
    setOptionsLaboratorios,
    estadosOptions,
  } = useStatesFields();

  const handleSetListFilterShowFromCharts = (
    inputName: string,
    newValue: any
  ) => {
    setListFilterShow((prev) => {
      return [...prev, { inputName, value: newValue }];
    });
  };

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue ?? '' } : item
      )
    );
  };

  const handleSetFilterShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) => {
      const exists = prev.some((item) => item.inputName === inputName);
      return exists
        ? prev
        : [...prev, { inputName: inputName, value: newValue }];
    });
  };

  const { setLoading } = useContext(LoadingContext);

  const { loadReportsHpv } = useRequestFilter({
    setTableData,
  });

  const { getMunicipioDetails, getEstabelecimentoDetails, getAcsDetails } =
    useGetDetailsFilters();

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    dtColetaFinal,
    dtColetaIni,
    dtResultadoIni,
    dtResultadoFinal,
    selectedLaboratorio,
    selectedOptinsProfissionalResponsavel,
    selectedOptionsLaboratorio,
  });

  const estabelecimentoIdUser = userToken?.estabelecimentoId ?? null;
  const estabelecimento_saude = userToken?.estabelecimento_saude;
  const endereco = estabelecimento_saude?.endereco;
  const municipio = endereco?.municipio;

  const estabelecimentoNameUser = estabelecimento_saude?.nome_fantasia ?? null;
  const municipioUserId = municipio?.id ?? null;
  const municipioUserName = municipio?.nome ?? null;
  const estadoUserSigla = municipio?.estadoSigla ?? null;

  const setDefaultEstado = ({ sigla }) => {
    setEstadosOptions([
      {
        sigla: sigla,
      },
    ]);
    handleSetFilter(`Estado: ${sigla}`, 'estado');

    setEstadosSelected([sigla]);
  };

  const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
    setOptionsMunicipios({
      id: municipioId,
      nome: municipioNome,
    });
    handleSetFilter(`Municipio: ${municipioNome}`, 'municipio');
    setMunicipiosSelected([municipioId]);
  };

  const setDefaultEstabelecimento = ({
    estabelecimentoId,
    estabelecimentoNome,
  }) => {
    setOptionsEstabelecimentos({
      id: estabelecimentoIdUser,
      municipio: municipioUserName,
      municipio_id: municipioUserId,
      nome: estabelecimentoNameUser,
      label: estabelecimentoNameUser,
    });

    setEstabelecimentosSelected([estabelecimentoIdUser]);
  };

  const setDefaultLaboratorio = ({ laboratorioId, laboratorioName }) => {
    setOptionsLaboratorios([
      {
        label: laboratorioName,
        value: laboratorioId,
      },
    ]);
    setSelectedOptionsLaboratorio([
      {
        label: laboratorioName,
        value: laboratorioId,
      },
    ]);

    handleSetFilter(
      `Laboratório Responsável pelo Laudo: ${laboratorioName}`,
      'estabelecimentoLaudoId'
    );
  };

  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
  const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
  const isAdminLaboratorio =
    userToken.permissao_atual?.nome === 'Admin Estabelecimento';
  const isPadraoEstabelecimento =
    userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

  const actionsWhenUserIsEstadual = () => {
    setDefaultEstado({
      sigla: estadoUserSigla,
    });
  };

  const actionsWhenUserIsMunicipal = () => {
    setDefaultEstado({
      sigla: estadoUserSigla,
    });
    setDefaultMunicipio({
      municipioId: String(municipioUserId),
      municipioNome: municipioUserName,
    });

    setListFilterShow((prev) => [
      ...prev,
      { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
      { inputName: 'municipio', value: `Município: ${municipioUserName}` },
    ]);

    setShowTableData(true);
  };

  const actionsWhenUserIsAdminLaboratorio = () => {
    setDefaultEstado({
      sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
    });

    setDefaultLaboratorio({
      laboratorioId: userToken.estabelecimentoId,
      laboratorioName: userToken.estabelecimento_saude?.nome_fantasia,
    });
  };

  const actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento = () => {
    actionsWhenUserIsMunicipal();

    setDefaultEstabelecimento({
      estabelecimentoId: estabelecimentoIdUser,
      estabelecimentoNome: estabelecimentoNameUser,
    });

    setListFilterShow((prev) => [
      { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
      { inputName: 'municipio', value: `Município: ${municipioUserName}` },
      {
        inputName: 'estabelecimento_saude',
        value: `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
      },
    ]);
  };

  useEffect(() => {
    if (isSuperAdmin) {
      return;
    }

    if (isEstadual) {
      actionsWhenUserIsEstadual();
    }

    if (isMunicipal) {
      actionsWhenUserIsMunicipal();
      if (!fromTable) {
        loadReportsHpv({
          estados: estadoUserSigla,
          municipios: municipioUserId,
          page: 1,
          size: 25,
        });
      }
    }

    if (isAdminLaboratorio) {
      actionsWhenUserIsAdminLaboratorio();
    }

    if (isPadraoEstabelecimento) {
      actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento();
      if (!fromTable) {
        loadReportsHpv({
          estados: estadoUserSigla,
          municipios: municipioUserId,
          estabelecimentoSaudeId: estabelecimentoIdUser,
          page: 1,
          size: 25,
        });
      }
    }
  }, []);

  const fieldConnectedChart = {
    result: {
      displayName: 'Resultado do Teste de Hpv: ',
      fieldName: 'resultado',
      options: optionsResultadoHPV,
    },

    totalValue: {
      displayName: 'Realizou o atendimento: ',
      fieldName: 'realizou_atendimento',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },
      ],
    },
    typeVirus: {
      displayName: 'Tipos de Vírus: ',
      fieldName: 'tipo_virus',
      options: optionsTiposVirus,
    },
    reasonNotProcedure: {
      displayName: 'Motivo do Não Atendimento: ',
      fieldName: 'atendimento_status',
      options: optionsMotivoNaoAtendida,
    },
  };

  const payloadFromPanelCharts =
    JSON.parse(String(localStorage.getItem('payload'))) ??
    location?.state?.payload;

  const arrEstbFromPayload = payloadFromPanelCharts?.params?.estabelecimentos
    ? payloadFromPanelCharts.params.estabelecimentos?.split(',')
    : [];

  const chartNameSavedInStorage =
    localStorage.getItem('chartName') || location?.state?.table;

  const chartClickedIsTypeVirus = chartNameSavedInStorage === 'typeVirus';
  const chartClickedIsReasonNotProcedure =
    chartNameSavedInStorage === 'reasonNotProcedure';
  const chartClickedIsTotal = chartNameSavedInStorage === 'totalValue';
  const chartClickedIsResults = chartNameSavedInStorage === 'results';
  const chartClickedIsFaixa = chartNameSavedInStorage === 'faixa';

  const setFieldThatShowFieldClickedInTable = ({ fieldFatherName, value }) => {
    setValue(fieldFatherName, value);
  };

  const fieldFather = {
    typeVirus: {
      fieldFatherName: 'resultado',
      option: {
        value: 'POSITIVO',
        label: 'Detectável',
      },
    },
    reasonNotProcedure: {
      fieldFatherName: 'realizou_atendimento',
      option: 'Não',
    },
  };

  const setDisplayFilters = ({ optionSelected, field }) => {
    handleSetFilter(optionSelected, field.fieldName);
    setListFilterShow((prev) => {
      return [
        ...prev,
        { inputName: field.fieldName, value: optionSelected.label },
      ];
    });
  };

  const setValueInInput = () => {
    const valueClickedInChart = localStorage.getItem('itemClicked');

    if (chartNameSavedInStorage === 'ageRange') {
      if (valueClickedInChart === 'outRange') {
        setValue('realizou_atendimento', 'Não');
        // setValue('status', 'NAO_ATENDIDA ');
        setValue('atendimento_status', {
          value: 'NAO_FAIXA_ETARIA',
          label: 'Não está na faixa etária',
        });
      } else {
        const iniValue = valueClickedInChart?.split(',')[0];
        const finValue = valueClickedInChart?.split(',')[1];
        setDisplayFilters({
          optionSelected: {
            label: 'Idade Início: ' + iniValue,
            value: iniValue,
          },
          field: { fieldName: 'idade_ini' },
        });
        setDisplayFilters({
          optionSelected: { label: 'Idade Fim: ' + finValue, value: finValue },
          field: { fieldName: 'idade_fin' },
        });

        setValue('idade_ini', iniValue);
        setValue('idade_fin', finValue);
      }
      return;
    }
    const fieldName = fieldConnectedChart[chartNameSavedInStorage]?.fieldName;

    const valueSelected = fieldConnectedChart[
      chartNameSavedInStorage
    ]?.options.find((item) => item.value === valueClickedInChart);

    const labelValueSelectedToSetDisplay =
      fieldConnectedChart[chartNameSavedInStorage].displayName;

    if (fieldName === 'tipo_virus') {
      setValue('resultado', [
        {
          value: 'POSITIVO',
          label: 'Detectável',
        },
      ]);

      setDisplayFilters({
        optionSelected: {
          value: 'POSITIVO',
          label: 'Resultado do Teste de HPV: Detectável',
        },
        field: { fieldName },
      });
    }

    setValue(fieldName, valueSelected);

    setDisplayFilters({
      optionSelected: {
        ...valueSelected,
        label: labelValueSelectedToSetDisplay + valueSelected?.label,
      },
      field: { fieldName },
    });
  };

  const setInDisplayTheStateOrMunicipioThatUserAdminSelectedInPanel =
    async () => {
      const selectedTwoEstados =
        payloadFromPanelCharts.params.estados.split(',').length > 1;
      const selectedMoreThanOneMunicipio =
        payloadFromPanelCharts.params.municipios.split(',').length > 1;
      if (selectedTwoEstados) {
        handleSetFilter('Estados: Vários', 'estado');
        handleSetFilterShow('Estados: Vários', 'estado');
      } else {
        handleSetFilterShow(
          'Estado: ' + payloadFromPanelCharts.params.estados,
          'estado'
        );
        handleSetFilter(
          'Estado:' + payloadFromPanelCharts.params.estados,
          'estado'
        );
      }

      if (selectedMoreThanOneMunicipio) {
        handleSetFilter('Município: Vários', 'municipio');
        handleSetFilterShow('Município: Vários', 'municipio');
      } else {
        const getResponseresponseDetailMunicipio = async () => {
          return new Promise(async (resolve, reject) => {
            const response = await api.get(
              `/municipios/${payloadFromPanelCharts.params.municipios}`
            );

            resolve(response);
          });
        };
        const municipioDetail: any = await getResponseresponseDetailMunicipio();

        handleSetFilterShow(
          'Município: ' + municipioDetail.data.nome,
          'municipio'
        );
        handleSetFilter('Município:' + municipioDetail.data.nome, 'municipio');
      }
    };

  const getDataByChartClicked = async () => {
    return new Promise(async (resolve, reject) => {
      const isGestao =
        isSuperAdmin || isAdminLaboratorio || isEstadual || isMunicipal;

      if (isSuperAdmin) {
        setInDisplayTheStateOrMunicipioThatUserAdminSelectedInPanel();
      }
      if (isGestao) {
        const selectedTwoEstados =
          payloadFromPanelCharts.params.estados.split(',').length > 1;

        if (selectedTwoEstados) {
          setEstadosSelected(
            payloadFromPanelCharts.params.estados
              .split(',')
              .map((estId) => estId.trim())
          );
        } else {
          setEstadosSelected([payloadFromPanelCharts.params.estados]);
          const selectedMoreThanOneMunicipios =
            payloadFromPanelCharts.params.municipios.split(',').length > 1;

          if (selectedMoreThanOneMunicipios) {
            setMunicipiosSelected(
              payloadFromPanelCharts.params.municipios
                .split(',')
                .map((munId) => munId.trim())
            );
          } else {
            setMunicipiosSelected([payloadFromPanelCharts.params.municipios]);
            setEstabelecimentosSelected(
              payloadFromPanelCharts.params.estabelecimentos
                ? arrEstbFromPayload
                : []
            );
          }
        }
      }

      if (chartClickedIsTypeVirus || chartClickedIsReasonNotProcedure) {
        setFieldThatShowFieldClickedInTable({
          fieldFatherName: fieldFather[chartNameSavedInStorage].fieldFatherName,
          value: fieldFather[chartNameSavedInStorage].option,
        });
      }

      setShowTableData(true);

      setValueInInput();
      resolve('');
    }).then(async () => {
      setPayload({
        requestFromPanel: true,
        payloadFromPanel: payloadFromPanelCharts,
      }).then(async (payload: any) => {
        await loadReportsHpv(payload);
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    if (fromTable) {
      setLoading(true);
      getDataByChartClicked();
    }
  }, [fromTable]);

  // const setFiltersToRequestByTable = async () => {
  //   return new Promise(async (resolve, reject) => {
  //     const periodo_inicio = localStorage.getItem('coleta_inicio') || undefined;
  //     const periodo_fim = localStorage.getItem('coleta_fim') || undefined;
  //     let payloadToRequest: any = {
  //       page: 1,
  //       size: 25,
  //       periodo_inicio,
  //       periodo_fim,
  //     };
  //     const payloadFromLocalStorage = JSON.parse(
  //       String(localStorage.getItem('payload'))
  //     );

  //     const selectedChartIsReason =
  //       !!localStorage.getItem('reasonNotProcedure');
  //     const params =
  //       location?.state?.payload?.params ||
  //       payloadFromLocalStorage?.params ||
  //       undefined;
  //     if (params) {
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         ...params,
  //       };
  //     }
  //     if (params?.periodo_inicio) {
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         dt_coleta_inicio: params.periodo_inicio,
  //         dt_coleta_fim: params.periodo_fim,
  //       };
  //       handleSetListFilterShowFromCharts(
  //         'periodo',
  //         `Perido: De ${params.periodo_inicio} até ${params.periodo_fim}`
  //       );
  //       if (!selectedChartIsReason) {
  //         //caso tiver o procedimento chama aqui
  //         setValue('dtColetaIni', params.periodo_inicio);
  //         setDataColetaIni(
  //           moment(params.periodo_inicio, 'YYYY-MM-DD').format('DD-MM-YYYY')
  //         );
  //         setValue('dtColetaFinal', params.periodo_fim);
  //         setDataColetaFinal(
  //           moment(params.periodo_fim, 'YYYY-MM-DD').format('DD-MM-YYYY')
  //         );
  //       }
  //     }
  //     if (params?.procedimento) {
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         procedimento: params?.procedimento,
  //       };
  //       setValue('realizou_atendimento', params?.procedimento ? 'Sim' : 'Não');
  //       setValue('procedimento', params?.procedimento ? 'Sim' : 'Não');
  //       handleSetListFilterShowFromCharts(
  //         'realizou_atendimento',
  //         `Procedimento: ${params?.procedimento ? 'Sim' : 'Não'}`
  //       );
  //     }

  //     const selectedChartIsResult = !!localStorage.getItem('result');
  //     const selectedChartIsTypeVirus = !!localStorage.getItem('typeVirus');
  //     const selectedChartOriginCollection =
  //       localStorage.getItem('collectionOrigin');
  //     const selectedChartTotalTests =
  //       location?.state?.panelClicked === 'totalTests';

  //     if (selectedChartTotalTests) {
  //       handleSetListFilterShowFromCharts('realizou_atendimento', 'Sim');
  //       setValue('realizou_atendimento', 'Sim');
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         realizou_atendimento: 'Sim',
  //         atendimento_status: 'REALIZADO',
  //       };
  //     }

  //     if (location?.state?.table === 'faixa') {
  //       const dates = String(location.state.faixa?.[0])
  //         ?.split('a')
  //         ?.map((age) => age.trim());
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         idade_inicio: dates[0] || undefined,
  //         idade_final: dates[1] || undefined,
  //       };

  //       setValue('idade_ini', dates[0]);
  //       dates[1] && setValue('idade_fin', dates[1]);

  //       handleSetListFilterShowFromCharts(
  //         'idade_ini',
  //         `Idade Início: ${dates[0]}`
  //       );
  //       dates[1] &&
  //         handleSetListFilterShowFromCharts(
  //           'idade_fin',
  //           `Idade Fim: ${dates[1]}`
  //         );
  //     }

  //     const filteredByState =
  //       !!payload?.params?.estados ||
  //       !!payloadFromLocalStorage?.params?.estados;

  //     const filteredByMunicipio =
  //       !!payload?.params?.municipios ||
  //       !!payloadFromLocalStorage?.params?.municipios;

  //     const filteredByEstabelecimento =
  //       !!payload?.params?.estabelecimentos ||
  //       !!payloadFromLocalStorage?.params?.estabelecimentos;

  //     const filteredByEquipe =
  //       !!payload?.params?.equipe || !!payloadFromLocalStorage?.params?.equipe;

  //     const setDisplayFilterEstado = () => {
  //       handleSetListFilterShowFromCharts('estado', `Estado: PE`);
  //     };

  //     const setDisplayFilterMunicipio = async (valueMunicipio) => {
  //       const idsMunicipioFromCharts = String(valueMunicipio).split(',');
  //       const response = await api.get(`/municipios`, {
  //         params: {
  //           sigla: 'PE',
  //         },
  //       });

  //       let arrOffMunicipiosLabel: any = [];

  //       idsMunicipioFromCharts.forEach((idFromChart) => {
  //         const label = response.data.find(
  //           (municipio) => String(municipio.id) === String(idFromChart).trim()
  //         )?.nome;

  //         arrOffMunicipiosLabel.push(label);
  //       });

  //       const labelsToSetFilter = arrOffMunicipiosLabel;

  //       if (labelsToSetFilter.length > 1) {
  //         handleSetListFilterShowFromCharts('municipio', 'Municípios: Vários');
  //       } else {
  //         handleSetListFilterShowFromCharts(
  //           'municipio',
  //           `Município: ${labelsToSetFilter[0]}`
  //         );
  //       }
  //     };

  //     const setDisplayFilterEstabelecimentos = async ({
  //       valueEstabelecimentos,
  //       valueMunicipio,
  //     }) => {
  //       const idsEstabelecimentosFromCharts = String(
  //         valueEstabelecimentos
  //       ).split(',');
  //       const response = await api.get(`/estabelecimentos-saude`, {
  //         params: {
  //           estado: 'PE',
  //           municipio: valueMunicipio,
  //         },
  //       });

  //       let arrOffEstabelecimentosLabel: any = [];

  //       idsEstabelecimentosFromCharts.forEach((idFromChart) => {
  //         const label = response.data.data.find(
  //           (estabelecimento) =>
  //             String(estabelecimento.id) === String(idFromChart).trim()
  //         )?.nome_fantasia;

  //         arrOffEstabelecimentosLabel.push(label);
  //       });

  //       const labelsToSetFilter = arrOffEstabelecimentosLabel;

  //       if (labelsToSetFilter.length > 1) {
  //         handleSetListFilterShowFromCharts(
  //           'estabelecimento_saude',
  //           'Estabelecimentos: Vários'
  //         );
  //       } else {
  //         handleSetListFilterShowFromCharts(
  //           'estabelecimento_saude',
  //           `Estabelecimento: ${labelsToSetFilter[0]}`
  //         );
  //       }
  //     };

  //     /*if (  // isso ta bugando as regras abaixo...
  //       !filteredByState &&
  //       !filteredByMunicipio &&
  //       !filteredByEstabelecimento
  //     ) {
  //       resolve(payloadToRequest);
  //     }*/

  //     if (
  //       filteredByState &&
  //       !filteredByMunicipio &&
  //       !filteredByEstabelecimento
  //     ) {
  //       const payloadEstado = payloadFromLocalStorage
  //         ? payloadFromLocalStorage.params?.estados
  //         : payload.params?.estados;

  //       payloadToRequest = {
  //         ...payloadToRequest,

  //         estado:
  //           isPadrao || isMunicipal || isEstadual
  //             ? 'PE'
  //             : JSON.parse(payloadEstado).join(', '),
  //       };
  //       setPayloadFromCharts(payloadToRequest);
  //       setDisplayFilterEstado();
  //       setEstadosSelected(JSON.parse(payloadEstado));
  //     }

  //     // Quando há somente filtro por estado e municipio aplicado ao gráfico

  //     if (
  //       filteredByState &&
  //       filteredByMunicipio &&
  //       !filteredByEstabelecimento
  //     ) {
  //       const valueEstado =
  //         isPadrao || isEstadual || isMunicipal
  //           ? 'PE'
  //           : payloadFromLocalStorage
  //           ? JSON.parse(payloadFromLocalStorage.params?.estados).join(', ')
  //           : JSON.parse(payload.params?.estados).join(', ');

  //       const valueMunicipio =
  //         isPadrao || isMunicipal
  //           ? userToken.endereco?.municipio_id
  //           : payloadFromLocalStorage
  //           ? payloadFromLocalStorage.params?.municipios
  //           : payload.params?.municipios;

  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         municipioId: valueMunicipio,
  //         estado: valueEstado,
  //       };
  //       setEstadosSelected([valueEstado]);
  //       setMunicipiosSelected(
  //         String(valueMunicipio)
  //           .split(',')
  //           .map((id) => id.trim())
  //       );

  //       setPayloadFromCharts(payloadToRequest);
  //       setDisplayFilterEstado();
  //       await setDisplayFilterMunicipio(valueMunicipio);
  //     }

  //     if (filteredByState && filteredByMunicipio && filteredByEstabelecimento) {
  //       // const acsData = await getAcsDetails(clickedCategory);
  //       const valueEstado =
  //         isPadrao || isMunicipal || isEstadual
  //           ? 'PE'
  //           : payloadFromLocalStorage
  //           ? JSON.parse(payloadFromLocalStorage.params?.estados).join(', ')
  //           : JSON.parse(payload.params?.estados).join(', ');

  //       const valueMunicipio =
  //         isPadrao || isMunicipal || isEstadual
  //           ? userToken.endereco?.municipio_id
  //           : payloadFromLocalStorage
  //           ? payloadFromLocalStorage.params?.municipios
  //           : payload.params?.municipios;

  //       const valueEstabelecimentos = isPadrao
  //         ? userToken.estabelecimentoId
  //         : payloadFromLocalStorage
  //         ? String(payloadFromLocalStorage.params?.estabelecimentos)
  //         : String(payload.params?.estabelecimentos);

  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         municipioId: valueMunicipio,
  //         estado: valueEstado,
  //         estabelecimentoSaudeId: valueEstabelecimentos,
  //         // profissionalAcsId: acsData.data[0].id,
  //       };

  //       const nomeMunicipio = (await api.get(`/municipios/${valueMunicipio}`))
  //         .data.nome;

  //       setEstadosSelected([valueEstado]);

  //       setMunicipiosSelected(
  //         String(valueMunicipio)
  //           .split(',')
  //           .map((id) => id.trim())
  //       );
  //       setEstabelecimentosSelected(
  //         String(valueEstabelecimentos)
  //           .split(',')
  //           .map((id) => Number(id.trim()))
  //       );
  //       setDisplayFilterEstado();
  //       await setDisplayFilterMunicipio(valueMunicipio);
  //       await setDisplayFilterEstabelecimentos({
  //         valueEstabelecimentos,
  //         valueMunicipio: nomeMunicipio,
  //       });
  //       setPayloadFromCharts(payloadToRequest);
  //     }

  //     if (filteredByEquipe) {
  //       const valueEstado =
  //         isPadrao || isMunicipal || isEstadual
  //           ? 'PE'
  //           : payloadFromLocalStorage
  //           ? JSON.parse(payloadFromLocalStorage.params?.estados).join(', ')
  //           : JSON.parse(payload.params?.estados).join(', ');

  //       const valueMunicipio =
  //         isPadrao || isMunicipal || isEstadual
  //           ? userToken.endereco?.municipio_id
  //           : payloadFromLocalStorage
  //           ? payloadFromLocalStorage.params?.municipios
  //           : payload.params?.municipios;

  //       const valueEstabelecimentos = isPadrao
  //         ? userToken.estabelecimentoId
  //         : payloadFromLocalStorage
  //         ? String(payloadFromLocalStorage.params?.estabelecimentos)
  //         : String(payload.params?.estabelecimentos);

  //       const valueEquipe = payloadFromLocalStorage
  //         ? payloadFromLocalStorage.params?.equipe
  //         : payload.params?.equipe;

  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         municipioId: valueMunicipio,
  //         estado: valueEstado,
  //         estabelecimentoSaudeId: valueEstabelecimentos,
  //         equipes: valueEquipe,
  //       };

  //       const nomeMunicipio = (await api.get(`/municipios/${valueMunicipio}`))
  //         .data.nome;

  //       setDisplayFilterEstado();
  //       await setDisplayFilterMunicipio(valueMunicipio);
  //       await setDisplayFilterEstabelecimentos({
  //         valueEstabelecimentos,
  //         valueMunicipio: nomeMunicipio,
  //       });
  //       setPayloadFromCharts(payloadToRequest);
  //     }

  //     if (selectedChartIsResult) {
  //       const value = localStorage.getItem('result');
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         resultado: value,
  //       };

  //       setValue(
  //         'resultado',
  //         optionsResultadoHPV.find((item) => item.value === value)
  //       );

  //       handleSetListFilterShowFromCharts(
  //         'resultado',
  //         `Resultado: ${
  //           optionsResultadoHPV.find((item) => item.value === value)?.label
  //         }`
  //       );

  //       setLoading(false);
  //     }

  //     if (selectedChartIsReason) {
  //       const value = localStorage.getItem('reasonNotProcedure');
  //       setValue('dt_criado_inicio', payloadToRequest.dt_coleta_inicio);
  //       setValue('dt_criado_fim', payloadToRequest.dt_coleta_fim);
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         atendimento_status: value,
  //         procedimento: 'false',
  //         dt_coleta_inicio: undefined,
  //         dt_coleta_fim: undefined,
  //         dt_criado_inicio: payloadToRequest.dt_coleta_inicio,
  //         dt_criado_fim: payloadToRequest.dt_coleta_fim,
  //       };
  //       setValue('dtColetaIni', undefined);
  //       setValue('dtColetaFinal', undefined);
  //       setValue('atendimento_status', value);
  //       setValue('realizou_atendimento', 'Não');

  //       // handleSetListFilterShowFromCharts(
  //       //   'realizou_atendimento',
  //       //   'Realizou o atendimento?: Não'
  //       // );
  //       // handleSetListFilterShowFromCharts(
  //       //   'atendimento_status',
  //       //   `Motivo do Não Atendimento: ${
  //       //     optionsMotivoNaoAtendida.find((item) => item.value === value)?.label
  //       //   }`
  //       // );

  //       setLoading(false);
  //     }

  //     if (selectedChartOriginCollection) {
  //       const value = localStorage.getItem('collectionOrigin');
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         natureza: value,
  //       };

  //       setValue('natureza', value);

  //       handleSetListFilterShowFromCharts(
  //         'natureza',
  //         value === 'autocoleta'
  //           ? 'Método de Coleta: Autocoleta'
  //           : 'Método de Coleta: Profissional de Saúde'
  //       );

  //       setLoading(false);
  //     }

  //     if (selectedChartIsTypeVirus) {
  //       const value = localStorage.getItem('typeVirus');
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         tipo_virus: value,
  //         resultado: 'POSITIVO',
  //       };

  //       setValue(
  //         'resultado',
  //         optionsResultadoHPV.find((item) => item.value === 'POSITIVO')
  //       );
  //       setValue(
  //         'tipo_virus',
  //         optionsTiposVirus.find((item) => item.value === value)
  //       );

  //       handleSetListFilterShowFromCharts('resultado', 'Resultado: Detectável');
  //       handleSetListFilterShowFromCharts(
  //         'tipo_virus',
  //         `Tipo de Vírus: ${
  //           optionsTiposVirus.find((item) => item.value === value)?.label
  //         }`
  //       );

  //       setLoading(false);
  //     }
  //     resolve(payloadToRequest);
  //   });
  // };

  // const setFiltersWhenAdminLaboratorio = async (userToken) => {
  //   setShowTableData(false);
  //   setDisableInputEstado(true);

  //   const estado =
  //     userToken.estabelecimento_saude?.endereco.municipio.estadoSigla;
  //   const laboratorioId = userToken.estabelecimento_saude?.id;
  //   const laboratorioName = userToken.estabelecimento_saude?.nome_fantasia;

  //   return new Promise(async (resolve, reject) => {
  //     let payload = {};
  //     setEstadosOptions([
  //       {
  //         sigla: estado,
  //       },
  //     ]);
  //     setEstadosSelected([estado]);
  //     setOptionsLaboratorios([
  //       {
  //         label: laboratorioName,
  //         value: laboratorioId,
  //       },
  //     ]);
  //     setSelectedOptionsLaboratorio([
  //       {
  //         label: laboratorioName,
  //         value: laboratorioId,
  //       },
  //     ]);
  //     setSelectedLaboratorio([laboratorioId]);

  //     payload = {
  //       page: 1,
  //       size: 25,
  //       estados: estado,
  //       estabelecimentoLaudoId: laboratorioId,
  //     };

  //     handleSetFilter(
  //       `Laboratório Responsável pelo Laudo: ${laboratorioName}`,
  //       'estabelecimentoLaudoId'
  //     );
  //     handleSetFilter(`Estado: ${estado}`, 'estado');
  //     resolve(payload);
  //   });
  // };

  // const setFiltersWhenPadrao = async (userToken) => {
  //   setShowTableData(true);
  //   setDisableInputEstado(true);

  //   setDisabledMunicipioInput(true);

  //   return new Promise(async (resolve, reject) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     const optionCidade = [
  //       {
  //         id: userToken.endereco?.municipio_id,

  //         nome: userToken.endereco?.municipio_nome,
  //         codigo: userToken.endereco?.municipio_code,
  //         estadoSigla: userToken.endereco?.estado,
  //       },
  //     ];

  //     setOptionsMunicipios(optionCidade);

  //     setMunicipiosSelected([optionCidade[0].id]);
  //     handleSetFilter('Estado: PE', 'estados');
  //     handleSetFilter(
  //       `Municipio: ${userToken.endereco?.municipio_nome}`,
  //       'municipios'
  //     );
  //     if (isLaboratorio) {
  //       setDisabledLaboratorioResultado(true);
  //       setSelectedLaboratorio([userToken.estabelecimentoId]);
  //       payload = {
  //         page: 1,
  //         size: 25,
  //         estados: 'PE',
  //         municipios: optionCidade[0].id,
  //         estabelecimentoLaudoId: userToken.estabelecimentoId,
  //       };

  //       handleSetFilter(
  //         `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //         'estabelecimentoLaudoId'
  //       );

  //       setListFilterShow([
  //         {
  //           inputName: 'estados',
  //           value: 'Estado: PE',
  //         },
  //         {
  //           inputName: 'municipios',
  //           value: `Municipio: ${userToken.endereco?.municipio_nome}`,
  //         },
  //         {
  //           inputName: 'estabelecimentoLaudoId',
  //           value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //         },
  //       ]);
  //     } else {
  //       setDisabledEstabelecimentoInput(true);
  //       setEstabelecimentosSelected([userToken.estabelecimentoId]);
  //       payload = {
  //         page: 1,
  //         size: 25,
  //         estados: 'PE',
  //         municipios: optionCidade[0].id,
  //         estabelecimentoSaudeId: userToken.estabelecimentoId,
  //       };

  //       handleSetFilter(
  //         `Estabelecimento de Saúde: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //         'estabelecimento_saude'
  //       );

  //       setListFilterShow([
  //         {
  //           inputName: 'estados',
  //           value: 'Estado: PE',
  //         },
  //         {
  //           inputName: 'municipios',
  //           value: `Municipio: ${userToken.endereco?.municipio_nome}`,
  //         },
  //         {
  //           inputName: 'estabelecimento_saude',
  //           value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //         },
  //       ]);
  //     }

  //     resolve(payload);
  //   });
  // };

  // const setFiltersWhenMunicipal = async (userToken) => {
  //   setShowTableData(false);
  //   setDisableInputEstado(true);
  //   setDisabledMunicipioInput(true);

  //   return new Promise(async (resolve, reject) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     const municipio = await getMunicipioDetails(
  //       userToken.endereco?.municipio_nome
  //     );

  //     setMunicipiosSelected([municipio[0].id]);

  //     payload = {
  //       page: 1,
  //       size: 25,
  //       estados: 'PE',
  //       municipios: municipio[0].id,
  //     };

  //     setListFilterShow([
  //       {
  //         inputName: 'estados',
  //         value: 'Estado: PE',
  //       },
  //       {
  //         inputName: 'municipios',
  //         value: `Municipio: ${userToken.endereco?.municipio_nome}`,
  //       },
  //     ]);

  //     resolve(payload);
  //   });
  // };

  // const setFiltersWhenEstadual = async (userToken) => {
  //   setShowTableData(false);
  //   setDisableInputEstado(true);

  //   return new Promise(async (resolve, reject) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     payload = {
  //       page: 1,
  //       size: 25,
  //       estados: 'PE',
  //     };

  //     setListFilterShow([
  //       {
  //         inputName: 'estados',
  //         value: 'Estado: PE',
  //       },
  //     ]);

  //     resolve(payload);
  //   });
  // };

  // const requestDataByUserPerfil = async () => {
  //   let payloadToReturn;
  //   console.log(userToken);
  //   if (
  //     userToken.permissao_atual?.id === 1 ||
  //     userToken.permissao_atual?.id === 10 ||
  //     userToken.permissao_atual?.id === 7 ||
  //     userToken.permissao_atual?.id === 9
  //   ) {
  //     setFiltersWhenPadrao(userToken).then((payload) => {
  //       payloadToReturn = payload;
  //       loadReportsHpv(payload);
  //       setLoading(false);
  //     });
  //   }

  //   if (userToken.permissao_atual?.id === 3) {
  //     setFiltersWhenMunicipal(userToken).then((payload) => {
  //       payloadToReturn = payload;
  //       setLoading(false);
  //     });
  //   }

  //   if (userToken.permissao_atual?.id === 4) {
  //     setFiltersWhenEstadual(userToken).then((payload) => {
  //       payloadToReturn = payload;
  //       setLoading(false);
  //     });
  //   }
  //   if (userToken.permissao_atual?.id === 2) {
  //     setLoading(false);
  //   }

  //   if (userToken.permissao_atual?.id === 6) {
  //     setFiltersWhenAdminLaboratorio(userToken).then((payload) => {
  //       setLoading(false);
  //     });
  //   }

  //   return payloadToReturn;
  // };

  // useEffect(() => {
  //   if (fromTable) {
  //     setLoading(true);
  //     setFiltersToRequestByTable().then((payloadToRequest) => {
  //       setShowTableData(true);
  //       loadReportsHpv(payloadToRequest);
  //     });
  //     window.history.replaceState({}, '');
  //     // localStorage.removeItem('requestFromTable');
  //     // localStorage.removeItem('payload');
  //   } else {
  //     localStorage.removeItem('requestFromTable');
  //     localStorage.removeItem('payload');
  //     setLoading(true);
  //     // requestDataByUserPerfil();
  //   }
  // }, [origin]);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage={fromTable ? '/paineis/teste-hpv' : '/home'} />
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: '30px' }}
          >
            Relatório Geral do Teste de HPV
          </Typography>

          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{ marginBottom: '16px', marginTop: '16px' }}
            sx={{ marginBottom: '24px' }}
          >
            <Link
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to="/home"
            >
              Home
            </Link>

            {fromTable ? (
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#646464',
                }}
                to={'/paineis/teste-hpv'}
              >
                {'Painel do Teste de HPV'}
              </Link>
            ) : (
              <Typography color="text.primary">{'Relatórios'}</Typography>
            )}

            <Typography key="3" color="text.primary">
              Relatório Geral do Teste de HPV{' '}
            </Typography>
          </Breadcrumbs>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />
            <p
              style={{
                fontSize: '12px',
                textAlign: 'right',
                color: '#d32f2f',
                margin: '0',
                marginTop: '20px',
                marginBottom: '-20px',
              }}
            >
              <i>* Campo obrigatório</i>
            </p>
            <FiltersReportsHpv
              selectedOptinsProfissionalResponsavel={
                selectedOptinsProfissionalResponsavel
              }
              setSelectedOptinsProfissionalResponsavel={
                setSelectedOptinsProfissionalResponsavel
              }
              selectedLaboratorio={selectedLaboratorio}
              disabledLaboratorioResultado={disabledLaboratorioResultado}
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              setSelectedLaboratorio={setSelectedLaboratorio}
              setSelectedOptionsLaboratorio={setSelectedOptionsLaboratorio}
              watch={watch}
              dtResultadoIni={dtResultadoIni}
              setDataResultadoIni={setDataResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              setDataResultadoFinal={setDataResultadoFinal}
              periodResultado={periodResultado}
              setPeriodResultado={setPeriodResultado}
              dtColetaIni={dtColetaIni}
              setDataColetaIni={setDataColetaIni}
              dtColetaFinal={dtColetaFinal}
              setDataColetaFinal={setDataColetaFinal}
              setListFilterToShow={setListFilterShow}
              disableInputEstado={disableInputEstado}
              period={period}
              setPeriod={setPeriod}
              listFilter={listFilter}
              setListFilter={setListFilter}
              setListFilterShow={setListFilterShow}
              listFilterShow={listFilterShow}
              initialStateListFilter={initialStateListFilter}
              setTableData={setTableData}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setShowTableData={setShowTableData}
              showTableData={showTableData}
            />

            <TableReportsHpv
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              selectedOptinsProfissionalResponsavel={
                selectedOptinsProfissionalResponsavel
              }
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              equipesSelected={equipesSelected}
              showTableData={showTableData}
              dtColetaFinal={dtColetaFinal}
              dtColetaIni={dtColetaIni}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};

export default ReportsHpv;
