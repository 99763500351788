import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import EmptyData from '../../../../../components/Charts/emptyData';

interface AgeRangeChartProps {
  data: number[];
  categories?: String[];
  height?: number;
  payload: any;
  needsRequiredFilters: boolean;
}

export function AgeRangeChart({
  data,
  payload,
  needsRequiredFilters,
}: AgeRangeChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();
  const emptyData = !data.find((item) => item > 0);

  const navigate = useNavigate();

  const categoriesFaixa = [
    ['25 a 34', 'ANOS'],
    ['35 a 45', 'ANOS'],
    ['46 a 64', 'ANOS'],
    ['FORA DA FAIXA', 'ETÁRIA'],
  ];

  useEffect(() => {
    setChartData({
      series: [
        {
          name: '',
          data: data,
        },
      ],
      chart: {
        type: 'bar',
        height: 284,
        events: {
          dataPointSelection: function (event, chartContext, config) {
            console.log(config.dataPointIndex);

            if (config.dataPointIndex > -1) {
              localStorage.removeItem('reasonNotProcedure');
              localStorage.removeItem('result');
              localStorage.removeItem('collectionOrigin');
              const values = {
                0: '25,34',
                1: '35,45',
                2: '46,64',
                3: 'outRange',
              };
              localStorage.setItem('chartName', 'ageRange');
              localStorage.setItem(
                'itemClicked',
                values[config.dataPointIndex]
              );
              localStorage.setItem('requestFromTable', 'true');
              localStorage.setItem('payload', JSON.stringify(payload));
              window.location.href = '/relatorios/hpv';
            }
          },
          // click: handleBarClick,
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        type: 'category',
        categories: categoriesFaixa,
        labels: {
          rotate: 0,
        },
      },
      yaxis: {
        //tickAmount: 5,
        min: 0,
        //stepSize: 100,
      },
      fill: {
        opacity: 1,
      },
      colors: [
        function ({ value, dataPointIndex, w }) {
          const categoryName = w.config.xaxis.categories[dataPointIndex];

          if (categoryName[0] === 'FORA DA FAIXA') {
            return '#C1CCCD';
          } else {
            return '#7B61FF';
          }
        },
      ],
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            xaxis: {
              labels: {
                style: {
                  fontSize: '10px',
                },
              },
            },
          },
        },
      ],
    });
  }, [data]);

  return (
    <>
      {chartData ? (
        <>
          <div style={{ display: 'none' }}>
            <ul>
              <li data-testid="twentyFiveToThirtyFourYearsTested">{data[0]}</li>
              <li data-testid="thirtyFiveToFourtyFiveYearsTested">{data[1]}</li>
              <li data-testid="FourtySixToSixtyFourYearsTested">{data[2]}</li>
              <li data-testid="outRangeTested">{data[3]}</li>
            </ul>
          </div>
          {/**O componente acima foi construído para que os dados dos gráficos
           * possam ser localizados nos testes automatizados do Cypress
           */}

          <div style={{ position: 'relative' }}>
            {emptyData && (
              <div style={{ position: 'absolute', left: '30%', zIndex: '1' }}>
                <EmptyData needsRequiredFilters={needsRequiredFilters} />
              </div>
            )}
            <ReactApexChart
              options={chartData}
              series={chartData.series}
              type="bar"
              height={284}
            />
          </div>
        </>
      ) : undefined}
    </>
  );
}
