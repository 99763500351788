import { ReactNode, useContext, useEffect, useState } from 'react';
import { ModalBase } from '..';
import CheckIcon from '@mui/icons-material/Check';
import { useStyles } from '../styles';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { Label } from '../../../../../components/Typo';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import api from '../../../../../api';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  footer?: ReactNode;
  children?: ReactNode;
  title: string;
  appointmentTime?: string;
  appointmentDate?: string;
  appointmentStatus?: string;
  patientName?: string;
  profissionalName?: string;
  procedureLocation?: string;
  address?: string;
  examName?: string;
  listTimes: any[];
  optionsPatients: any[];
  profissionalId?: string;
}

export const ModalScheduling = ({
  open = false,
  handleClose,
  footer,
  children,
  title,
  appointmentTime,
  appointmentDate,
  appointmentStatus,
  patientName,
  profissionalName,
  procedureLocation,
  address,
  examName,
  optionsPatients,
  listTimes,
  profissionalId,
}: ModalProps) => {
  const [patient, setPatient] = useState<any>(patientName);

  const [time, setTime] = useState(appointmentTime);

  const styles = useStyles();

  const { currentEstablishment } = useContext(EstablishmentContext);

  const handleSetPatient = (patient) => setPatient(patient);
  const handleSetTime = (time: string) => setTime(time);

  const convertToDate = new Date(String(appointmentDate));
  const originalDate = new Date(convertToDate);

  const newDate = new Date(originalDate);
  newDate.setDate(originalDate.getDate() + 1);

  const diasSemana = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  const formattedDate = `${
    diasSemana[newDate.getDay()]
  }, ${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;

  useEffect(() => {
    if (patientName) {
      setPatient(patientName);
    } else {
      setPatient('');
    }
  }, [patientName]);

  useEffect(() => {
    if (appointmentTime) {
      setTime(appointmentTime);
    } else {
      setTime('');
    }
  }, [appointmentTime]);

  const handleCloseModal = () => {
    handleClose();
    handleSetPatient('');
    handleSetTime('');
  };

  const numeroFormatado =
    currentEstablishment?.endereco.numero === 'S/N'
      ? 'S/N'
      : currentEstablishment?.endereco.numero.padStart(2, '0');
  const cepFormatado =
    currentEstablishment?.endereco.cep.slice(0, 5) +
    '-' +
    currentEstablishment?.endereco.cep.slice(5);

  const enderecoCompleto =
    currentEstablishment?.endereco.logradouro +
    ', ' +
    numeroFormatado +
    ' - ' +
    currentEstablishment?.endereco.bairro +
    ', ' +
    currentEstablishment?.endereco.municipio.nome +
    ' - ' +
    currentEstablishment?.endereco.municipio.estado.sigla +
    ', ' +
    cepFormatado;
  const handleSubmitSchedule = async () => {
    await api
      .get(`/pacientes/${patient?.id}`)
      .then(async (res) => {
        const response = await api.post('/cidadao_agenda/agendamento', {
          id_paciente: patient.id,
          nome: patient.nome,
          cns: res.data.cns,
          cpf: res.data.cpf,
          dt_nascimento: res.data.dt_nascimento,
          nacionalidade: res.data.nacionalidade ?? '',
          estabelecimentoId: currentEstablishment?.id,
          nome_fantasia: currentEstablishment?.nome_fantasia,
          endereco_local: enderecoCompleto,
          procedimento: 'Teste de HPV',
          isagendamento: true,
          nomeProfissional: profissionalName,
          dt_agendamento: appointmentDate,
          idProfissional: profissionalId,
          horario_atendimento: time,
          status: 'Agendado',
          motivo_cancelamento: '',
          motivo_outro: '',
        });
        if (response.status === 201) {
          handleCloseModal();
          setTimeout(() => {
            window.location.reload();
          }, 2000);

          toast.success('Agendamento realizado com Sucesso.', {
            position: 'bottom-right',
          });
        } else {
          toast.success(
            'Ocorreu um erro. Tente novamente ou contate o suporte.',
            {
              position: 'bottom-right',
            }
          );
        }
      })
      .catch((error) => {
        toast.success(
          'Ocorreu um erro. Tente novamente ou contate o suporte.',
          {
            position: 'bottom-right',
          }
        );
      });
  };

  return (
    <>
      <ModalBase
        open={open}
        onClose={handleCloseModal}
        title="Agendamento"
        appointmentDate={formattedDate}
        procedureLocation={currentEstablishment?.name}
        address={enderecoCompleto}
        profissionalName={profissionalName}
        examName={examName}
        footer={
          <div className={styles.footer}>
            <Button
              variant="contained"
              endIcon={<CheckIcon />}
              onClick={() => {
                handleSubmitSchedule();
              }}
            >
              Agendar
            </Button>
          </div>
        }
      >
        <Box mt={2}>
          <Autocomplete
            options={optionsPatients}
            onChange={(_, value) => handleSetPatient(value)}
            getOptionLabel={(option) => {
              return option?.nome;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Paciente"
                size="small"
                placeholder="Busque nome ou cpf do paciente"
              />
            )}
          />
        </Box>

        <Label variant="body1" mt={2} labelcolor="rgba(0, 0, 0, 0.87)">
          Paciente ainda não cadastrada?{' '}
          <Link to="/cadastrar-paciente" className={styles.newPatientLink}>
            Cadastrar Novo Paciente
          </Link>
        </Label>

        <Box mt={2}>
          <Autocomplete
            options={listTimes}
            onChange={(_, value) => handleSetTime(value ?? '')}
            value={time}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField {...params} label="Horário" size="small" />
            )}
          />
        </Box>
      </ModalBase>
    </>
  );
};
