/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ptBR from 'date-fns/locale/pt-BR';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import BoxInfoPatient from '../../components/BoxInfoPatient/BoxInfoPatient';
import { SideMenu } from '../../components/SideMenu';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import { useStyles } from './styles';
import { ModalNewRegister } from '../../components/ModalNewRegister';
import { Escolha, Inputs, Motivo, StateProps } from './interface';
import { schema } from './schema';
import { toast } from 'react-toastify';
import Template from '../../components/Template';

export const Anamnese = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const location = useLocation();
  const state = location.state as StateProps;
  const styles = useStyles();
  const [checkedMenstruacao, setCheckedMenstruacao] =
    React.useState<boolean>(false);
  const [openDialogCancel, setOpenDialogCancel] =
    React.useState<boolean>(false);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  const rhf = watch();

  const onSubmit = async (data: Inputs) => {
    const formatedPayload = {
      ...data,
      dt_menstruacao: data.dt_menstruacao ? data.dt_menstruacao : null,
    };

    try {
      const response = await api.post('/anamnese', { ...formatedPayload });

      if (response.status === 201) {
        toast.success('Anamnese cadastrada.', { position: 'bottom-right' });
      }
    } catch (error) {}
  };

  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },

    '& .MuiFormLabel-asterisk': {
      color: '#d32f2f',
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('dt_menstruacao', '');
    }
    setValue('dt_menstruacao_desconhecida', event.target.checked);
    setCheckedMenstruacao(event.target.checked);
  };

  let navigate = useNavigate();

  React.useEffect(() => {
    async function loadResponsaveis() {}
    loadResponsaveis();
  }, []);

  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate(`/pacientes/${state.id}`);
  };

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <Box
            mb={3}
            justifyContent="space-between"
            display="flex"
            flexWrap="wrap"
          >
            <div>
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{ marginBottom: '5px' }}
              >
                Exame: Anamnese
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px' }}
              >
                <Link key="1" color="inherit" to="/pacientes">
                  Listagem de Pacientes
                </Link>
                <Link
                  className={styles.link}
                  key="1"
                  color="inherit"
                  to={`/pacientes/${state.id}`}
                >
                  Ficha Individual da Paciente
                </Link>
                <Typography key="3" color="text.primary">
                  Exame: Anamnese
                </Typography>
              </Breadcrumbs>
            </div>
          </Box>

          <Box mb={3}>
            <BoxInfoPatient data={state} viewButton={false}></BoxInfoPatient>
          </Box>
        </Box>

        <Box mt={3}>
          <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
            <DialogTitle>Cancelar Cadastro de anamnese</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deseja realmente cancelar o cadastro de anamnese?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogCancel}>Não</Button>
              <Button onClick={handleCloseConfirmCancel} autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>
          <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
            <i>* Resposta obrigatória</i>
          </p>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box>
              <Paper variant="outlined">
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  style={{ margin: '25px', paddingTop: '25px' }}
                >
                  Dados da Anamnese
                </Typography>
                <Divider />
                <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            select
                            required
                            onChange={onChange}
                            value={value ? value : ''}
                            fullWidth
                            label="Motivo do exame"
                            size="small"
                            variant="outlined"
                            error={!!errors.motivo}
                            helperText={errors.motivo?.message}
                            className={styles.textField}
                          >
                            <MenuItem value={Motivo.Rastreamento}>
                              Rastreamento
                            </MenuItem>
                            <MenuItem value={Motivo.Repeticao}>
                              Repetição (exame alterado ASCUS/Baixo grau)
                            </MenuItem>
                            <MenuItem value={Motivo.Seguimento}>
                              Seguimento (pós diagnóstico colposcópico /
                              tratamento)
                            </MenuItem>
                          </StyledAsteriskTextField>
                        )}
                        name="motivo"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={errors?.papanicolaou && true}>
                        <FormLabel sx={styleFormLabel} required>
                          Fez o exame preventivo (Papanicolaou) alguma vez?
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="papanicolaou"
                          control={control}
                        />
                        {errors?.papanicolaou?.message && (
                          <FormHelperText>
                            {errors?.papanicolaou?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {rhf?.papanicolaou === Escolha.Sim ? (
                      <Grid item xs={12} md={4}>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <StyledAsteriskTextField
                              required
                              fullWidth
                              onChange={(e) =>
                                onChange(parseInt(e.target.value))
                              }
                              value={value ? value : ''}
                              label="Ano de realização exame preventivo (Papanicolaou)"
                              size="small"
                              variant="outlined"
                              error={!!errors?.ano_papanicolaou}
                              helperText={errors?.ano_papanicolaou?.message}
                              inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                              }}
                              className={styles.textField}
                            />
                          )}
                          name="ano_papanicolaou"
                          control={control}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <FormControl error={errors?.diu && true}>
                        <FormLabel required sx={styleFormLabel}>
                          Usa DIU?
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="diu"
                          control={control}
                        />
                        <FormHelperText>{errors?.diu?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={errors?.gravida && true}>
                        <FormLabel
                          required
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Está Grávida?
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="gravida"
                          control={control}
                        />
                        <FormHelperText>
                          {errors?.gravida?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={errors?.anticoncepcional && true}>
                        <FormLabel
                          required
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Usa pílula anticoncepcional?
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="anticoncepcional"
                          control={control}
                        />
                        <FormHelperText>
                          {errors?.anticoncepcional?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={errors?.hormonio && true}>
                        <FormLabel
                          required
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Usa hormônio / remédio para tratar a menopausa?
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="hormonio"
                          control={control}
                        />
                        <FormHelperText>
                          {errors?.hormonio?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={errors?.radioterapia && true}>
                        <FormLabel
                          required
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Já fez tratamento por radioterapia?
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="radioterapia"
                          control={control}
                        />
                        <FormHelperText>
                          {errors?.radioterapia?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12}>
                      <FormControl
                        style={{ width: '100%', display: 'flex' }}
                        error={errors?.dt_menstruacao && true}
                      >
                        <Grid item xs={12}>
                          <FormLabel
                            required={!checkedMenstruacao}
                            sx={styleFormLabel}
                            id="demo-row-radio-buttons-group-label"
                          >
                            Data da última menstruação / regra
                          </FormLabel>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptBR}
                          >
                            <div>
                              <Controller
                                control={control}
                                name="dt_menstruacao"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <DatePicker
                                      mask="__/__/____"
                                      disabled={checkedMenstruacao}
                                      onChange={onChange}
                                      value={value ? value : null}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          error={
                                            errors?.dt_menstruacao &&
                                            true &&
                                            !checkedMenstruacao
                                          }
                                          style={{ marginTop: '20px' }}
                                          label="Data da última menstruação"
                                          size="small"
                                          variant="outlined"
                                          className={styles.textField}
                                          fullWidth
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                              {checkedMenstruacao ? undefined : (
                                <FormHelperText>
                                  {errors?.dt_menstruacao?.message}
                                </FormHelperText>
                              )}
                            </div>
                          </LocalizationProvider>
                          <FormControlLabel
                            style={{ marginTop: '15px', marginLeft: '15px' }}
                            name="dt_menstruacao_desconhecida"
                            control={
                              <Checkbox
                                name="dt_menstruacao_desconhecida"
                                checked={checkedMenstruacao}
                                onChange={handleChange}
                                sx={{
                                  color: '#238884',
                                  '&.Mui-checked': {
                                    color: '#238884',
                                  },
                                }}
                              />
                            }
                            label="Não sabe / Não lembra"
                          />
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={errors?.sangramento_relacao && true}>
                        <FormLabel
                          required
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Tem ou teve algum sangramento após relações sexuais?
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não / Não Sabe / Não Lembra"
                              />
                            </RadioGroup>
                          )}
                          name="sangramento_relacao"
                          control={control}
                        />
                        <FormHelperText style={{ fontStyle: 'italic' }}>
                          Não considerar a primeira relação sexual na vida
                        </FormHelperText>
                        <FormHelperText>
                          {errors?.sangramento_relacao?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        error={errors?.sangramento_menopausa && true}
                      >
                        <FormLabel
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Tem ou teve algum sangramento após a menopausa?
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não / Não Sabe / Não Lembra"
                              />
                            </RadioGroup>
                          )}
                          name="sangramento_menopausa"
                          control={control}
                        />
                        <FormHelperText style={{ fontStyle: 'italic' }}>
                          Não considerar o(s) sangramento(s)na vigência de
                          reposição hormonal
                        </FormHelperText>
                        <FormHelperText>
                          {errors?.sangramento_menopausa?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>

            <Box mt={3} display="flex" justifyContent="space-between">
              <Button
                sx={{
                  backgroundColor: '#17BEBB',
                }}
                variant="contained"
                type="submit"
                startIcon={<CheckIcon />}
                // onClick={() => setModalOpen(true)}
              >
                Cadastrar
              </Button>

              <Button
                variant="outlined"
                color="error"
                onClick={handleClickOpenDialogCancel}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </Box>

        <ModalNewRegister
          open={modalOpen}
          setOpen={setModalOpen}
          registerType={'anamnese'}
          patientData={state}
          onClickConfirmbutton={() => navigate(`/pacientes/${state.id}`)}
        />
      </Container>
    </SideMenu>
  );
};
