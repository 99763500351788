/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import api from '../../../api';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { Inputs } from '../../../types/Citologia/types';
import { useStyles } from '../RegisterExam.style';
import { Escolha, Motivo } from './Hpv';
import { schemaCitologia } from './schemCitologia';
import { getUserToken } from '../../../lib/auth';
import AnmamneseForm from '../Anamnese';
import { toast } from 'react-toastify';
import moment from 'moment';

export const Citologia = ({ data }) => {
  const [openDialogCancel, setOpenDialogCancel] = useState<boolean>(false);
  const navigate = useNavigate();
  const styles = useStyles();
  const location = useLocation();

  const [nameResponsavel, setNameResponsavel] = useState();

  const {
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
    control,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(schemaCitologia),
  });
  const rhf = watch();
  const [checkedMenstruacao, setCheckedMenstruacao] = useState<boolean>(false);
  const [responsaveis, setResponsaveis] = useState<any>([]);

  const state: any = location.state;

  // useEffect(() => {
  //   const state: any = location.state;
  //   async function loadResponsaveis() {
  //     const estabelecimentos = await api.get('/estabelecimentos-saude');
  //     const profissionais = await api.get('/profissionais');
  //     setValue('estabelecimentoSaudeId', estabelecimentos.data.data[0].id);
  //     setValue('profissionalId', profissionais.data.data[0].id);
  //   }
  //   loadResponsaveis();
  // }, []);

  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate(`/pacientes/${data.patientData.id}`);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('anamnese.dt_menstruacao', '');
    }
    setValue('anamnese.dt_menstruacao_desconhecida', event.target.checked);
    setCheckedMenstruacao(event.target.checked);
  };

  const tokenCurrentUser = getUserToken();

  const onSubmit = async (data) => {
    if (data.rede_atendimento && !data.laboratorio_responsavel) {
      setError('laboratorio_responsavel', {
        type: 'custom',
        message: 'Campo Obrigatório',
      });
    }

    const state: any = location.state;

    if (data.anamnese.sangramento_menopausa === undefined) {
      data = {
        ...data,
        clinico: {
          ...data.clinico,
          dt_coleta: moment(data.dt_coleta).format('YYYY-MM-DD'),
        },
        anamnese: {
          ...data.anamnese,
          sangramento_menopausa: null,
        },
      };
    }

    const dataFormatedMenstruacaoDate = {
      ...data,
      clinico: {
        ...data.clinico,
        dt_coleta: moment(data.dt_coleta).format('YYYY-MM-DD'),
      },

      anamnese: {
        ...data.anamnese,
        dt_menstruacao: !!data.anamnese.dt_menstruacao
          ? data.anamnese.dt_menstruacao
          : null,
      },
    };

    dataFormatedMenstruacaoDate.clinico.autocoleta = false;

    try {
      await api.post('/citologia', { ...dataFormatedMenstruacaoDate });
      navigate(`/pacientes/${state.patientData.id}`);
    } catch (err: any) {
      toast.error(err.error.message, { position: 'bottom-right' });
      console.error(err);
    }
  };

  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },

    '& .MuiFormLabel-asterisk': {
      color: 'red',
    },
  };

  const isPadrao = getUserToken().permissao_atual?.id === 1;

  const getCurrentProfessional = async (cpf) => {
    try {
      const response = await api.get('/profissionais', { params: { cpf } });

      setValue('profissionalId', response.data?.data[0]?.id);
    } catch (error) {
      console.error(error);
      toast.error('Recarregue a página', { position: 'bottom-right' });
    }
  };

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  useEffect(() => {
    if (isPadrao) {
      getCurrentProfessional(getUserToken().pessoa?.cpf);
    }
  }, []);

  useEffect(() => {
    const state: any = location.state;
    async function loadResponsaveis() {
      const profissionais = await api.get(
        `/profissionais/estabelecimento/${currentEstablishmentId}`
      );
      setResponsaveis(profissionais.data);
      setValue('pessoaId', state.patientData.id);

      setValue('estabelecimentoSaudeId', Number(currentEstablishmentId));
    }
    loadResponsaveis();
  }, []);

  useEffect(() => {
    const userToken = getUserToken();

    const loadResponsaveis = async () => {
      try {
        const currentUser = await api.get(`/profissionais/`, {
          params: {
            cpf: tokenCurrentUser.pessoa?.cpf,
          },
        });

        if (isPadrao) {
          setNameResponsavel(currentUser.data.data[0].pessoa.nome);
          setValue('clinico.responsavel_coleta', {
            id: currentUser.data.data[0].id,
          });
          setValue('profissionalId', currentUser.data.data[0].id);
          setValue('clinico.responsavelId', currentUser.data.data[0].id);
          setValue('clinico.responsavel', currentUser.data.data[0]);
          setValue('estabelecimentoSaudeId', userToken.estabelecimento_saude);
        }
      } catch (error) {}
    };
    setValue('pessoaId', state.patientData.id);

    loadResponsaveis();
  }, []);

  useEffect(() => {
    setValue('tipo', 'Rastreio');
  }, []);

  return (
    <Box mt={12}>
      <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
        <i>* Resposta obrigatória</i>
      </p>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <AnmamneseForm
          errors={errors}
          control={control}
          watch={watch}
          styles={styles}
          checkedMenstruacao={checkedMenstruacao}
          setCheckedMenstruacao={setCheckedMenstruacao}
          clearErrors={clearErrors}
          setValue={setValue}
          screenMode="register"
          idPatient={location?.state?.patientData?.id}
        />

        <Paper
          variant="outlined"
          sx={{ borderRadius: '10px', marginTop: '24px' }}
        >
          <Box p={3}>
            <Typography variant="h5" fontWeight="bold">
              Exame Clínico
            </Typography>

            <Box my={3}>
              <Divider />
            </Box>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name="clinico.inspecao"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        options={[
                          { label: 'Normal', value: 'NORMAL' },
                          {
                            label:
                              'Ausente (anomalias congênitas ou retirado cirurgicamente)',
                            value: 'AUSENTE',
                          },
                          {
                            label: 'Alterado',
                            value: 'ALTERADO',
                          },
                          {
                            label: 'Colo Não Visualizado',
                            value: 'NAO_VISUALIZADO',
                          },
                        ]}
                        size="small"
                        sx={{ width: '100%' }}
                        fullWidth
                        onChange={(_, newValue) => {
                          onChange(newValue?.value ?? null);
                        }}
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            error={!!errors.clinico?.inspecao}
                            label="Inspeção do Colo"
                            fullWidth
                            helperText={errors.clinico?.inspecao?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6.5}>
                <Alert severity="warning">
                  Na presença de colo alterado, com{' '}
                  <b>lesão sugestiva de câncer</b>, não aguardar
                  <br /> o resultado do TESTE HPV para <b>encaminhar</b> a
                  mulher para <b>colposcopia.</b>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="clinico.dst"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <>
                        <FormLabel
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                          required
                          error={!!errors.clinico?.dst}
                        >
                          Sinais sugestivos de doenças sexualmente
                          transmissíveis?
                        </FormLabel>
                        <RadioGroup
                          row
                          onChange={(_, newValue) => {
                            if (newValue === 'true') {
                              onChange(true);
                            } else if (newValue === 'false') {
                              onChange(false);
                            } else if (newValue === undefined) {
                              onChange(null);
                            }
                          }}
                          value={value}
                        >
                          <FormControlLabel
                            value={true}
                            label="Sim"
                            control={<Radio />}
                          />
                          <FormControlLabel
                            value={false}
                            label="Não"
                            control={<Radio />}
                          />
                        </RadioGroup>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {errors.clinico?.dst?.message}
                        </FormHelperText>
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <Controller
                    control={control}
                    name="clinico.dt_coleta"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={(date) => {
                          onChange(date);
                          setValue('dt_coleta', date);
                        }}
                        mask="__/__/____"
                        value={value ? value : null}
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            required
                            {...params}
                            label="Data da coleta"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            fullWidth
                            error={!!errors.clinico?.dt_coleta}
                            helperText={errors.clinico?.dt_coleta?.message}
                          />
                        )}
                      />
                    )}
                  />
                  <FormHelperText>{errors.dt_coleta?.message}</FormHelperText>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={9}>
                <Controller
                  name="clinico.responsavel_coleta"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        options={responsaveis}
                        getOptionLabel={(option: any) => {
                          return option?.nomeprofissional;
                        }}
                        onChange={(_, newValue) => {
                          onChange({ id: newValue.idprofissional });

                          setValue(
                            'profissionalId',
                            Number(newValue.idprofissional)
                          );
                          setValue(
                            'clinico.responsavelId',
                            Number(newValue.idprofissional)
                          );
                        }}
                        // value={value ? value : ''}
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            fullWidth
                            disabled={isPadrao}
                            label="Profissional Responsável pela Coleta"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            error={!!errors.profissionalId && true}
                            helperText={errors.profissionalId?.message}
                          />
                        )}
                      />
                      // <StyledAsteriskTextField
                      //   required
                      //   select
                      //   fullWidth
                      //   disabled={isPadrao}
                      //   label="Profissional Responsável pela Coleta"
                      //   size="small"
                      //   variant="outlined"
                      //   className={styles.textField}
                      //   error={!!errors.profissionalId && true}
                      //   helperText={errors.profissionalId?.message}
                      //   value={value ? value : ''}
                      //   onChange={(e) => {
                      //     onChange(e.target.value);
                      //     setValue('profissionalId', Number(e.target.value));
                      //     setValue('profissionalId', Number(e.target.value));
                      //   }}
                      // >
                      //   {responsaveis.map((option: any, index: number) => {
                      //     return (
                      //       <MenuItem key={index} value={option.idprofissional}>
                      //         {option.nomeprofissional}
                      //       </MenuItem>
                      //     );
                      //   })}
                      // </StyledAsteriskTextField>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="clinico.local_coleta"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        options={[
                          {
                            label: 'Serviço de Saúde',
                            value: 'SERVICO_SAUDE',
                          },
                          { label: 'Unidade Móvel', value: 'UNIDADE_MOVEL' },
                          { label: 'Outro Local', value: 'OUTRO' },
                        ]}
                        size="small"
                        sx={{ width: '100%' }}
                        fullWidth
                        onChange={(_, newValue) =>
                          onChange(newValue?.value ?? null)
                        }
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            label="Local da Coleta"
                            fullWidth
                            error={!!errors.clinico?.local_coleta}
                            helperText={errors.clinico?.local_coleta?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="clinico.outro_local_coleta"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        disabled={watch('clinico.local_coleta') !== 'OUTRO'}
                        label="Outro Local"
                        size="small"
                        fullWidth
                        onChange={onChange}
                        value={value}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="clinico.mtd_coleta"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        options={[
                          {
                            label: 'Convencional',
                            value: { id: 1, nome: 'Convencional' },
                          },
                          {
                            label: 'Meio Líquido',
                            value: { id: 2, nome: 'Meio Líquido' },
                          },
                        ]}
                        size="small"
                        sx={{ width: '100%' }}
                        fullWidth
                        onChange={(_, newValue) =>
                          onChange(newValue?.value ?? null)
                        }
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            label="Método de Coleta de Citologia"
                            fullWidth
                            error={!!errors.clinico?.mtd_coleta}
                            helperText={errors.clinico?.mtd_coleta?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Box display="flex" justifyContent="space-between" marginTop="16px">
          <Button
            sx={{
              backgroundColor: '#238884',
            }}
            variant="contained"
            type="submit"
            startIcon={<CheckIcon />}
          >
            Cadastrar
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={handleClickOpenDialogCancel}
            startIcon={<ClearIcon />}
          >
            Cancelar
          </Button>
        </Box>
      </form>

      <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
        <DialogTitle>Cancelar Cadastro de exame</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente cancelar o cadastro de exame ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogCancel}>Não</Button>
          <Button onClick={handleCloseConfirmCancel} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
