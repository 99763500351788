import moment from 'moment';
import {
  optionsMotivoNaoAtendida,
  optionsResultadoCitologia,
  optionsResultadoHPV,
  optionsTipoAchados,
  optionsPreNeoplasicaEscamosa,
  optionsPreNeoplasicaGlandular,
  optionsOrigemHistopatologia,
} from '../../../../../utils/variables';

interface SetPayloadProps {
  watch: () => any;
  estadosSelected: string[];
  municipiosSelected: string[];
  estabelecimentosSelected: string[];
  dtColetaIni: string | null;
  dtColetaFinal: string | null;
  dtResultadoIni: string | null;
  dtResultadoFinal: string | null;
}

const useSetPayload = ({
  watch,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  dtColetaIni,
  dtColetaFinal,
  dtResultadoIni,
  dtResultadoFinal,
}: SetPayloadProps) => {
  const setPayload = () => {
    return new Promise((resolve) => {
      const formValues = watch();

      const formatDate = (date: string | null) => {
        if (!date) return undefined;
        const formatted = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        // console.log(`Formatando data ${date} -> ${formatted}`);
        return formatted;
      };

      const getDatesForProcedure = () => {
        const hasColeta = Boolean(formValues.procedimento_coleta);
        const hasResultado = Boolean(formValues.procedimento_resultado);

        if (!hasColeta && !hasResultado) {
          // console.log('No procedimento_coleta selected');
          return {};
        }

        const procedureMap = {
          TESTE_HPV: {
            coleta: ['dataColetaHpvInicio', 'dataColetaHpvFim'],
            resultado: ['dataResultadoHpvInicio', 'dataResultadoHpvFim'],
          },
          CITOLOGIA: {
            resultado: [
              'dataResultadoCitologiaInicio',
              'dataResultadoCitologiaFim',
            ],
          },
          COLPOSCOPIA: {
            coleta: ['dataColposcopiaInicio', 'dataColposcopiaFim'],
          },
          HISTOPATOLOGIA: {
            coleta: [
              'dataColetaHistopatologiaInicio',
              'dataColetaHistopatologiaFim',
            ],
            resultado: [
              'dataResultadoHistopatologiaInicio',
              'dataResultadoHistopatologiaFim',
            ],
          },
        };

        const dates: Record<string, string | undefined> = {};

        const procedures = [
          formValues.procedimento_coleta,
          formValues.procedimento_resultado,
        ].filter(Boolean);

        procedures.forEach((proc) => {
          const procedure = procedureMap[proc];
          if (!procedure) return;

          if ('coleta' in procedure) {
            dates[procedure.coleta[0]] = formatDate(dtColetaIni);
            dates[procedure.coleta[1]] = formatDate(dtColetaFinal);
          }

          if ('resultado' in procedure) {
            dates[procedure.resultado[0]] = formatDate(dtResultadoIni);
            dates[procedure.resultado[1]] = formatDate(dtResultadoFinal);
          }
        });

        return dates;
      };

      const getAtendimentoTesteHpv = () => {
        const atendimentoValues = formValues.atendimentoTesteHpv || [];
        const motivos = formValues.motivo_nao_atendimento || [];

        if (!atendimentoValues.length) {
          return undefined;
        }

        if (
          atendimentoValues.includes('Sim') &&
          atendimentoValues.includes('Não')
        ) {
          return 'REALIZADO,NAO_REALIZADO';
        }

        if (atendimentoValues.includes('Não')) {
          if (motivos.length > 0) {
            const motivosMapeados = motivos.map((motivo) => {
              const option = optionsMotivoNaoAtendida.find(
                (opt) => opt.label === motivo
              );
              return option ? option.value : motivo;
            });
            return motivosMapeados.join(',');
          }
          return 'NAO_REALIZADO';
        }

        if (atendimentoValues.includes('Sim')) {
          return 'REALIZADO';
        }

        return undefined;
      };

      const getLabelValue = (options: any[], label: string) => {
        const option = options.find((opt) => opt.label === label);
        return option ? option.value : label;
      };

      const formatArrayForBackend = (
        values: any[] | undefined | null,
        prefix = '',
        type?: string
      ) => {
        if (!values || values.length === 0) return undefined;

        let mappedValues = values;

        switch (type) {
          case 'resultadoCitologia':
            mappedValues = values.map((v) =>
              getLabelValue(optionsResultadoCitologia, v)
            );
            break;
          case 'resultadoHPV':
            mappedValues = values.map((v) =>
              getLabelValue(optionsResultadoHPV, v)
            );
            break;
          case 'tipoAchados':
            mappedValues = values.map((v) =>
              getLabelValue(optionsTipoAchados, v)
            );
            break;
          case 'preNeoplasica':
            mappedValues = values.map((v) =>
              getLabelValue(optionsPreNeoplasicaEscamosa, v)
            );
            break;
          case 'preNeoplasicaGlandular':
            mappedValues = values.map((v) =>
              getLabelValue(optionsPreNeoplasicaGlandular, v)
            );
            break;
          case 'origem':
            mappedValues = values.map((v) =>
              getLabelValue(optionsOrigemHistopatologia, v)
            );
            break;
        }

        return mappedValues.map((v) => `${prefix}${v}`).join(',');
      };

      const payload = {
        // Location filters
        estados:
          estadosSelected.length > 0 ? estadosSelected.join(',') : undefined,
        municipios:
          municipiosSelected?.length > 0
            ? municipiosSelected.join(',')
            : undefined,
        estabelecimentoSaudeId:
          estabelecimentosSelected.length > 0
            ? estabelecimentosSelected.join(',')
            : undefined,

        // Status and results
        atendimentoTesteHpv: getAtendimentoTesteHpv(),
        resultadoTesteHpv: formatArrayForBackend(
          formValues.resultadoTesteHpv,
          '',
          'resultadoHPV'
        ),
        resultadoCitologia: formatArrayForBackend(
          formValues.resultadoCitologia,
          '',
          'resultadoCitologia'
        ),
        tipoAchados: formatArrayForBackend(
          formValues.tipos_achados,
          '',
          'tipoAchados'
        ),
        realizarBiopsia: formatArrayForBackend(formValues.realizar_biopsia),
        origemHistopatologia: formatArrayForBackend(
          formValues.origem_histopatologia,
          '',
          'origem'
        ),
        preNeoplasicaEscamosa: formatArrayForBackend(
          formValues.pre_neoplasico_escamosa,
          '',
          'preNeoplasica'
        ),
        preNeoplasicaGlandular: formatArrayForBackend(
          formValues.pre_neoplasico_glandular,
          '',
          'preNeoplasicaGlandular'
        ),
        indicacaoEzt: formatArrayForBackend(formValues.indicacao_ezt),

        // Search and pagination
        search: formValues.search || undefined,
        page: formValues.page || 1,
        size: formValues.size || 25,

        // Dates
        ...getDatesForProcedure(),
      };

      const cleanPayload = Object.entries(payload).reduce<Record<string, any>>(
        (acc, [key, value]) => {
          if (value !== undefined && value !== null && value !== '') {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      resolve(cleanPayload);
    });
  };

  return { setPayload };
};

export default useSetPayload;
