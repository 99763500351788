import moment from 'moment';
import { formatedMultiples } from '../../../../../utils/requestUtils/formatMultiples';

interface SetPayloadProps {
  payloadFromPanel?: any;
  requestFromPanel?: boolean;
}

const useSetPayload = ({
  watch,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  equipesSelected,
  dtColetaIni,
  dtColetaFinal,
  dtResultadoIni,
  dtResultadoFinal,
  selectedLaboratorio,
  selectedOptionsLaboratorio,
  selectedOptinsProfissionalResponsavel,
}: any) => {
  const setPayload = ({
    payloadFromPanel,
    requestFromPanel,
  }: SetPayloadProps) => {
    if (requestFromPanel) {
      estadosSelected =
        payloadFromPanel?.params?.estados.split(',').length > 1
          ? payloadFromPanel?.params?.estados
              .split(',')
              .map((estId) => estId.trim())
          : [payloadFromPanel?.params?.estados];
      estabelecimentosSelected =
        payloadFromPanel?.params?.municipios.split(',').length > 1
          ? null
          : payloadFromPanel?.params?.estabelecimentos
              ?.split(',')
              .map((item) => item.trim())
              .reverse();
      municipiosSelected = payloadFromPanel?.params?.municipios
        ? payloadFromPanel?.params?.municipios.split(',').length > 1
          ? payloadFromPanel?.params?.municipios
              .split(',')
              .map((munId) => munId.trim())
          : [Number(payloadFromPanel?.params?.municipios)]
        : null;
    }
    const estados = estadosSelected.map((estado) => {
      if (estado === 'Sem Vinculação') {
        return 'null';
      } else {
        return estado;
      }
    });

    const arrayIdsLaboratorios = selectedOptionsLaboratorio?.map(
      (lab) => lab.value
    );

    let payload: any;
    return new Promise((resolve, reject) => {
      payload = {
        dt_coleta_inicio: dtColetaIni
          ? moment(dtColetaIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,
        dt_coleta_fim: dtColetaFinal
          ? moment(dtColetaFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,
        dt_resultado_fim: dtResultadoFinal
          ? moment(dtResultadoFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,
        dt_resultado_inicio: dtResultadoIni
          ? moment(dtResultadoIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,
        estados: estados.length > 0 ? estados.join(', ') : undefined,
        municipios:
          municipiosSelected?.length > 0
            ? municipiosSelected.join(', ')
            : undefined,
        estabelecimentoSaudeId:
          estabelecimentosSelected?.length > 0
            ? estabelecimentosSelected.join(', ')
            : undefined,
        dt_criado_inicio: watch('dt_criado_inicio')
          ? moment(watch().dt_criado_inicio).format('YYYY-MM-DD')
          : undefined,
        inspecao: watch('inspecao_colo'),
        dt_criado_fim: watch('dt_criado_fim')
          ? moment(watch().dt_criado_fim).format('YYYY-MM-DD')
          : undefined,
        equipes:
          equipesSelected.length > 0 ? equipesSelected.join(',') : undefined,
        dt_coleta: watch('dt_coleta')
          ? moment(watch().dt_coleta).format('YYYY-MM-DD')
          : undefined,
        profissionalColetaId: formatedMultiples(
          selectedOptinsProfissionalResponsavel
        ),
        dt_resultado: watch('dt_resultado')
          ? moment(watch().dt_resultado).format('YYYY-MM-DD')
          : undefined,
        atendimento_status: watch().atendimento_status?.value ?? undefined,
        // status: watch().status ?? undefined,
        procedimento: watch().procedimento ?? undefined,
        realizou_atendimento: watch().realizou_atendimento ?? undefined,
        tipo_virus: watch().tipo_virus?.value ?? undefined,
        natureza: watch().natureza ?? undefined,
        estabelecimentoLaudoId:
          arrayIdsLaboratorios?.length > 0
            ? arrayIdsLaboratorios?.join(', ')
            : undefined,

        resultado:
          watch().resultado?.length > 0
            ? watch()
                .resultado.map((option) => option.value)
                .join(', ')
            : undefined,
        idade_inicio: watch().idade_ini ?? undefined,
        idade_final: watch().idade_fin ?? undefined,
        motivo: watch().motivo ?? undefined,
        search: watch().search ?? undefined,
        size: watch().size,
        page: watch().page,
      };

      resolve(payload);
    });
  };

  return { setPayload };
};

export default useSetPayload;
