// import { useContext } from 'react';
// import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
// import { getUserToken } from '../../../../../lib/auth';

// const useClearValues = ({
//   permissao,
//   setValue,
//   handleSetFilter,
//   watch,
//   loadReportsColposcopia,

//   setEstadosSelected,
//   setMunicipiosSelected,
//   setEstabelecimentosSelected,
//   showTableData,
//   setShowTableData,
//   setTableData,
//   setErrorsFields,
//   setListFilterShow,

//   setDataResultadoFinal,
//   setDataResultadoIni,
//   setSelectedResultado,
//   setSelectedOptionsAvaliacao,
//   setSelectedOptionsVisibilidade,
//   setSelectedOptionsZt,
//   setSelectedOptionsTiposAchados,
//   setSelectedOptionsLugol,
//   setSelectedOptionsSchiller,
//   setSelectedOptionsBiopsia,
//   setPayload,
//   setDtColposcopiaIni,
//   setDtColposcopiaFinal,
//   setSelectedOptionsAlteracoes,
//   setSelectedOptionsLaboratorio,
//   setSelectedLaboratorio,
// }) => {
//   const handleSetFilterToShow = (newValue: any, inputName: string) => {
//     setListFilterShow((prev) =>
//       prev.map((item) =>
//         item.inputName === inputName ? { ...item, value: newValue } : item
//       )
//     );
//   };

//   const clearValues = async () => {
//     setErrorsFields([]);
//     setTableData([]);

//     setDtColposcopiaIni(null);
//     setDataResultadoFinal(null);
//     setDtColposcopiaFinal(null);
//     setDataResultadoIni(null);
//     setSelectedResultado();
//     setValue('profissionalResultadoId', null);
//     setSelectedOptionsAvaliacao([]);
//     setSelectedOptionsVisibilidade([]);
//     setSelectedOptionsZt([]);
//     setSelectedOptionsTiposAchados([]);
//     setSelectedOptionsLugol([]);
//     setSelectedOptionsSchiller([]);
//     setSelectedOptionsBiopsia([]);
//     setSelectedOptionsAlteracoes([]);

//     const fieldsToClearWhenIsMedicoLaboratorio: any[] = [
//       'data_ini_colposcopia',
//       'data_fin_colposcopia',
//       'macroscopico',
//       'aval_geral',
//       'visibilidade',
//       'zt',
//       'achados',
//       'lugol',
//       'schiller',
//       'biopsia',
//       'search',
//       'estabelecimento_saude',
//     ];
//     const fieldsToClearWhenIsPadrao: any[] = [
//       'data_ini_colposcopia',
//       'data_fin_colposcopia',
//       'macroscopico',
//       'aval_geral',
//       'visibilidade',
//       'zt',
//       'achados',
//       'lugol',
//       'schiller',
//       'biopsia',
//       'laboratorio_responsavel_laudo',
//       'profissional_responsavel_laudo',
//       'search',
//     ];

//     const fieldsToClearWhenSuperAdmin: any[] = [
//       ...fieldsToClearWhenIsPadrao,
//       'estado',
//       'municipio',
//       'estabelecimento_saude',
//     ];

//     const fieldsToClearWhenIsMunicipal: any[] = [
//       ...fieldsToClearWhenIsPadrao,

//       'estabelecimento_saude',
//     ];

//     const fieldsToClearWhenEstadual = [
//       'municipio',
//       ...fieldsToClearWhenIsMunicipal,
//     ];

//     const permissaoId = getUserToken().permissao_atual?.id;

//     const isPadrao =
//       permissaoId === 1 ||
//       permissaoId === 9 ||
//       permissaoId === 10 ||
//       permissaoId === 7;

//     const isMunicipal = permissaoId === 3;

//     const isLaboratorio =
//       getUserToken().permissao_atual?.contexto === 'LABORATÓRIO';
//     const isMedicoLaboratorio = isPadrao && isLaboratorio;

//     if (isMunicipal) {
//       setSelectedOptionsLaboratorio([]);
//       setSelectedLaboratorio([]);

//       fieldsToClearWhenIsMunicipal.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//         setSelectedOptionsLaboratorio([]);
//       });

//       setEstabelecimentosSelected([]);

//       await setPayload().then((payload) => {
//         try {
//           loadReportsColposcopia({ payload });
//         } catch (error) {}
//       });
//     }

//     if (isPadrao && !isLaboratorio) {
//       setSelectedOptionsLaboratorio([]);
//       setSelectedLaboratorio([]);

//       fieldsToClearWhenIsPadrao.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//       setShowTableData(true);
//       await setPayload().then((payload) => {
//         try {
//           loadReportsColposcopia({ payload });
//         } catch (error) {}
//       });
//     }

//     if (isMedicoLaboratorio) {
//       fieldsToClearWhenIsMedicoLaboratorio.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//       setShowTableData(true);
//       await setPayload().then((payload) => {
//         try {
//           loadReportsColposcopia({ payload });
//         } catch (error) {}
//       });
//     }
//     if (permissao === 'isSuperAdmin') {
//       setSelectedOptionsLaboratorio([]);
//       setSelectedLaboratorio([]);

//       fieldsToClearWhenSuperAdmin.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//       setShowTableData(false);
//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//       setEstadosSelected([]);

//       // const payload = {
//       //   size: watch().size,
//       //   page: watch().page,
//       // };

//       // if (showTableData) {
//       //   loadReportsHpv(payload);
//       // }
//     }

//     if (permissao === 'isEstadual' && !isLaboratorio) {
//       setSelectedOptionsLaboratorio([]);
//       setSelectedLaboratorio([]);

//       fieldsToClearWhenEstadual.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//       setShowTableData(false);
//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);

//       // const payload = {
//       //   size: watch().size,
//       //   page: watch().page,
//       // };

//       // if (showTableData) {
//       //   loadReportsHpv(payload);
//       // }
//     }
//   };

//   return { clearValues };
// };

// export default useClearValues;

// import { useContext } from 'react';
// import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
// import { getUserToken } from '../../../../../lib/auth';

// const useClearValues = ({
//   permissao,
//   setValue,
//   handleSetFilter,
//   watch,
//   loadReportsColposcopia,

//   setEstadosSelected,
//   setMunicipiosSelected,
//   setEstabelecimentosSelected,
//   showTableData,
//   setShowTableData,
//   setTableData,
//   setErrorsFields,
//   setListFilterShow,
//   setDataColetaIni,
//   setDataColetaFinal,
//   setDataResultadoFinal,
//   setDataResultadoIni,
//   setSelectedResultado,
//   setSelectedOptionsOrigem,
//   setSelectedOptionsCarater,
//   setSelectedOptionsEscamosa,
//   setSelectedOptionsGlandular,
//   setPayload,
//   setSelectedOrigem,
//   setSelectedCarater,
//   setSelectedEscamosa,
//   setSelectedGlandular,
//   setSelectedLaboratorio,
//   setSelectedOptionsLaboratorio,
//   setSelectedHasLesao,
// }) => {
//   const handleSetFilterToShow = (newValue: any, inputName: string) => {
//     setListFilterShow((prev) =>
//       prev.map((item) =>
//         item.inputName === inputName ? { ...item, value: newValue } : item
//       )
//     );
//   };

//   const clearValues = async () => {
//     setErrorsFields([]);
//     setTableData([]);

//     setDataColetaIni(null);
//     setDataResultadoFinal(null);
//     setDataColetaFinal(null);
//     setDataResultadoIni(null);
//     setSelectedResultado();
//     setSelectedOptionsOrigem([]);
//     setSelectedOptionsCarater([]);
//     setSelectedOptionsEscamosa([]);
//     setSelectedOptionsGlandular([]);

//     setSelectedOrigem([]);
//     setSelectedCarater([]);
//     setSelectedEscamosa([]);
//     setSelectedGlandular([]);

//     const fieldsToClearWhenIsPadraoLaboratorio: any[] = [
//       'realizou_tratamento',
//       'dt_coleta_inicio',
//       'dt_coleta_fim',
//       'origem',
//       'lesoes_benigno',
//       'lesoes_neoplasico_escamosa',
//       'dt_resultado_inicio',
//       'dt_resultado_fim',
//       'search',
//     ];

//     const fieldsToClearWhenIsPadrao: any[] = [
//       'realizou_tratamento',
//       'dt_coleta_inicio',
//       'dt_coleta_fim',
//       'origem',
//       'lesoes_benigno',
//       'lesoes_neoplasico_escamosa',
//       'dt_resultado_inicio',
//       'dt_resultado_fim',
//       'search',
//       'laboratorio_responsavel_laudo',
//       'profissional_responsavel_resultado',
//     ];

//     const fieldsToClearWhenSuperAdmin: any[] = [
//       ...fieldsToClearWhenIsPadrao,
//       'estado',
//       'municipio',
//       'estabelecimento_saude',
//     ];

//     const fieldsToClearWhenIsMunicipal: any[] = [
//       ...fieldsToClearWhenIsPadrao,
//       'estabelecimento_saude',
//     ];

//     const fieldsToClearWhenEstadual = [
//       'municipio',
//       ...fieldsToClearWhenIsMunicipal,
//     ];

//     const permissaoId = getUserToken().permissao_atual?.id;

//     const isPadrao =
//       permissaoId === 1 ||
//       permissaoId === 9 ||
//       permissaoId === 10 ||
//       permissaoId === 7;

//     const isMunicipal = permissaoId === 3;
//     const isEstadual = permissaoId === 4;

//     const isLaboratorio =
//       getUserToken().permissao_atual?.contexto === 'LABORATÓRIO';

//     if (isPadrao && !isLaboratorio) {
//       setValue('profissionalLaudoId', null);

//       fieldsToClearWhenIsPadrao.forEach((field) => {
//         setSelectedHasLesao([]);
//         setSelectedOrigem([]);
//         setSelectedCarater([]);
//         setSelectedEscamosa([]);
//         setSelectedGlandular([]);
//         setSelectedLaboratorio([]);
//         setSelectedOptionsLaboratorio([]);

//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//       setShowTableData(true);
//       await setPayload().then((payload) => {
//         try {
//           loadReportsColposcopia({ payload });
//         } catch (error) {}
//       });
//     }

//     if (isPadrao && isLaboratorio) {
//       fieldsToClearWhenIsPadraoLaboratorio.forEach((field) => {
//         setSelectedHasLesao([]);
//         setSelectedOrigem([]);
//         setSelectedCarater([]);
//         setSelectedEscamosa([]);
//         setSelectedGlandular([]);

//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//       setShowTableData(true);
//       await setPayload().then((payload) => {
//         try {
//           loadReportsColposcopia({ payload });
//         } catch (error) {}
//       });
//     }

//     if (isMunicipal) {
//       setValue('profissionalLaudoId', null);

//       setSelectedLaboratorio([]);
//       setSelectedOptionsLaboratorio([]);

//       fieldsToClearWhenIsMunicipal.forEach((field) => {
//         setEstabelecimentosSelected([]);
//         setSelectedHasLesao([]);
//         setSelectedOrigem([]);
//         setSelectedCarater([]);
//         setSelectedEscamosa([]);
//         setSelectedGlandular([]);
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//       setShowTableData(true);
//       await setPayload().then((payload) => {
//         try {
//           loadReportsColposcopia({ payload });
//         } catch (error) {}
//       });
//     }

//     if (isEstadual) {
//       setValue('profissionalLaudoId', null);

//       setSelectedLaboratorio([]);
//       setSelectedOptionsLaboratorio([]);

//       fieldsToClearWhenEstadual.forEach((field) => {
//         setMunicipiosSelected([]);
//         setEstabelecimentosSelected([]);
//         setSelectedHasLesao([]);
//         setSelectedOrigem([]);
//         setSelectedCarater([]);
//         setSelectedEscamosa([]);
//         setSelectedGlandular([]);
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//       setShowTableData(false);
//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);

//       // const payload = {
//       //   size: watch().size,
//       //   page: watch().page,
//       // };

//       // if (showTableData) {
//       //   loadReportsHpv(payload);
//       // }
//     }

//     if (permissao === 'isSuperAdmin') {
//       setValue('profissionalLaudoId', null);

//       fieldsToClearWhenSuperAdmin.forEach((field) => {
//         setSelectedLaboratorio([]);
//         setSelectedOptionsLaboratorio([]);

//         setEstadosSelected([]);
//         setMunicipiosSelected([]);
//         setEstabelecimentosSelected([]);
//         setSelectedHasLesao([]);
//         setSelectedOrigem([]);
//         setSelectedCarater([]);
//         setSelectedEscamosa([]);
//         setSelectedGlandular([]);

//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//       setShowTableData(false);
//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//       setEstadosSelected([]);

//       // const payload = {
//       //   size: watch().size,
//       //   page: watch().page,
//       // };

//       // if (showTableData) {
//       //   loadReportsHpv(payload);
//       // }
//     }
//   };

//   return { clearValues };
// };

// export default useClearValues;

// import { useContext } from 'react';
// import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
// import { getUserToken } from '../../../../../lib/auth';

// const useClearValues = ({
//   permissao,
//   setValue,
//   handleSetFilter,
//   watch,
//   loadReportsCitologiaReflexo,

//   setEstadosSelected,
//   setMunicipiosSelected,
//   setEstabelecimentosSelected,
//   showTableData,
//   setShowTableData,
//   setTableData,
//   setErrorsFields,
//   setDataColetaIni,
//   setDataColetaFinal,
//   setSelectedResultado,
//   setSelectedOptions,
//   setPayload,
//   setSelectedLaboratorio,
//   setSelectedOptionsLaboratorio,
//   setListFilterShow,
// }) => {
//   const fieldsToClearWhenIsPadrao: any[] = [
//     'resultado',
//     'profissionalLaudo',
//     'data_ini',
//     'data_fin',
//     'search',
//   ];

//   const fieldsToClearWhenSuperAdmin: any[] = [
//     ...fieldsToClearWhenIsPadrao,
//     'estado',
//     'municipio',
//     'estabelecimento_saude',
//   ];

//   const fieldsToClearWhenIsMunicipal: any[] = [
//     ...fieldsToClearWhenIsPadrao,

//     'estabelecimento_saude',
//   ];

//   const fieldsToClearWhenEstadual = [
//     'municipio',
//     ...fieldsToClearWhenIsMunicipal,
//   ];

//   const userToken = getUserToken();
//   const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
//   const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
//   const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
//   const isAdminLaboratorio =
//     userToken.permissao_atual?.nome === 'Admin Estabelecimento';
//   const isPadraoEstabelecimento =
//     userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

//   const handleSetFilterToShow = (newValue: any, inputName: string) => {
//     setListFilterShow((prev) =>
//       prev.map((item) =>
//         item.inputName === inputName ? { ...item, value: newValue } : item
//       )
//     );
//   };

//   const clearValues = () => {
//     setErrorsFields([]);
//     setTableData([]);
//     setShowTableData(false);
//     setDataColetaIni(null);
//     setDataColetaFinal(null);

//     if (isPadraoEstabelecimento) {
//       fieldsToClearWhenIsPadrao.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });
//     }

//     if (isMunicipal) {
//       fieldsToClearWhenIsMunicipal.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//     }

//     if (isEstadual || isAdminLaboratorio) {
//       fieldsToClearWhenEstadual.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//     }

//     if (isSuperAdmin) {
//       fieldsToClearWhenSuperAdmin.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//       setEstadosSelected([]);
//     }
//   };
//   // const handleSetFilterToShow = (newValue: any, inputName: string) => {
//   //   setListFilterShow((prev) =>
//   //     prev.map((item) =>
//   //       item.inputName === inputName ? { ...item, value: newValue } : item
//   //     )
//   //   );
//   // };

//   // const clearValues = async () => {
//   //   setErrorsFields([]);
//   //   setTableData({ data: [] });

//   //   setDataColetaIni(null);
//   //   setDataColetaFinal(null);
//   //   setDataColetaFinal(null);
//   //   setDataColetaIni(null);
//   //   setSelectedResultado([]);
//   //   setSelectedOptions([]);

//   //   const fieldsToClearWhenIsPadrao: any[] = [
//   //     'resultado',
//   //     'profissionalLaudo',
//   //     'data_ini',
//   //     'data_fin',
//   //     'search',
//   //   ];

//   //   const fieldsToClearWhenIsPadraoLaboratorio: any[] = [
//   //     'resultado',
//   //     'estabelecimento_saude',
//   //     'data_ini',
//   //     'data_fin',
//   //     'search',
//   //   ];

//   //   const fieldsToClearWhenSuperAdmin: any[] = [
//   //     ...fieldsToClearWhenIsPadrao,
//   //     'estado',
//   //     'municipio',
//   //     'estabelecimento_saude',
//   //   ];

//   //   const fieldsToClearWhenIsMunicipal: any[] = [
//   //     ...fieldsToClearWhenIsPadrao,

//   //     'estabelecimento_saude',
//   //   ];

//   //   const fieldsToClearWhenEstadual = [
//   //     'municipio',
//   //     ...fieldsToClearWhenIsMunicipal,
//   //   ];

//   //   const permissaoId = getUserToken().permissao_atual?.id;
//   //   const handleSetFilterToShow = (newValue: any, inputName: string) => {
//   //     setListFilterShow((prev) =>
//   //       prev.map((item) =>
//   //         item.inputName === inputName ? { ...item, value: newValue } : item
//   //       )
//   //     );
//   //   };
//   //   const isPadrao =
//   //     permissaoId === 1 ||
//   //     permissaoId === 9 ||
//   //     permissaoId === 10 ||
//   //     permissaoId === 7;

//   //   const isLaboratorio =
//   //     getUserToken().permissao_atual?.contexto === 'LABORATÓRIO';

//   //   const isMunicipal = permissaoId === 3;
//   //   const isEstadual = permissaoId === 4;

//   //   if (isMunicipal) {
//   //     fieldsToClearWhenIsMunicipal.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //     });
//   //     setValue('profissionalResultadoId', null);

//   //     setEstabelecimentosSelected([]);
//   //     setSelectedLaboratorio([]);
//   //     setSelectedOptionsLaboratorio([]);
//   //     await setPayload().then((payload) => {
//   //       try {
//   //         loadReportsCitologiaReflexo({
//   //           estado: payload.estado,
//   //           municipioId: payload.municipioId,
//   //           tipo: 'Reflexo',
//   //           size: payload.size,
//   //           page: payload.page,
//   //         });
//   //       } catch (error) {}
//   //     });
//   //   }

//   //   if (isPadrao && !isLaboratorio) {
//   //     setValue('profissionalResultadoId', null);

//   //     fieldsToClearWhenIsPadrao.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //     });
//   //     setShowTableData(true);
//   //     await setPayload().then((payload) => {
//   //       try {
//   //         loadReportsCitologiaReflexo({
//   //           estado: payload.estado,
//   //           tipo: 'Reflexo',

//   //           municipioId: payload.municipioId,
//   //           estabelecimentoSaudeId: payload.estabelecimentoSaudeId,
//   //           // laboratorioLaudoId: payload.laboratorioLaudoId,
//   //           size: payload.size,
//   //           page: payload.page,
//   //         });
//   //       } catch (error) {}
//   //     });
//   //   }

//   //   if (isPadrao && isLaboratorio) {
//   //     fieldsToClearWhenIsPadraoLaboratorio.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //       setMunicipiosSelected([]);
//   //       setEstabelecimentosSelected([]);
//   //     });
//   //     setShowTableData(true);
//   //     await setPayload().then((payload) => {
//   //       try {
//   //         loadReportsCitologiaReflexo({
//   //           estados: payload.estado,
//   //           tipo: 'Reflexo',
//   //           profissionalResultadoId: payload.profissionalResultadoId,
//   //           laboratorioLaudoId: payload.laboratorioLaudoId,
//   //           size: payload.size,
//   //           page: payload.page,
//   //         });
//   //       } catch (error) {}
//   //     });
//   //   }

//   //   if (permissao === 'isSuperAdmin') {
//   //     fieldsToClearWhenSuperAdmin.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //     });
//   //     setValue('profissionalResultadoId', null);

//   //     setSelectedLaboratorio([]);
//   //     setSelectedOptionsLaboratorio([]);
//   //     setShowTableData(false);
//   //     setEstabelecimentosSelected([]);
//   //     setMunicipiosSelected([]);
//   //     setEstadosSelected([]);

//   //     // const payload = {
//   //     //   size: watch().size,
//   //     //   page: watch().page,
//   //     // };

//   //     // if (showTableData) {
//   //     //   loadReportsHpv(payload);
//   //     // }
//   //   }

//   //   if (isEstadual) {
//   //     fieldsToClearWhenEstadual.forEach((field) => {
//   //       setValue(field, '');
//   //       handleSetFilter('', field);
//   //       handleSetFilterToShow('', field);
//   //     });
//   //     setValue('profissionalResultadoId', null);

//   //     setShowTableData(false);
//   //     setSelectedLaboratorio([]);
//   //     setSelectedOptionsLaboratorio([]);
//   //     setEstabelecimentosSelected([]);
//   //     setMunicipiosSelected([]);

//   //     // const payload = {
//   //     //   size: watch().size,
//   //     //   page: watch().page,
//   //     // };

//   //     // if (showTableData) {
//   //     //   loadReportsHpv(payload);
//   //     // }
//   //   }
//   // };

//   return { clearValues };
// };

// export default useClearValues;

// import { useContext } from 'react';
// import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
// import { getUserToken } from '../../../../../lib/auth';

// const useClearValues = ({
//   permissao,
//   setValue,
//   handleSetFilter,
//   watch,
//   loadReportsTrackingHpv,
//   setTableData,
//   setUsePayloadFromCharts,
//   showTableData,
//   setShowTableData,
//   clearErrors,
//   setEstadosSelected,
//   setMunicipiosSelected,
//   setEstabelecimentosSelected,
//   setAcsSelected,
//   setListFilterToShow,
//   listFilter,
//   getReportsTracking,
// }) => {
//   const fieldsToClearWhenIsPadrao: any[] = [
//     'acs',
//     'idade_inicio',
//     'idade_final',
//     'faixa',
//     'prioridade',
//     'meio_rastreio',
//     'search',
//     'has_teste',
//     'periodo',
//   ];

//   const fieldsToClearWhenIsMunicipal = [
//     'estabelecimento_saude',
//     ...fieldsToClearWhenIsPadrao,
//   ];

//   const fieldsToClearWhenEstadual = [
//     'municipio',
//     ...fieldsToClearWhenIsMunicipal,
//   ];

//   const fieldsToClearWhenSuperAdmin = ['estado', ...fieldsToClearWhenEstadual];

//   const { currentEstablishment } = useContext(EstablishmentContext);

//   const clearValues = ({
//     setPeriod,
//     setValueInputPeriod,
//     setValuePeriodoModal,
//     setValueInputQuadrimestreSemestre,
//     setYear,
//     setSelectedInicialAge,
//     setSelectedFinalAge,
//     setSelectedFaixa,
//     setSelectedPrioridade,
//     setSelectedMeio,
//     setSelectedRealizacaoTeste,
//   }) => {
//     setPeriod(null);
//     setValueInputPeriod(null);
//     setValuePeriodoModal(null);
//     setValueInputQuadrimestreSemestre(null);
//     setYear(null);

//     setSelectedInicialAge([]);
//     setSelectedFinalAge([]);
//     setSelectedFaixa([]);
//     setSelectedPrioridade([]);
//     setSelectedMeio([]);
//     setSelectedRealizacaoTeste([]);

//     clearErrors([]);
//     setTableData([]);
//     setShowTableData(false);

//     const handleSetFilterToShow = (newValue: any, inputName: string) => {
//       setListFilterToShow((prev) =>
//         prev.map((item) =>
//           item.inputName === inputName ? { ...item, value: newValue } : item
//         )
//       );
//     };
//     const userToken = getUserToken();
//     const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
//     const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
//     const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
//     const isAdminLaboratorio =
//       userToken.permissao_atual?.nome === 'Admin Estabelecimento';
//     const isPadraoEstabelecimento =
//       userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

//     const {
//       estabelecimentoId: estabelecimentoIdUser,
//       estabelecimento_saude: {
//         nome_fantasia: estabelecimentoNameUser,
//         endereco: {
//           municipio: {
//             id: municipioUserId,
//             nome: municipioUserName,
//             estadoSigla: estadoUserSigla,
//           },
//         },
//       },
//     } = userToken;
//     if (isPadraoEstabelecimento) {
//       fieldsToClearWhenIsPadrao.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setShowTableData(true);

//       getReportsTracking({
//         estados: estadoUserSigla,
//         municipios: municipioUserId,
//         estabelecimentoSaudeId: estabelecimentoIdUser,
//         page: 1,
//         size: 25,
//       });
//     }

//     if (isMunicipal) {
//       fieldsToClearWhenIsMunicipal.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);

//       setShowTableData(true);

//       getReportsTracking({
//         estados: estadoUserSigla,
//         municipios: municipioUserId,
//         page: 1,
//         size: 25,
//       });
//     }

//     if (isEstadual || isAdminLaboratorio) {
//       fieldsToClearWhenEstadual.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//     }

//     if (isSuperAdmin) {
//       fieldsToClearWhenSuperAdmin.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//       setEstadosSelected([]);
//     }
//   };

//   return { clearValues };
// };

// export default useClearValues;

// // const clearValues = () => {
// //   setShowTableData(false);
// //   clearErrors();
// //   setUsePayloadFromCharts(false);
// //   setTableData([]);

// //   if (permissao === 'isPadrao') {
// //     fieldsToClearWhenIsPadrao.forEach((field) => {
// //       setValue(field, null);
// //       handleSetFilter('', field);
// //     });

// //     const payload = {
// //       estado: currentEstablishment?.endereco.municipio.estado.nome,
// //       municipio: currentEstablishment?.endereco.municipio.nome,
// //       estabelecimentoId: currentEstablishment?.id,
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }

// //   if (permissao === 'isMunicipal') {
// //     fieldsToClearWhenIsMunicipal.forEach((field) => {
// //       setValue(field, '');
// //       handleSetFilter('', field);
// //     });

// //     setEstabelecimentosSelected([]);

// //     const payload = {
// //       estado: currentEstablishment?.endereco.municipio.estado.nome,
// //       municipio: currentEstablishment?.endereco.municipio.nome,
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }

// //   if (permissao === 'isEstadual') {
// //     fieldsToClearWhenEstadual.forEach((field) => {
// //       setValue(field, '');
// //       handleSetFilter('', field);
// //     });

// //     setEstabelecimentosSelected([]);
// //     setMunicipiosSelected([]);

// //     const payload = {
// //       estado: currentEstablishment?.endereco.municipio.estado.nome,
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }

// //   if (permissao === 'isSuperAdmin') {
// //     fieldsToClearWhenSuperAdmin.forEach((field) => {
// //       setValue(field, '');
// //       handleSetFilter('', field);
// //     });

// //     setEstadosSelected([]);
// //     setEstabelecimentosSelected([]);
// //     setMunicipiosSelected([]);

// //     const payload = {
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }
// // };

import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { getUserToken } from '../../../../../lib/auth';
import useClearReports from '../../../../../utils/hooks/useClearReports';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsColposcopia,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  initialStateListFilter,
  setListFilterShow,

  // setSelectedHasLesao,
  // setSelectedOptionsOrigem,
  // setSelectedOrigem,
  // setSelectedOptionsCarater,
  // setSelectedCarater,
  // setSelectedOptionsEscamosa,
  // setSelectedEscamosa,
  // setSelectedOptionsGlandular,
  // setSelectedGlandular,
  // setSelectedOptionsLaboratorio,
  // setSelectedLaboratorio,

  setDtColposcopiaIni,
  setDtColposcopiaFinal,

  setSelectedOptionsAlteracoes,
  setSelectedOptionsAvaliacao,
  setSelectedOptionsVisibilidade,
  setSelectedOptionsZt,
  setSelectedOptionsTiposAchados,
  setSelectedOptionsLugol,
  setSelectedOptionsSchiller,
  setSelectedOptionsBiopsia,
  setSelectedOptionsLaboratorio,
  setSelectedLaboratorio,
}) => {
  const { clearReport } = useClearReports({ setListFilterShow });
  const userToken = getUserToken();

  const estabelecimentoIdUser = userToken?.estabelecimentoId ?? null;
  const estabelecimento_saude = userToken?.estabelecimento_saude;
  const endereco = estabelecimento_saude?.endereco;
  const municipio = endereco?.municipio;

  const estabelecimentoNameUser = estabelecimento_saude?.nome_fantasia ?? null;
  const municipioUserId = municipio?.id ?? null;
  const municipioUserName = municipio?.nome ?? null;
  const estadoUserSigla = municipio?.estadoSigla ?? null;

  const objStatesToClear = {
    setSelectedOptionsAlteracoes,
    setSelectedOptionsAvaliacao,
    setSelectedOptionsVisibilidade,
    setSelectedOptionsZt,
    setSelectedOptionsTiposAchados,
    setSelectedOptionsLugol,
    setSelectedOptionsSchiller,
    setSelectedOptionsBiopsia,
    setSelectedOptionsLaboratorio,
    setSelectedLaboratorio,
  };

  const fieldNamesToClear = initialStateListFilter.map((fieldObj) => {
    return fieldObj.inputName;
  });

  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
  const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
  const isAdminLaboratorio =
    userToken.permissao_atual?.nome === 'Admin Estabelecimento';
  const isMedicoLaboratorio = userToken.permissao_atual?.id === 7;
  const isPadraoUnidadeSaude =
    userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

  const clearValues = () => {
    setErrorsFields([]);
    setTableData([]);
    setDtColposcopiaIni('');
    setDtColposcopiaFinal('');

    const clearWhenSuperAdmin = () => {
      setShowTableData(false);

      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
          setEstadosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear,
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });
    };

    const clearWhenEstadual = () => {
      setShowTableData(false);
      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(1),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });
    };

    const clearWhenAdminLaboratorio = () => {
      delete objStatesToClear.setSelectedOptionsLaboratorio;
      delete objStatesToClear.setSelectedLaboratorio;
      setShowTableData(false);
      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear
          .slice(1)
          .filter((field) => field !== 'laboratorio_responsavel_laudo'),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });

      handleSetFilter(
        `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
        'laboratorio_responsavel_laudo'
      );
    };

    const clearWhenMedicoLaboratorio = () => {
      delete objStatesToClear.setSelectedOptionsLaboratorio;
      delete objStatesToClear.setSelectedLaboratorio;
      setShowTableData(false);
      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear
          .slice(1)
          .filter((field) => field !== 'laboratorio_responsavel_laudo')
          .filter((field) => field !== 'profissional_responsavel_laudo'),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });

      handleSetFilter(
        `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
        'laboratorio_responsavel_laudo'
      );
    };

    const clearWhenMunicipal = () => {
      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(2),
        handleSetFilter,
        handleSetFilterToShow,
      });

      const payload = {
        estados: estadoUserSigla,
        municipios: municipioUserId,
        page: 1,
        size: 25,
      };

      loadReportsColposcopia({ payload });
    };

    const clearWhenPadraoUnidadeSaude = () => {
      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(3),
        handleSetFilter,
        handleSetFilterToShow,
      });

      const payload = {
        estados: estadoUserSigla,
        municipios: municipioUserId,
        estabelecimentoSaudeId: estabelecimentoIdUser,
        page: 1,
        size: 25,
      };

      loadReportsColposcopia({ payload });
    };

    if (isSuperAdmin) {
      clearWhenSuperAdmin();
    }

    if (isEstadual) {
      clearWhenEstadual();
    }

    if (isAdminLaboratorio) {
      clearWhenAdminLaboratorio();
    }

    if (isMedicoLaboratorio) {
      clearWhenMedicoLaboratorio();
    }

    if (isPadraoUnidadeSaude) {
      clearWhenPadraoUnidadeSaude();
    }

    if (isMunicipal) {
      clearWhenMunicipal();
    }
  };

  return { clearValues };
};

export default useClearValues;
