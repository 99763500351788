import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { getUserToken } from '../../../../../lib/auth';
import useClearReports from '../../../../../utils/hooks/useClearReports';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsHpv,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  setListFilterToShow,
  setDataColetaIni,
  setDataColetaFinal,
  setDataResultadoFinal,
  setDataResultadoIni,
  setSelectedOptinsProfissionalResponsavel,
  setSelectedOptionsLaboratorio,
  setArrayEquipes,
  initialStateListFilter,
  setListFilterShow,
}) => {
  const { clearReport } = useClearReports({ setListFilterShow });
  const userToken = getUserToken();

  const estabelecimentoIdUser = userToken?.estabelecimentoId ?? null;
  const estabelecimento_saude = userToken?.estabelecimento_saude;
  const endereco = estabelecimento_saude?.endereco;
  const municipio = endereco?.municipio;

  const estabelecimentoNameUser = estabelecimento_saude?.nome_fantasia ?? null;
  const municipioUserId = municipio?.id ?? null;
  const municipioUserName = municipio?.nome ?? null;
  const estadoUserSigla = municipio?.estadoSigla ?? null;

  const objStatesToClear = {
    setSelectedOptinsProfissionalResponsavel,
    setSelectedOptionsLaboratorio,
    setArrayEquipes,
  };

  const fieldNamesToClear = initialStateListFilter.map((fieldObj) => {
    return fieldObj.inputName;
  });

  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterToShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
  const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
  const isAdminLaboratorio =
    userToken.permissao_atual?.nome === 'Admin Estabelecimento';
  const isPadraoUnidadeSaude =
    userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

  const clearValues = () => {
    setErrorsFields([]);
    setTableData([]);
    setDataColetaIni(null);
    setDataColetaFinal(null);
    setDataResultadoFinal(null);
    setDataResultadoIni(null);
    setValue('resultado', []);

    const clearWhenSuperAdmin = () => {
      setShowTableData(false);

      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
          setEstadosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear,
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });
    };

    const clearWhenEstadual = () => {
      setShowTableData(false);
      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(1),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });
    };

    const clearWhenAdminLaboratorio = () => {
      delete objStatesToClear.setSelectedOptionsLaboratorio;
      setShowTableData(false);
      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear
          .slice(1)
          .filter((field) => field !== 'estabelecimentoLaudoId'),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });

      handleSetFilter(
        `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
        'estabelecimentoLaudoId'
      );
    };

    const clearWhenMunicipal = () => {
      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(2),
        handleSetFilter,
        handleSetFilterToShow,
      });

      loadReportsHpv({
        estados: estadoUserSigla,
        municipios: municipioUserId,
        page: 1,
        size: 25,
      });
    };

    const clearWhenPadraoUnidadeSaude = () => {
      clearReport({
        watch,
        objStates: {
          ...objStatesToClear,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(3),
        handleSetFilter,
        handleSetFilterToShow,
      });

      loadReportsHpv({
        estados: estadoUserSigla,
        municipios: municipioUserId,
        estabelecimentoSaudeId: estabelecimentoIdUser,
        page: 1,
        size: 25,
      });
    };

    if (isSuperAdmin) {
      clearWhenSuperAdmin();
    }

    if (isEstadual) {
      clearWhenEstadual();
    }

    if (isAdminLaboratorio) {
      clearWhenAdminLaboratorio();
    }

    if (isPadraoUnidadeSaude) {
      clearWhenPadraoUnidadeSaude();
    }

    if (isMunicipal) {
      clearWhenMunicipal();
    }
  };

  return { clearValues };
};

export default useClearValues;
