import axios from 'axios';
import qs from 'qs';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import api from '../api';

interface AuthStorage {
  access_token: string | null;
  refresh_token: string | null;
  expires_in: number | null;
  logged: boolean;
}

export interface UserToken {
  id: number;
  email: string;
  estabelecimentoId: string;
  endereco: {
    estado: string;
    municipio_code: string;
    municipio_nome: string;
    municipio_id: string;
  };
  estabelecimento_saude: number | any;
  perfis: {
    estabelecimento_saude: {
      id: number;
      cnes: string;
      nome_fantasia: string;
      inativo: boolean;
      razao_social: string;
    };
  }[];
  permissao_atual: {
    id: number;
    nome: string;
    contexto: string;
  };
  permissoes: string[];
  pessoa: {
    nome: string;
    cns: string;
    id: number;
    apelido: string;
    cpf: string;
  };
}

const STORAGE = '@jornada-ccu/auth';
const VERSION = '@jornada-ccu/version';
export const BASIC_AUTH = 'am9yZGFuYS1jY3U6czNjcmV0';

const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'web-sync': 'valid',
  },
});

// async function login(username: string, password: string, setError) {
//   return await authApi
//     .post(
//       '/oauth/token',
//       qs.stringify({
//         username,
//         password,
//         scope: 'client',
//         grant_type: 'password',
//       }),
//       {
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//           Authorization: `Basic ${BASIC_AUTH}`,
//         },
//       }
//     )
//     .then((res) => {
//       setError('');
//       updateStorage({ ...res.data, logged: true });
//       return res;
//     })
//     .catch((e: any) => {
//       const message = e.response.data.message;
//       if (message === 'Senha incorreta') {
//         setError('Login ou senha inválidos');
//       }

//       if (e.response.status === 503) {
//         setError('Login ou senha inválidos');
//       }
//       return e.response;
//     });
// }

async function login(username: string, password: string, setError) {
  try {
    const response = await authApi.post(
      '/oauth/token',
      qs.stringify({
        username,
        password,
        scope: 'client',
        grant_type: 'password',
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${BASIC_AUTH}`,
        },
      }
    );
    setError('');
    updateStorage({ ...response.data, logged: true });
    return response;
  } catch (error: any) {
    console.error(error?.response);
    const message = error?.response.data.message;

    if (message === 'Senha incorreta' || error.response.status === 503) {
      setError('Login ou senha inválidos');
    }

    return error.response;
  }
}

async function refreshLogin(
  username: string,
  password: string,
  establishment: number
) {
  //Refresh token no login
  return await authApi
    .post(
      '/oauth/token/establishment',
      qs.stringify({
        username,
        password,
        establishment,
        scope: 'client',
        grant_type: 'password',
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${BASIC_AUTH}`,
        },
      }
    )
    .then((res) => {
      updateStorage({ ...res.data, logged: true });
      return res;
    })
    .catch((e: any) => {
      return e.response.data;
    });
}

async function changePassword({ senhaAtual, novaSenha }) {
  return await api
    .post('/usuarios/changePassword', qs.stringify({ senhaAtual, novaSenha }))
    .then((res) =>
      res.status === 201 ? { success: true } : { success: false }
    )
    .catch((e: any) => e.response.data);
}

function logout() {
  window.location.assign('/');
  localStorage.removeItem(STORAGE);
}

function verifyWebVersion(webVersion: string) {
  const currentVersion = localStorage.getItem(VERSION);
  if (!currentVersion) {
    localStorage.setItem(VERSION, webVersion);
  } else if (currentVersion != webVersion) {
    localStorage.removeItem(VERSION);
    window.location.reload();
  } else {
  }
}

function getAccessToken() {
  const storage = getAuthStorage();
  return storage ? JSON.parse(storage).access_token : null;
}

function getRefreshToken() {
  const storage = getAuthStorage();
  return storage ? JSON.parse(storage).refresh_token : null;
}

function getUserToken() {
  const storage = getAuthStorage();
  const token = storage
    ? (jwtDecode<JwtPayload>(storage) as any).user
    : ({} as any);

  return token as UserToken;
}

function checkAccessToken() {
  const token =
    getAccessToken() !== null ? jwtDecode<JwtPayload>(getAccessToken()) : {};

  return token && token.exp! >= new Date().getTime() / 1000;
}

function updateStorage(data: AuthStorage) {
  localStorage.setItem(STORAGE, JSON.stringify(data));
}

function getAuthStorage() {
  return localStorage.getItem(STORAGE);
}

async function refreshAccessToken() {
  const refreshToken = getRefreshToken();

  try {
    const res = await authApi.post(
      '/oauth/token/',
      qs.stringify({
        refresh_token: refreshToken,
        scope: 'client',
        grant_type: 'refresh_token',
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${BASIC_AUTH}`,
        },
      }
    );
    updateStorage(res.data);
  } catch (e) {
    logout();
    return e;
  }
}

export {
  login,
  logout,
  checkAccessToken,
  getAccessToken,
  getRefreshToken,
  getUserToken,
  refreshAccessToken,
  getAuthStorage,
  refreshLogin,
  changePassword,
  verifyWebVersion,
};
