/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
//src/pages/RegisterPatient/RegisterPatient.tsx
import { useCallback, useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container } from '@mui/material';

import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import ReturnButton from '../../components/ReturnButton';
import { SideMenu } from '../../components/SideMenu';
import { returnCpfValidation } from '../../utils/validateCPF';
import { getUserToken } from '../../lib/auth';
import { EstablishmentContext } from '../../context/EstablishmentContext';
import { scrollToErrors } from '../../utils/scrollToErrors';
import { Inputs } from './types';
import { schemaVariation } from './schema';
import ModalCancelRegister from './Components/ModalCancelRegister';
import ModalRemoveContato from './Components/ModalRemoveContato';
import PageTitlePatients from './Components/PageTitle';
import FormPatients from './FormPatients';
import { toast } from 'react-toastify';
import Template from '../../components/Template';

export default function RegisterPatient() {
  let navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { currentEstablishment } = useContext(EstablishmentContext);

  const canUpdateVinculo = !!getUserToken()?.permissoes?.find(
    (permission) => permission === 'UPDATE_VINCULO'
  );

  const [indexRemoveContato, setIndexRemoveContato] = useState();
  const [estabelecimentoVinculo, setEstabelecimentoVinculo] = useState<any>();
  const [ufValue, setUfValue] = useState<any>();
  const [bindData, setBindData] = useState<any>();
  const [disableInputsVinculo, setDisableInputsVinculo] = useState(false);
  const [haventVinculo, sethaventVinculo] = useState(false);
  const [openDialogRemoveContato, setOpenDialogRemoveContato] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [equipesOptions, setEquipesOptions] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [profissionalNotHasEquipe, setProfissionalNotHasEquipe] =
    useState<any>(true);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
    watch,
    clearErrors,
    setError,
  } = useForm<Inputs>({
    resolver: yupResolver(
      schemaVariation(
        !haventVinculo,
        canUpdateVinculo,
        disableInputsVinculo,
        profissionalNotHasEquipe
      )
    ),
    defaultValues: {
      dt_nascimento: null,
      endereco: undefined,
    },
  });

  const {
    fields: contatos,
    append,
    remove,
  } = useFieldArray({ name: 'contatos', control });

  const addContato = useCallback(() => {
    append({
      forma_contato: 'EMAIL',
      descricao: '',
      isWhatsapp: false,
      observacao: '',
      inativo: false,
      is_favorito: false,
      foneZap: '',
    });
  }, []);

  useEffect(() => {
    if (currentEstablishment && !location.state) {
      setValue('estabelecimento_saude', currentEstablishment);
    }
  }, [currentEstablishment]);

  const onSubmit = async (data: Inputs) => {
    setLoading(true);
    data.cpf = data.cpf.replace(/[.-]/g, '');
    const payloadPostPessoas = {
      nome: data.nome,
      apelido: data.apelido,
      cpf: data.cpf,
      dt_nascimento: data.dt_nascimento,
      cns: data.cns,
      raca: data.raca,
      nacionalidade: data.nacionalidade,
      nome_mae: data.nome_mae,
      endereco: data.endereco,
      contatos: data.contatos,
      contato_preferencial: data.contato_preferencial,
      isPaciente: true,
      estabelecimento_saude: data.estabelecimento_saude,
      sexo: 'Feminino',
    };
    const payloadPostVinculo = {
      profissional_id: data.vinculo.profissionalId,
      equipe_ine: data.vinculo.equipeIne,
      equipe_nome:
        data?.vinculo?.equipeNome?.equipe?.nome || data?.vinculo?.equipeNome,
      estabelecimento_id: estabelecimentoVinculo?.id || estabelecimentoVinculo,
      area: data.vinculo.area,
      micro_area: data.vinculo.micro_area,
    };

    if (location?.state?.id) {
      data?.dt_nascimento?.toISOString();
      try {
        const responseUpdate = await api.put('/pessoas/' + id, {
          ...payloadPostPessoas,
        });
        const idPaciente = responseUpdate.data.id;

        if (responseUpdate.status === 200 && canUpdateVinculo) {
          const responseVinculo = await api.post(
            `/profissionais/acs/vincular/${idPaciente}`,
            { ...payloadPostVinculo, remove: haventVinculo }
          );

          if (responseVinculo.status === 201) {
            navigate(`/pacientes/${idPaciente}`);
          }
        } else if (haventVinculo && bindData.id && canUpdateVinculo) {
          try {
            const responseDeleteVinculo = await api.delete(
              `/vinculos/${bindData.id}`
            );

            if (responseDeleteVinculo.status === 200) {
              navigate(`/pacientes/${idPaciente}`);
            }
          } catch (error: any) {
            console.error(error);
            toast.error(error?.error.message);
            navigate(`/pacientes/${idPaciente}`);
          }
        } else {
          navigate(`/pacientes/${idPaciente}`);
        }
      } catch (err: any) {
        toast.error(err?.error.message);
      }
    } else {
      try {
        const response = await api.post('/pessoas', { ...payloadPostPessoas });
        if (
          response.status === 201 &&
          canUpdateVinculo &&
          estabelecimentoVinculo
        ) {
          const idPaciente = response.data.id;
          const responseVinculo = await api.post(
            `/profissionais/acs/vincular/${idPaciente}`,
            { ...payloadPostVinculo, remove: haventVinculo }
          );

          if (responseVinculo.status === 201) {
            navigate(`/pacientes/${idPaciente}`);
          }
        } else {
          navigate(`/pacientes/${response.data.id}`);
        }
      } catch (error: any) {
        toast.error(error?.error.message, { position: 'bottom-right' });
      }
    }
  };

  const CPF = watch('cpf');

  const validateCpfWhenUserTypeElevenNumbers = () => {
    const cpfIsValid = returnCpfValidation(getValues);

    const CpfWithoutChar = CPF.replace(/[-.]/g, '');

    const setOrClearErrorsOnCpfInput = () => {
      cpfIsValid === false
        ? setError('cpf', { type: 'manual', message: 'CPF inválido' })
        : clearErrors('cpf');
    };

    if (CpfWithoutChar.length === 11) setOrClearErrorsOnCpfInput();
  };

  const setVinculoData = async () => {
    const { data } = await api.get('/vinculos/pessoa/' + id);
    if (!data.id) {
      sethaventVinculo(false);
      setDisableInputsVinculo(false);
      // @ts-ignore
      setValue('vinculo.active', false);
      return;
    }
    const vinculoBinder = {
      id: data.id,
      cns: data.profissionalEquipe?.profissional?.pessoa?.cns,
      cbo: data.profissionalEquipe?.profissional?.cbo,
      ine: data.profissionalEquipe?.equipe?.ine,
      area: data.profissionalEquipe?.area,
      cnes: data.estabelecimentoSaude?.cnes,
      nomeProfissional: data.profissionalEquipe?.profissional?.pessoa?.nome,
      idProfissional: data.profissionalEquipe?.profissional?.id,
      cargoProfissional: data.profissionalEquipe?.profissional?.cargo,
      nomeEquipe: data.profissionalEquipe?.equipe?.nome,
      microarea: data.profissionalEquipe?.micro_area,
      nomeEstabelecimento: data.estabelecimentoSaude?.nome_fantasia,
      uf: data.estabelecimentoSaude?.endereco?.municipio?.estadoSigla,
      municipio: data.estabelecimentoSaude?.endereco?.municipio?.nome,
      estabelecimentoSaude: data.estabelecimentoSaude,
    };
    setEstabelecimentoVinculo(data?.estabelecimentoSaude?.id);
    setEquipesOptions([data?.profissionalEquipe]);
    setBindData(vinculoBinder);
    setTimeout(() => {
      setEstabelecimentoVinculo(data?.estabelecimentoSaude?.id);
    }, 100);
  };

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  useEffect(() => {
    if (loading)
      setTimeout(() => {
        setLoading(false);
      }, 2500);
  }, [loading]);
  useEffect(() => {
    if (location.state?.id) {
      const state = location.state as Inputs;
      const formatedDate = new Date(state.dt_nascimento + 'T00:00:00');
      setValue('cns', state.cns, { shouldValidate: true });
      setValue('nome', state.nome, { shouldValidate: true });
      setValue('apelido', state.apelido, { shouldValidate: true });
      setValue('nome_mae', state.nome_mae ?? '', { shouldValidate: true });
      setValue('cpf', state.cpf ? state.cpf : '', { shouldValidate: true });
      setValue('dt_nascimento', formatedDate, { shouldValidate: true });
      setValue('nacionalidade', state.nacionalidade, { shouldValidate: true });
      setValue('raca', state.raca, { shouldValidate: true });
      setValue('escolaridade', state.escolaridade, { shouldValidate: true });
      setValue('endereco.municipio', state.endereco?.municipio, {
        shouldValidate: true,
      });

      setValue('endereco', state.endereco, { shouldValidate: true });
      setValue('endereco.municipio.estado', state.endereco?.municipio?.estado, {
        shouldValidate: true,
      });
      setValue('contatos', state.contatos, { shouldValidate: true });
      setValue('contato_preferencial', state.contato_preferencial, {
        shouldValidate: true,
      });
      setValue('orientacao', state.orientacao, { shouldValidate: true });
      setValue('identidade_genero', state.identidade_genero, {
        shouldValidate: true,
      });
      setValue('endereco', state.endereco);
      setValue('estabelecimento_saude', state.estabelecimento_saude);
      setUfValue({
        sigla: state.estabelecimento_saude?.endereco?.municipio?.estadoSigla,
      });

      setValue('vinculo.profissionalCns', bindData?.cns);
      setValue('vinculo.profissionalNome', bindData?.nomeProfissional);
      setValue('vinculo.profissionalId', bindData?.idProfissional);
      setValue('vinculo.cbo', bindData?.cbo);
      setValue('vinculo.tipo', bindData?.cargoProfissional);
      setValue('vinculo.equipeNome', bindData?.nomeEquipe);
      setValue('vinculo.equipeIne', bindData?.ine);
      setValue('vinculo.area', bindData?.area);
      setValue('vinculo.micro_area', bindData?.microarea);
      setValue('vinculo.uf', bindData?.uf);
      setValue('vinculo.municipio', bindData?.municipio);
      // setValue('vinculo.estabelecimento_saude', bindData);
      setValue('vinculo.estabelecimentoString', bindData?.nomeEstabelecimento);
      setValue('vinculo.estabelecimento_saude', bindData?.estabelecimentoSaude);
      setEstabelecimentoVinculo(state.estabelecimento_saude?.id);
    }
  }, [location.state, bindData]);

  useEffect(() => {
    if (CPF) {
      validateCpfWhenUserTypeElevenNumbers();
    } else {
      clearErrors('cpf');
    }
  }, [CPF]);

  useEffect(() => {
    location.state?.id && setVinculoData();
  }, [id]);

  useEffect(() => {
    if (haventVinculo === true) {
      clearErrors('vinculo.micro_area');
      clearErrors('vinculo.profissionalCns');
    }
  }, [haventVinculo]);

  useEffect(() => {
    scrollToErrors(errors);
  }, [errors]);

  return (
    <SideMenu>
      <Container style={{ marginTop: '64px' }}>
        <Box sx={{ width: '100%' }}>
          <ModalCancelRegister
            id={id}
            setOpenDialogCancel={setOpenDialogCancel}
            openDialogCancel={openDialogCancel}
          />

          <ModalRemoveContato
            openDialogRemoveContato={openDialogRemoveContato}
            setOpenDialogRemoveContato={setOpenDialogRemoveContato}
            remove={remove}
            indexRemoveContato={indexRemoveContato}
          />
          <ReturnButton backPage={location?.state?.url} />

          <PageTitlePatients id={id} />

          <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
            <i>* Resposta obrigatória</i>
          </p>
          <FormPatients
            setProfissionalNotHasEquipe={setProfissionalNotHasEquipe}
            setOpenDialogCancel={setOpenDialogCancel}
            setOpenDialogRemoveContato={setOpenDialogRemoveContato}
            setIndexRemoveContato={setIndexRemoveContato}
            contatos={contatos}
            addContato={addContato}
            isLoading={loading}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            errors={errors}
            setValue={setValue}
            setUfValue={setUfValue}
            ufValue={ufValue}
            watch={watch}
            canUpdateVinculo={canUpdateVinculo}
            clearErrors={clearErrors}
            id={id}
            setEstabelecimentoVinculo={setEstabelecimentoVinculo}
            setDisableInputsVinculo={setDisableInputsVinculo}
            disableInputsVinculo={disableInputsVinculo}
            haventVinculo={haventVinculo}
            sethaventVinculo={sethaventVinculo}
          />
        </Box>
      </Container>
    </SideMenu>
  );
}
