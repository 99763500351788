import { LocalizationProvider, StaticDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CancelScheduleModal } from '../modals/cancelScheduleModal';
import { ModalScheduling } from '../modals/modalScheduling';
import { ScheduleListItem } from '../scheduleListItem';
import { useStyles } from './styles';
import EmptySchedule from '../.../../../../../assets/imgs/empty_schedule.png';
import { Link } from 'react-router-dom';
import { getUsersAgenda } from '../../../../lib/profissionais';
import { getProfessionalSchedule } from '../../../../lib/professionalSchedule';
import moment from 'moment';

import api from '../../../../api';
import RealizeSchedule from '../modals/realizeSchedule';
import { EstablishmentContext } from '../../../../context/EstablishmentContext';
import { toast } from 'react-toastify';
import { PerfilContext } from '../../../../context/PerfilContext';

interface PatientData {
  time: string;
  appointmentStatus: string;
  patient: string;
  exam: string;
  scheduleId: string | null;
  scheduleData: any;
}

export const ProfessionalsSchedule = () => {
  const { control } = useForm();
  const [value, setValue] = useState(null);
  const styles = useStyles();
  const [emptySchedule, setEmptySchedule] = useState(true);
  const [realizeSchedule, setRealizeSchedule] = useState(false);
  const [cancelSchedule, setCancelSchedule] = useState(false);
  const [notScheduled, setNotScheduled] = useState(false);
  const [patientSelected, setPatientSelected] = useState('');
  const [patientData, setPatientData] = useState<PatientData>({
    time: '',
    appointmentStatus: '',
    patient: '',
    exam: '',
    scheduleId: null,
    scheduleData: {},
  });
  const [currentProfessionalLogged, setCurrentProfessionalLogged] = useState(
    []
  );

  const [dates, setDates] = useState<any>();
  const [datesToSelect, setDatesToSelect] = useState<any>([]);

  const [datesWithSchedule, setDatesWithSchedule] = useState<any>();
  const [selectedDayTimes, setSelectedDayTimes] = useState<any>([]);
  const [selectedProfessional, setSelectedProfessional] = useState<any>();
  const [selectedDay, setSelectedDay] = useState<any>();
  const [patientsUbs, setPatientsUbs] = useState<any>([]);
  const handleCloseModal = () => {
    setPatientSelected('');
    setPatientData({
      time: '',
      appointmentStatus: '',
      patient: '',
      exam: '',
      scheduleId: null,
      scheduleData: {},
    });

    setCancelSchedule(false);
    setNotScheduled(false);
    setRealizeSchedule(false);
  };

  const handleOpenModal = ({
    data,
    modalToOpen,
    scheduleId,
    scheduleData,
  }: any) => {
    const [time, appointmentStatus, patient, exam] = data.split('-');

    setPatientData({
      time: time?.trim(),
      appointmentStatus: appointmentStatus?.trim(),
      patient: patient?.trim() || '',
      exam: exam?.trim() || '',
      scheduleId: modalToOpen === 'cancel_schedule' ? scheduleId : null,
      scheduleData,
    });

    setPatientSelected(patient || '');
    setRealizeSchedule(true);

    if (modalToOpen === 'realize_schedule') {
      setRealizeSchedule(true);
      setCancelSchedule(false);
    } else if (modalToOpen === 'cancel_schedule') {
      setCancelSchedule(true);
      setRealizeSchedule(false);
    }
  };

  const { getPermissao, hasPermission } = useContext(PerfilContext);

  const permissao = getPermissao();

  const { currentEstablishment } = useContext(EstablishmentContext);

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  const getOptionsProfessionals = async () => {
    try {
      const response = await getUsersAgenda({
        estabelecimentoId: String(currentEstablishmentId),
      });

      const optionsWithEstablishment = response.data.map((item) => {
        return {
          ...item,
          nomeprofissional: item.label,
        };
      });
      setCurrentProfessionalLogged(optionsWithEstablishment);
    } catch (error) {
      return;
    }
  };

  const handleClickSearchProfessionalSchedule = async () => {
    if (!selectedProfessional) {
      return;
    }
    try {
      const responseProfessionalEstablishment = await api.get(
        `/profissionais/${selectedProfessional.idprofissional}`
      );

      const establishmentId =
        responseProfessionalEstablishment.data.data[0].estabelecimentos.find(
          (item) =>
            item.estabelecimento_saude_id ===
            currentEstablishment?.estabelecimentoId
        );

      if (
        !establishmentId &&
        (permissao === 'isPadrao' ||
          !hasPermission(['ZONE_ESTADO', 'ZONE_PAIS', 'ZONE_ALL']))
      ) {
        toast.error(
          'Profissional não pertence à este estabelecimento, contate o suporte.',
          { position: 'bottom-right' }
        );
        return;
      }

      if (establishmentId) {
        const response = await getProfessionalSchedule({
          profissional_id: selectedProfessional.idprofissional,
          estabelecimento_id: establishmentId.estabelecimento_saude_id,
        });
        if (response.data['Teste de HPV']?.ativado === false) {
          setEmptySchedule(true);
          return;
        }

        setDates(response.data['Teste de HPV']?.horariosDisponiveis);
      }
    } catch (error) {}
  };

  const handleSelectProfessional = async (newValue) => {
    if (newValue) {
      setSelectedProfessional(newValue);
    } else {
      setDates(null);
      setDatesWithSchedule(null);
      setSelectedDay(null);
      setEmptySchedule(true);
      setSelectedProfessional(null);
      setValue(null);
    }
  };
  const data: { [date: string]: { hour: string; disponivel: boolean }[] } =
    dates;

  const handleChangeDatePicker = (newValue) => {
    const originalDate = moment(newValue);
    const convertedDate = originalDate.format('YYYY-MM-DD');

    setSelectedDayTimes(dates[convertedDate]);
    setSelectedDay(convertedDate);

    setValue(newValue);

    const hasHoursInThisDaySelected = !!datesWithSchedule.find((item) => {
      return item.date === convertedDate;
    });
    if (hasHoursInThisDaySelected) {
      setEmptySchedule(false);
    } else {
      setEmptySchedule(true);
    }
  };
  useEffect(() => {
    if (data) {
      const selectedDates = Object.entries(data)
        .filter(([date, schedule]) =>
          schedule.some(
            (item) =>
              item.hasOwnProperty('hour') && item.hasOwnProperty('disponivel')
          )
        )
        .reduce((filteredData, [date, schedule]) => {
          filteredData[date] = schedule;
          return filteredData;
        }, {});
      const selectedDatesArray = Object.entries(selectedDates).map(
        ([date, schedule]) => ({ date, schedule })
      );

      setDatesWithSchedule(selectedDatesArray);
    }
  }, [data]);

  useEffect(() => {
    getOptionsProfessionals();
  }, []);

  // useEffect(() => {
  //   const setListPatientsToModal = async () => {
  //     const response = await getPeopleNoParams();
  //   };

  //   setListPatientsToModal();
  // }, []);

  useEffect(() => {
    let datesArr: string[] = [];

    for (let date in dates) {
      datesArr.push(date);
    }

    setDatesToSelect(datesArr);
  }, [dates]);
  useEffect(() => {
    const getPacientes = async () => {
      const response = await api
        .get('/pacientes/nomes')
        .then((response) => response)
        .catch((error) => error);
      setPatientsUbs(response.data.pessoas);
    };

    getPacientes();
  }, []);

  // useEffect(() => {
  //   const getProfessionalsList = async () => {
  //     const res = await api.get(
  //       `/profissionais/estabelecimento/${Number(idEstablishment)}`
  //     );
  //   };

  //   getProfessionalsList();
  // }, []);

  return (
    <Box>
      <Box mt={3} display="flex" justifyContent="space-between" gap={3}>
        <Box flex={1}>
          <Controller
            control={control}
            name="professional"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={currentProfessionalLogged}
                getOptionLabel={(option) => option?.nomeprofissional}
                value={value ? value : null}
                onChange={(_, newValue) => {
                  handleSelectProfessional(newValue);

                  // handleSetFilter(newValue?.pessoa.nome, 'acs');
                  onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Profissional"
                    placeholder="Pesquise pelo nome do profissional da sua UBS "
                  />
                )}
              />
            )}
          />
        </Box>

        <Button
          variant="contained"
          type="button"
          onClick={() => handleClickSearchProfessionalSchedule()}
        >
          Buscar
        </Button>
      </Box>

      <Box mt={3} className={styles.scheduleContainer}>
        <Box className={styles.datePickerWrapper}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              label="Week picker"
              value={value}
              shouldDisableDate={(date) => {
                if (!dates) {
                  return true;
                }
                const originalDate = moment(date);
                const convertedDate = originalDate.format('YYYY-MM-DD');

                const disableDate = !!datesWithSchedule?.find((item) => {
                  return item?.date === convertedDate;
                });
                return !disableDate;
              }}
              onChange={(newValue) => {
                handleChangeDatePicker(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>

        {!emptySchedule && (
          <Box className={styles.schedules}>
            {selectedDayTimes?.map((item) => {
              const id = item.marcadoPor ? item.marcadoPor.id : null;
              if (item.disponivel === false) {
                const patientName = patientsUbs.find(
                  (patient) => patient.id === item.marcadoPor.pessoaId
                )?.nome;
                return (
                  <ScheduleListItem
                    active={true}
                    title={`${item.hour} - Agendado - ${patientName} - Teste HPV`}
                    onClick={() =>
                      handleOpenModal({
                        data: `${item.hour} - Agendado - ${patientName} - Teste HPV`,
                        modalToOpen: 'cancel_schedule',
                        scheduleId: id,
                        scheduleData: item.marcadoPor,
                      })
                    }
                  />
                );
              } else {
                return (
                  <ScheduleListItem
                    title={`${item.hour} - Horário livre`}
                    onClick={() =>
                      handleOpenModal({
                        data: `${
                          item.hour
                        } - Horário livre - ${''} - Teste de HPV`,
                        modalToOpen: 'realize_schedule',
                        scheduleId: null,
                      })
                    }
                  />
                );
              }
            })}
          </Box>
        )}

        {emptySchedule && (
          <Box className={styles.emptySchedules}>
            <Typography
              variant="h5"
              fontWeight="700"
              sx={{ color: 'rgba(0, 0, 0, 0.60)' }}
            >
              Agenda Vazia
            </Typography>
            <img src={EmptySchedule} width="240" alt="Agenda Vazia" />

            <Box className={styles.configScheduleInfo}>
              <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.60)' }}>
                Não há nenhuma agenda configurada para este dia, verifique
                outros dias, outros profissionais ou{' '}
                <Link to="/configuracao-agenda-digital">
                  Configure a Agenda
                </Link>
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {notScheduled && (
        // <CancelScheduleModal
        //   title="Cancelar Agendamento"
        //   open={scheduled}
        //   handleClose={handleCloseModal}
        //   patientName={patientSelected}
        //   examName={patientData.exam}
        //   appointmentTime={patientData.time}
        // />
        <ModalScheduling
          title="Cancelar Agendamento"
          open={notScheduled}
          handleClose={handleCloseModal}
          patientName={patientSelected}
          examName={patientData.exam}
          appointmentTime={patientData.time}
          listTimes={selectedDayTimes}
          optionsPatients={patientsUbs}
        />
      )}

      {realizeSchedule && (
        <RealizeSchedule
          open={realizeSchedule}
          appointmentTime={patientData.time}
          appointmentDate={selectedDay}
          patientName={patientSelected}
          profissionalName={selectedProfessional?.nomeprofissional}
          handleClose={handleCloseModal}
          profissionalId={selectedProfessional?.idprofissional}
          examName={patientData.exam}
          listTimes={selectedDayTimes}
          optionsPatients={patientsUbs}
        />
      )}

      {cancelSchedule && (
        <CancelScheduleModal
          scheduleId={patientData.scheduleId}
          handleClose={handleCloseModal}
          patientName={patientSelected}
          examName={patientData.exam}
          optionsDays={datesToSelect}
          appointmentTime={patientData.time}
          open={cancelSchedule}
          scheduleData={patientData.scheduleData}
          appointmentDate={selectedDay}
          profissionalName={selectedProfessional?.nomeprofissional}
          datesWithSchedule={datesWithSchedule}
        />
      )}
    </Box>
  );
};
