/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import AlertInfo from '../../../assets/imgs/AlertInfo.svg';

import ptBR from 'date-fns/locale/pt-BR';
import jwtDecode from 'jwt-decode';
import { useEffect, useState, ChangeEvent, useContext } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../../../api';
import { TokenData, User } from '../../../components/ModalStatus';
import { getAccessToken, getUserToken } from '../../../lib/auth';
import { useStyles } from '../RegisterExam.style';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { Anamnese, Escolha, Motivo } from './Hpv';
import { PerfilContext } from '../../../context/PerfilContext';
import useDebounce from '../../../utils/hooks/useDebounce';
import { toast } from 'react-toastify';

enum ExmVulva {
  SemAlteracoes = '1',
  ComAlteracoes = '2',
}

enum AvalGeral {
  Adequada = '1',
  Inadequada = '2',
}

enum JuncaoEscamoColunar {
  CompletamenteVisivel = '1',
  ParcialmenteVisivel = '2',
  NaoVisivel = '3',
}

enum ZonaTransformacao {
  Tipo1 = '1',
  Tipo2 = '2',
  Tipo3 = '3',
}

enum TipoAchados {
  AchadoColposcopicoNormal = '1',
  AchadoColposcopicoAnormal = '2',
  AchadoColposcopicoAmbos = '3',
}

enum NaoEspecifico {
  Leucoplasia = '1',
  Erosao = '2',
}

// enum Lugol {
//   Positiva = "1",
//   Negativa = "2"
// }

// enum Biopsia {
//   Sim = 1,
//   Nao = 0
// }

export enum LocalBiopsia {
  Colo = '1',
  Vagina = '2',
  Ambos = '3',
}

// enum Schiller {
//   Negativo = "1",
//   Positivo = "2s"
// }
interface ColposcopicosAnormal {
  nao_especifico: string;
  localizacao_colo: boolean;
  localizacao_vagina: boolean;
  localizacao_zona_de_transformacao: string;
  n_quadrantes: number;
  localizacao?: any;
}

interface IColposcopia {
  dt_coleta: String;
  exame_macroscopico: ExmVulva;
  avaliacao_geral: AvalGeral;
  visibilidade_juncao_escamocolunar: JuncaoEscamoColunar;
  zona_transformacao: ZonaTransformacao;
  tipo_achados: TipoAchados | null | undefined;
  aspectos_achados_anormais: ColposcopicosAnormal;
  localizacao: any;
  miscelania: Boolean;
  captacao_lugol: Boolean;
  teste_schiller: Boolean;
  observacoes: String;
  realizar_biopsia: Boolean | String;
  localizacao_biopsia: LocalBiopsia | undefined;
  sem_biopsia_previa: Boolean | undefined;
  pessoaId: number;
  motivo_inadequada: any;
  grau_menor: any;
  grau_maior: any;
  resultados_miscelania: any;
  suspeitas_invasao: any;
}

interface Inputs {
  anamnese: Anamnese;
  colposcopia: IColposcopia;
  pessoaId: number;
  profissionalId: { id?: any };
  responsavelId: number;
  estabelecimentoSaudeId: { id?: any; nome_fantasia?: any; cnes?: any };
  rede_atendimento: number;
  cbo: string;
  cns: string;
  cnes: string;
  protocolo: string;
}

const schema = Yup.object({
  colposcopia: Yup.object({
    realizar_biopsia: Yup.string().required('Campo Obrigatório'),
    localizacao_biopsia: Yup.string().when('realizar_biopsia', {
      is: (realizarBiopsiaValue) => {
        return realizarBiopsiaValue === 'true';
      },

      then: Yup.string().required('Campo Obrigatório'),
    }),
    sem_biopsia_previa: Yup.boolean(),
    dt_coleta: Yup.string().required('Campo Obrigatório'),
    exame_macroscopico: Yup.string().required('Campo Obrigatório'),
    avaliacao_geral: Yup.string().required('Campo Obrigatório'),
    // motivo_inadequada: Yup.string().when('avaliacao_geral', {
    //   is: (avaliacaoGeral) => {
    //     return avaliacaoGeral === AvalGeral.Inadequada;
    //   },

    //   then: Yup.string().required('Campo Obrigatório'),
    // }),
    visibilidade_juncao_escamocolunar:
      Yup.string().required('Campo Obrigatório'),
    zona_transformacao: Yup.string().required('Campo Obrigatório'),
    tipo_achados: Yup.string().required('Campo Obrigatório'),
    aspectos_achados_anormais: Yup.object({}).when('tipo_achados', {
      is: (tipoAchadosValue) => {
        return tipoAchadosValue === TipoAchados.AchadoColposcopicoAnormal;
      },
      then: Yup.object({}).required('Campo Obrigatório'),
    }),
    localizacao: Yup.object({}).when('aspectos_achados_anormais', {
      is: (aspectosAnormaisValue) => {
        return aspectosAnormaisValue === TipoAchados.AchadoColposcopicoAnormal;
      },
      then: Yup.object({}).required('Campo Obrigatório'),
    }),
    captacao_lugol: Yup.boolean().required('Campo Obrigatório'),
    teste_schiller: Yup.boolean().required('Campo Obrigatório'),
  }),
  rede_atendimento: Yup.number().required('Campo Obrigatório'),
  cnes: Yup.string().required('Campo Obrigatório'),
  estabelecimentoSaudeId: Yup.object({
    id: Yup.number().required('Campo Obrigatório'),
  }),
  // profissionalId: Yup.string().required('Campo Obrigatório'),
  profissionalId: Yup.object({
    id: Yup.number().required('Campo Obrigatório'),
    cns: Yup.string(),
    cbo: Yup.string(),
  }),
  // anamnese: Yup.object({
  //   motivo: Yup.string().required('Campo Obrigatório'),
  //   papanicolaou: Yup.string().required('Campo Obrigatório'),
  //   ano_papanicolaou: Yup.number().when('papanicolaou', {
  //     is: (papanicolaou: any) => {
  //       return papanicolaou === Escolha.Sim;
  //     },
  //     then: Yup.number().required('Campo Obrigatório'),
  //   }),
  //   diu: Yup.string().required('Campo Obrigatório'),
  //   gravida: Yup.string().required('Campo Obrigatório'),
  //   anticoncepcional: Yup.string().required().required('Campo Obrigatório'),
  //   hormonio: Yup.string().required('Campo Obrigatório'),
  //   radioterapia: Yup.string().required('Campo Obrigatório'),
  //   dt_menstruacao_desconhecida: Yup.boolean().default(false),
  //   dt_menstruacao: Yup.string().when('dt_menstruacao_desconhecida', {
  //     is: (checkedMenstruacao: boolean) => {
  //       return checkedMenstruacao === false;
  //     },
  //     then: Yup.string().required('Campo Obrigatório'),
  //   }),
  //   sangramento_relacao: Yup.string().required('Campo Obrigatório'),
  //   sangramento_menopausa: Yup.string(),
  // }),
});

export default function Colposcopia() {
  const styles = useStyles();
  const location = useLocation();
  const [optionsEstabelecimentos, setOptionsEstabelecimentos] = useState<any>(
    []
  );
  const [cnesEstabelecimento, setCnesEstabelecimento] = useState<any>();
  const [pacienteData, setPacienteData] = useState<any>();
  const [checkedMenstruacao, setCheckedMenstruacao] = useState<boolean>(false);
  const [tipoAchado, setTipoAchado] = useState<any>({
    normal: false,
    anormal: false,
  });

  const [localizacaoBiopsia, setLocalizacaoBiopsia] = useState<any>({});
  const [medicoLab, setMedicoLab] = useState<any>(undefined);

  const [openDialogCancel, setOpenDialogCancel] = useState<boolean>(false);
  const [optionsResponsaveis, setOptionsResponsaveis] = useState<any>([]);
  const [disableInputResponsavel, setDisableInputResponsavel] = useState(false);

  const [disableLaboratorio, setDisableLaboratorio] = useState(false);

  const [loadingLaboratorios, setLoadingLaboratorios] = useState(true);
  const [disableCnes, setDisableCnes] = useState(true);
  const [modeInputsEstabelecimento, setModeInputsEstabelecimento] =
    useState('select');
  const [savedOptionsEstabelecimentos, setSavedOptionsEstabelecimentos] =
    useState([]);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    setError,
    watch,
    clearErrors,
  } = useForm<Inputs>({
    defaultValues: { rede_atendimento: 1 },
    resolver: yupResolver(schema),
  });
  const [user, setUser] = useState({} as User);

  const { hasPermission } = useContext(PerfilContext);
  const { replace: append_motivo_inadequada, fields: motivo_inadequada } =
    useFieldArray({
      name: 'colposcopia.motivo_inadequada',
      control,
    });

  const { replace: append_grau_menor, fields: grau_menor } = useFieldArray({
    name: 'colposcopia.grau_menor',
    control,
  });
  const { replace: append_grau_maior, fields: grau_maior } = useFieldArray({
    name: 'colposcopia.grau_maior',
    control,
  });
  const {
    replace: append_resultados_miscelania,
    fields: resultados_miscelania,
  } = useFieldArray({
    name: 'colposcopia.resultados_miscelania',
    control,
  });
  const { replace: append_suspeitas_invasao, fields: suspeitas_invasao } =
    useFieldArray({
      name: 'colposcopia.suspeitas_invasao',
      control,
    });
  useEffect(() => {
    api.get('forms/colposcopia').then((res) => {
      append_motivo_inadequada(res.data.motivo_inadequada);
      append_grau_menor(res.data.grau_menor);
      append_grau_maior(res.data.grau_maior);
      append_resultados_miscelania(res.data.miscelania);
      append_suspeitas_invasao(res.data.suspeitas_invasao);
    });
  }, []);

  const onSubmit = async (data: any) => {
    data.colposcopia.sem_biopsia_previa = !data.colposcopia.sem_biopsia_previa
      ? false
      : true;

    if (data.colposcopia.aspectos_achados_anormais) {
      // data.colposcopia.aspectos_achados_anormais.localizacao_colo = !data
      //   .colposcopia.aspectos_achados_anormais.localizacao_colo
      //   ? false
      //   : true;
      // data.colposcopia.aspectos_achados_anormais.localizacao_vagina = !data
      //   .colposcopia.aspectos_achados_anormais.localizacao_vagina
      //   ? false
      //   : true;

      data.colposcopia.aspectos_achados_anormais = {
        ...data.colposcopia.aspectos_achados_anormais,
        grau_maior: data.colposcopia.grau_maior,
        grau_menor: data.colposcopia.grau_menor,
        suspeitas_invasao: data.colposcopia.suspeitas_invasao,
      };
    }
    data.colposcopia.pessoaId = pacienteData.id;
    data.colposcopia.realizar_biopsia =
      data.colposcopia.realizar_biopsia === 'true';

    if (!data.colposcopia.observacoes) {
      data.colposcopia.observacoes = null;
    }

    let localizacaoBiopsiaArray: string[] = [];

    if (localizacaoBiopsia.colo === true) {
      localizacaoBiopsiaArray.push('COLO');
    }

    if (localizacaoBiopsia.vagina === true) {
      localizacaoBiopsiaArray.push('VAGINA');
    }

    if (localizacaoBiopsiaArray.length > 0) {
      data.colposcopia.localizacao_biopsia = localizacaoBiopsiaArray;
    }

    // if (data.anamnese.sangramento_menopausa === undefined) {
    //   data.anamnese.sangramento_menopausa = null;
    // }

    const objPayload = {
      ...data.colposcopia,
      profissionalId: data.profissionalId.id,
      estabelecimentoSaudeId: data?.estabelecimentoSaudeId?.id,
      rede_atendimento: data?.rede_atendimento,
      // anamnese: { ...data.anamnese },
      aspectos_achados_anormais: {
        ...data.colposcopia.aspectos_achados_anormais,
        grau_maior: data.colposcopia.grau_maior,
        grau_menor: data.colposcopia.grau_menor,
        suspeita_de_invasao: data.colposcopia.suspeitas_invasao,
      },
    };

    try {
      await api.post('/colposcopias', objPayload);
      if (pacienteData.id) {
        navigate(`/pacientes/${pacienteData.id}`);
      } else {
        navigate(`/pacientes`);
      }
    } catch (error: any) {
      toast.error(error.error.message, { position: 'bottom-right' });
      console.error('ops! ocorreu um erro' + error);
    }
  };

  const getOptionsProfissionais = async (estab_id: number | string) => {
    if (!estab_id) return;

    const response = await api.get('/profissionais/cbo/list', {
      params: {
        cboType: 'MÉDICO',
        estabelecimentoId: estab_id,
        removeFilterByMunicipio: true,
      },
    });

    const userLoggedIsMedicoLaboratorio = response.data.find(
      (responsavel) => responsavel?.pessoa?.id === currentUser?.pessoa.id
    );

    setOptionsResponsaveis(response.data);
  };
  // const getOptionsEstabelecimentos = async () => {
  //   /*const response = await api.get('/estabelecimentos-saude', {
  //     params: { id: '1474' }, //TODO: depois pegar por rota os laboratorios
  //   });*/
  //   const response = await api.get('/estabelecimentos-saude/laboratorios', {
  //     params: { colposcopia: true },
  //   });

  //   const options = response.data.data.map((lab) => {
  //     return {
  //       id: lab.id,
  //       nome_fantasia: lab.nome_fantasia,
  //       cnes: lab.cnes,
  //     };
  //   });

  //   setOptionsEstabelecimentos(options);
  // };

  const getOptionsEstabelecimentosAdminsUsers = async () => {
    const municipioId = currentUser.estabelecimento_saude.endereco.municipio.id;
    const estado =
      currentUser.estabelecimento_saude.endereco.municipio.estadoSigla;

    const response = await api.get('/estabelecimentos-saude/laboratorios', {
      params: {
        municipio_id: null,
        estado: isAdminEstadual ? estado : null,
        colposcopia: true,
      },
    });
    setOptionsEstabelecimentos(
      response.data.data.map((lab: any) => {
        return { id: lab.id, nome_fantasia: lab.nome_fantasia, cnes: lab.cnes };
      })
    );
  };

  const getOptionsEstabelecimentosBySearch = async ({ cnes }) => {
    const response = await api.get('/estabelecimentos-saude/laboratorios', {
      params: {
        cnes,
      },
    });

    setOptionsEstabelecimentos(
      response.data.data.map((lab: any) => {
        return { id: lab.id, nome_fantasia: lab.nome_fantasia, cnes: lab.cnes };
      })
    );

    if (response.data.data.length > 0) {
      clearErrors('cnes');
      clearErrors('estabelecimentoSaudeId');
      setValue('estabelecimentoSaudeId', {
        id: response.data.data?.[0].id,
        nome_fantasia: response.data.data?.[0].nome_fantasia,
        cnes: response.data.data?.[0].cnes,
      });

      getOptionsProfissionais(response.data.data?.[0].id);
    } else {
      setValue('estabelecimentoSaudeId', {});
      setError('cnes', { type: 'manual', message: ' CNES inválido' });
      setOptionsResponsaveis([]);
    }

    setLoadingLaboratorios(false);
  };

  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },
    '& .MuiFormLabel-asterisk': {
      color: '#d32f2f',
    },
  };

  const styleCheckBox = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const { id } = useParams();
  useEffect(() => {
    async function loadData(id: any) {
      await api
        .get('/pessoas/' + id)
        .then((response) => {
          setPacienteData(response.data);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    loadData(id);
  }, [id]);

  let navigate = useNavigate();

  /*useEffect(() => {
    setPacienteData(location.state);
    async function loadResponsaveis() {
      const estabelecimentos = await api.get('/estabelecimentos-saude');
      await api
        .get('/profissionais')
        .then((response) => {
          setValue('estabelecimentoSaudeId', estabelecimentos.data.data[3].id);
          setValue('responsavelId', response.data.data[0].id);
          // setValue('profissionalId', response.data.data[0].id);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    loadResponsaveis();
  }, []);*/

  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate(`/pacientes/${pacienteData.id}`);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('anamnese.dt_menstruacao', '');
    }
    setValue('anamnese.dt_menstruacao_desconhecida', event.target.checked);
    setCheckedMenstruacao(event.target.checked);
    clearErrors('anamnese.dt_menstruacao');
  };

  const rhf = watch();

  useEffect(() => {
    const token: TokenData = jwtDecode(getAccessToken());
    if (token) {
      setUser(token.user);
    }
  }, []);

  useEffect(() => {
    if (tipoAchado.normal === true && tipoAchado.anormal === true) {
      setValue('colposcopia.tipo_achados', TipoAchados.AchadoColposcopicoAmbos);
    }

    if (tipoAchado.normal === true && tipoAchado.anormal === false) {
      setValue(
        'colposcopia.tipo_achados',
        TipoAchados.AchadoColposcopicoNormal
      );
    }

    if (tipoAchado.normal === false && tipoAchado.anormal === true) {
      setValue(
        'colposcopia.tipo_achados',
        TipoAchados.AchadoColposcopicoAnormal
      );
    }

    if (tipoAchado.normal === false && tipoAchado.anormal === false) {
      setValue('colposcopia.tipo_achados', undefined);
    }

    clearErrors('colposcopia.tipo_achados');
  }, [tipoAchado]);

  const currentUser = getUserToken();

  const isContextUpae =
    currentUser.permissao_atual?.contexto === 'HOSPITAL/UPAE';

  const isAdminMunicipal =
    currentUser.permissao_atual?.contexto === 'GESTÃO' &&
    currentUser.permissao_atual?.id === 3;

  const isAdminEstadual =
    currentUser.permissao_atual?.contexto === 'GESTÃO' &&
    currentUser.permissao_atual?.id === 4;

  const isSuperAdmin = currentUser.permissao_atual?.nome === 'SuperAdmin';

  const isAdminLaboratorio =
    currentUser.permissao_atual?.contexto === 'LABORATÓRIO' &&
    currentUser.permissao_atual?.nome === 'Admin Estabelecimento';

  const isMedicoLaboratorio =
    currentUser.permissao_atual?.contexto === 'LABORATÓRIO' &&
    currentUser.permissao_atual?.id === 7;

  const isOutrosLaboratorio =
    currentUser.permissao_atual?.contexto === 'LABORATÓRIO' &&
    currentUser.permissao_atual?.id === 8;

  const isMedicoHospital =
    currentUser.permissao_atual?.contexto === 'HOSPITAL/UPAE' &&
    currentUser.permissao_atual?.id === 14;

  const isAdminHospital =
    currentUser.permissao_atual?.contexto === 'HOSPITAL/UPAE' &&
    currentUser.permissao_atual?.id === 16;

  const isOutrosHospital =
    currentUser.permissao_atual?.contexto === 'HOSPITAL/UPAE' &&
    currentUser.permissao_atual?.id === 15;

  const laboratorioUserId = getUserToken().estabelecimento_saude?.id;

  const getOptionsEstabelecimentos = async () => {
    /*const response = await api.get('/estabelecimentos-saude', {
      params: { id: '1474' }, //TODO: depois pegar por rota os laboratorios
    });*/
    const response = await api.get('/estabelecimentos-saude/laboratorios', {
      params: { colposcopia: true },
    });
    const options = response.data.data.map((lab) => {
      return {
        id: lab.id,
        nome_fantasia: lab.nome_fantasia,
        cnes: lab.cnes,
      };
    });

    setOptionsEstabelecimentos(options);
    setSavedOptionsEstabelecimentos(options);
  };

  useEffect(() => {
    if (isSuperAdmin || isAdminHospital || isAdminLaboratorio)
      getOptionsEstabelecimentos();
  }, []);

  // useEffect(() => {
  //   const laboratorioSelected = optionsEstabelecimentos?.find(
  //     (lab) => lab.id === laboratorioUserId
  //   );
  //   if (
  //     isAdminLaboratorio ||
  //     isMedicoLaboratorio ||
  //     isOutrosLaboratorio ||
  //     isMedicoHospital ||
  //     isAdminHospital
  //   ) {
  //     setDisableLaboratorio(true);
  //     getOptionsProfissionais(laboratorioSelected?.id);
  //     setCnesEstabelecimento(laboratorioSelected?.cnes);

  //     setValue('estabelecimentoSaudeId', laboratorioSelected);
  //   }

  //   if (isMedicoLaboratorio || isMedicoHospital) {
  //     const pessoa = getUserToken();
  //     const profissional = optionsResponsaveis.find(
  //       (resp) => resp?.pessoa?.id === 133862
  //     );

  //     setDisableInputResponsavel(true);
  //     setMedicoLab(pessoa?.pessoa?.nome);
  //     setValue('profissionalId', profissional);
  //     setValue('cbo', profissional?.cbo);
  //     setValue('cns', profissional?.cns);
  //   }

  //   if (isOutrosLaboratorio) {
  //     setDisableInputResponsavel(false);
  //     setValue('profissionalId', null);
  //     setValue('cbo', '');
  //     setValue('cns', '');
  //   }
  // }, [
  //   isAdminLaboratorio,
  //   isMedicoLaboratorio,
  //   isOutrosLaboratorio,
  //   isMedicoHospital,
  //   isAdminHospital,
  //   // optionsResponsaveis,
  //   optionsEstabelecimentos,
  // ]);

  // useEffect(() => {
  //   if (isMedicoLaboratorio || isMedicoHospital) {
  //     getOptionsProfissionais(laboratorioUserId);
  //   }
  // }, []);

  // const setProfissional = async (cpf) => {
  //   try {
  //     const response = await api.get('/profissionais', { params: { cpf } });

  //     const obj = {
  //       id: response.data.data[0].id,
  //       cns: response.data.data[0].cns,
  //       cbo: response.data.data[0].cbo,

  //       pessoa: {
  //         id: response.data.data[0].pessoa.id,
  //         nome: response.data.data[0].pessoa.nome,
  //       },
  //     };

  //     setValue('cbo', obj?.cbo);
  //     setValue('cns', obj?.cns);
  //     setValue('profissionalId', obj);
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   if (isMedicoLaboratorio || isMedicoHospital) {
  //     const pessoa = getUserToken();
  //     const profissional = optionsResponsaveis.find(
  //       (resp) => resp?.pessoa?.id === pessoa?.pessoa?.id
  //     );

  //     setDisableInputResponsavel(true);
  //     setMedicoLab(pessoa?.pessoa?.nome);
  //     setProfissional(pessoa?.pessoa.cpf);
  //   }
  // }, [optionsResponsaveis, isMedicoLaboratorio]);

  useEffect(() => {
    if (!hasPermission('VIEW_EXAME_COLPOSCOPIA_RESULTADO')) {
      navigate('/home');
    }
  }, []);
  const handleChangeCnes = (cnes) => {
    if (cnes) {
      setLoadingLaboratorios(true);

      getOptionsEstabelecimentosBySearch({ cnes });
    } else {
      setValue('estabelecimentoSaudeId', {});
      setOptionsEstabelecimentos([]);
      setLoadingLaboratorios(false);
    }
  };

  const debounceChange = useDebounce(handleChangeCnes, 1000);

  const setDefaultLaboratorio = () => {
    const {
      estabelecimentoId,
      estabelecimento_saude: { nome_fantasia, cnes },
    } = currentUser;

    const objUserLaboratorio = { id: estabelecimentoId, nome_fantasia, cnes };

    setDisableLaboratorio(true);
    setDisableCnes(true);
    setOptionsEstabelecimentos([objUserLaboratorio]);
    getOptionsProfissionais(estabelecimentoId);
    setValue('estabelecimentoSaudeId', objUserLaboratorio);
    setCnesEstabelecimento(cnes);
    setValue('cnes', cnes);
  };

  const setDefaultProfissional = async () => {
    setDisableInputResponsavel(true);
    try {
      const response = await api.get('/profissionais', {
        params: { cpf: currentUser.pessoa.cpf },
      });

      const obj = {
        id: response.data.data[0].id,
        cns: response.data.data[0].cns,
        cbo: response.data.data[0].cbo,

        pessoa: {
          id: response.data.data[0].pessoa.id,
          nome: response.data.data[0].pessoa.nome,
        },
      };

      setValue('cbo', obj?.cbo);
      setValue('cns', obj?.cns);
      setValue('profissionalId', obj);
    } catch (error) {}
  };

  const disableInputsEstabelecimentoByPerfil = (value) => {
    const selectedRedeSus = value === '1';
    const selectedRedeSuplementar = value === '2';
    setValue('cnes', '');
    setCnesEstabelecimento('');
    setValue('estabelecimentoSaudeId', { nome_fantasia: '' });
    clearErrors('cnes');
    clearErrors('estabelecimentoSaudeId');
    setOptionsResponsaveis([]);

    if (isSuperAdmin && selectedRedeSus) {
      setDisableCnes(true);
      setModeInputsEstabelecimento('select');
      setOptionsEstabelecimentos(savedOptionsEstabelecimentos);
    } else if (isSuperAdmin && selectedRedeSuplementar) {
      setDisableCnes(false);
      setModeInputsEstabelecimento('search');
    }
    if (
      (isAdminLaboratorio ||
        isAdminHospital ||
        isOutrosHospital ||
        isOutrosLaboratorio) &&
      selectedRedeSus
    ) {
      setDefaultLaboratorio();
    }

    if (
      (isAdminLaboratorio ||
        isAdminHospital ||
        isOutrosHospital ||
        isOutrosLaboratorio) &&
      selectedRedeSuplementar
    ) {
      setModeInputsEstabelecimento('search');
      setDisableCnes(false);
    }

    if ((isAdminMunicipal || isAdminEstadual) && selectedRedeSuplementar) {
      setDisableCnes(false);
      setModeInputsEstabelecimento('search');
    } else if ((isAdminMunicipal || isAdminEstadual) && selectedRedeSus) {
      getOptionsEstabelecimentosAdminsUsers();
      setDisableCnes(true);
      setModeInputsEstabelecimento('select');
    }
  };
  const setValuesByPerfil = () => {
    if (
      isAdminLaboratorio ||
      isOutrosLaboratorio ||
      isMedicoLaboratorio ||
      isMedicoHospital ||
      isAdminHospital ||
      isOutrosHospital
    ) {
      setDefaultLaboratorio();
    }

    if (isMedicoLaboratorio || isMedicoHospital) {
      setDefaultProfissional();
    }

    if (isOutrosLaboratorio || isOutrosHospital) {
      getOptionsProfissionais(currentUser.estabelecimentoId);
    }

    if (isContextUpae) {
      setValue('rede_atendimento', 1);
    }
  };

  useEffect(() => {
    setValuesByPerfil();
  }, []);

  useEffect(() => {
    if (isAdminMunicipal || isAdminEstadual) {
      getOptionsEstabelecimentosAdminsUsers();
    }
  }, [isAdminMunicipal]);

  return (
    <Box>
      <Box sx={{ width: '100%' }} mt={6}>
        <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
          <DialogTitle>Cancelar cadastro de resultado do exame</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deseja realmente cancelar o cadastro de resultado do exame ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogCancel}>Não</Button>
            <Button onClick={handleCloseConfirmCancel} autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
          <i>* Resposta obrigatória</i>
        </p>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box sx={{ width: '100%' }}>
            <Paper
              variant="outlined"
              sx={{ width: '100%', borderRadius: '10px' }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ margin: '25px' }}
              >
                Resultado do Exame
              </Typography>
              <Divider />
              <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <FormControl
                    // error={!!errors.colposcopia?.dt_coleta && true}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptBR}
                      >
                        <div>
                          <Controller
                            control={control}
                            name="colposcopia.dt_coleta"
                            render={({ field: { onChange, value } }) => (
                              <DatePicker
                                onChange={onChange}
                                mask="__/__/____"
                                value={value ? value : null}
                                renderInput={(params) => (
                                  <StyledAsteriskTextField
                                    required
                                    {...params}
                                    error={
                                      !!errors.colposcopia?.dt_coleta && true
                                    }
                                    style={{ marginTop: '20px' }}
                                    label="Data da Colposcopia"
                                    size="small"
                                    variant="outlined"
                                    helperText={
                                      errors.colposcopia?.dt_coleta?.message
                                    }
                                    className={styles.textField}
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      required
                      error={errors.colposcopia?.exame_macroscopico && true}
                    >
                      <FormLabel sx={styleFormLabel}>
                        Exame macroscópico da vulva e região perianal
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            row
                            sx={{ marginLeft: '15px' }}
                          >
                            <FormControlLabel
                              value={ExmVulva.SemAlteracoes}
                              control={<Radio sx={styleRadio} />}
                              label="Sem Alterações"
                            />
                            <FormControlLabel
                              value={ExmVulva.ComAlteracoes}
                              control={<Radio sx={styleRadio} />}
                              label="Com Alterações"
                            />
                          </RadioGroup>
                        )}
                        name="colposcopia.exame_macroscopico"
                        control={control}
                      />
                      <FormHelperText>
                        {errors.colposcopia?.exame_macroscopico?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      required
                      error={errors.colposcopia?.avaliacao_geral && true}
                    >
                      <FormLabel sx={styleFormLabel}>
                        Avaliação Geral da Colposcopia
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            row
                            sx={{ marginLeft: '15px' }}
                          >
                            <FormControlLabel
                              value={AvalGeral.Adequada}
                              control={<Radio sx={styleRadio} />}
                              label="Adequada"
                            />
                            <FormControlLabel
                              value={AvalGeral.Inadequada}
                              control={<Radio sx={styleRadio} />}
                              label="Inadequada"
                            />
                          </RadioGroup>
                        )}
                        name="colposcopia.avaliacao_geral"
                        control={control}
                      />
                      <FormHelperText>
                        {errors.colposcopia?.avaliacao_geral?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {watch('colposcopia.avaliacao_geral') ===
                    AvalGeral.Inadequada && (
                    <Grid item xs={12}>
                      <FormControl
                        required
                        error={!!errors.colposcopia?.motivo_inadequada}
                      >
                        <FormLabel sx={styleFormLabel}>
                          Inadequado por:{' '}
                        </FormLabel>
                        <Box sx={{ marginLeft: '15px' }}>
                          {motivo_inadequada.map((item: any, index: number) => {
                            return (
                              <Controller
                                key={item.id}
                                name={`colposcopia.motivo_inadequada.${index}.value`}
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox {...field} sx={styleCheckBox} />
                                    }
                                    label={item.motivo_inadequada.nome}
                                  />
                                )}
                              />
                            );
                          })}
                        </Box>
                        <FormHelperText>
                          {errors.colposcopia?.motivo_inadequada?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl
                      error={
                        !!errors?.colposcopia?.visibilidade_juncao_escamocolunar
                      }
                    >
                      <FormLabel sx={styleFormLabel} required>
                        Visibilidade da junção escamocolunar
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            row
                            sx={{ marginLeft: '15px' }}
                          >
                            <FormControlLabel
                              value={JuncaoEscamoColunar.CompletamenteVisivel}
                              control={<Radio sx={styleRadio} />}
                              label="Completamente Visivel"
                            />
                            <FormControlLabel
                              value={JuncaoEscamoColunar.ParcialmenteVisivel}
                              control={<Radio sx={styleRadio} />}
                              label="Parcialmente Visivel"
                            />
                            <FormControlLabel
                              value={JuncaoEscamoColunar.NaoVisivel}
                              control={<Radio sx={styleRadio} />}
                              label="Nao Visivel"
                            />
                          </RadioGroup>
                        )}
                        name="colposcopia.visibilidade_juncao_escamocolunar"
                        control={control}
                      />
                      <FormHelperText>
                        {
                          errors.colposcopia?.visibilidade_juncao_escamocolunar
                            ?.message
                        }
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      error={!!errors?.colposcopia?.zona_transformacao}
                    >
                      <FormLabel sx={styleFormLabel} required>
                        Zona de Transformação (ZT)
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            row
                            sx={{ marginLeft: '15px' }}
                          >
                            <FormControlLabel
                              value={ZonaTransformacao.Tipo1}
                              control={<Radio sx={styleRadio} />}
                              label="Tipo 1"
                            />
                            <FormControlLabel
                              value={ZonaTransformacao.Tipo2}
                              control={<Radio sx={styleRadio} />}
                              label="Tipo 2"
                            />
                            <FormControlLabel
                              value={ZonaTransformacao.Tipo3}
                              control={<Radio sx={styleRadio} />}
                              label="Tipo 3"
                            />
                          </RadioGroup>
                        )}
                        name="colposcopia.zona_transformacao"
                        control={control}
                      />
                      <FormHelperText>
                        {errors.colposcopia?.zona_transformacao?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error={!!errors?.colposcopia?.tipo_achados}>
                      <div style={{ display: 'flex' }}>
                        <FormLabel required sx={styleFormLabel}>
                          Tipo de Achados
                        </FormLabel>
                        {/* <Tooltip
                          title="Estamos cientes da coexistência de acharmos normais e anormais, 
                      no entanto, visto o objetivoda colposcopia, 
                      consideramos os achados de maior importância."
                          placement="top"
                        >
                          <img
                            src={AlertInfo}
                            alt=""
                            style={{ marginLeft: '13px', width: '24px' }}
                          />
                        </Tooltip> */}
                      </div>

                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup row sx={{ marginLeft: '15px' }}>
                            <FormControlLabel
                              value={TipoAchados.AchadoColposcopicoNormal}
                              control={
                                <Checkbox
                                  sx={styleRadio}
                                  checked={tipoAchado.normal}
                                  onChange={(_, checked) =>
                                    setTipoAchado((prev) => {
                                      return {
                                        ...prev,
                                        normal: checked,
                                      };
                                    })
                                  }
                                />
                              }
                              label="Achado Colposcópico Normal"
                            />
                            <FormControlLabel
                              value={TipoAchados.AchadoColposcopicoAnormal}
                              control={
                                <Checkbox
                                  sx={styleRadio}
                                  checked={tipoAchado.anormal}
                                  onChange={(_, checked) =>
                                    setTipoAchado((prev) => {
                                      return {
                                        ...prev,
                                        anormal: checked,
                                      };
                                    })
                                  }
                                />
                              }
                              label="Achado Colposcópico Anormal"
                            />
                          </RadioGroup>
                        )}
                        name="colposcopia.tipo_achados"
                        control={control}
                      />
                      <FormHelperText>
                        {errors.colposcopia?.tipo_achados?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {((tipoAchado.normal === true &&
                    tipoAchado.anormal === true) ||
                    tipoAchado.anormal === true) && (
                    <Paper
                      sx={{
                        width: '100%',
                        marginLeft: '25px',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '10px',
                        marginTop: '25px',
                      }}
                    >
                      <Typography style={{ margin: '25px' }}>
                        Aspectos dos Achados Colposcópicos Anormais
                      </Typography>
                      <Divider sx={{ margin: '25px' }} />
                      <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item xs={12}>
                            <FormControl
                              error={!!errors?.colposcopia?.localizacao}
                            >
                              <FormLabel
                                error={!!errors?.colposcopia?.localizacao}
                                sx={styleFormLabel}
                                required
                              >
                                Localização
                              </FormLabel>
                              <Box
                                sx={{
                                  marginLeft: '15px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          {...field}
                                          value={
                                            field.value ? field.value : false
                                          }
                                          sx={styleCheckBox}
                                        />
                                      }
                                      label="Colo"
                                    />
                                  )}
                                  name="colposcopia.aspectos_achados_anormais.localizacao_colo"
                                />
                                {watch(
                                  'colposcopia.aspectos_achados_anormais.localizacao_colo'
                                ) && (
                                  <Box sx={{ marginLeft: '25px' }}>
                                    <Grid item xs={12}>
                                      <FormControl
                                        error={
                                          !!errors.colposcopia
                                            ?.aspectos_achados_anormais
                                            ?.localizacao
                                        }
                                        required
                                      >
                                        <FormLabel sx={styleFormLabel}>
                                          Localização em relação a Zona de
                                          Transformação (ZT)
                                        </FormLabel>
                                        <Controller
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <RadioGroup
                                              onChange={onChange}
                                              value={value ? value : ''}
                                              row
                                            >
                                              <FormControlLabel
                                                value={1}
                                                control={
                                                  <Radio sx={styleRadio} />
                                                }
                                                label="Dentro da ZT"
                                              />
                                              <FormControlLabel
                                                value={2}
                                                control={
                                                  <Radio sx={styleRadio} />
                                                }
                                                label="Fora da ZT"
                                              />
                                            </RadioGroup>
                                          )}
                                          name="colposcopia.aspectos_achados_anormais.localizacao_zona_de_transformacao"
                                          control={control}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormLabel sx={styleFormLabel}>
                                        Nº de quadrantes envolvidos
                                      </FormLabel>
                                      <Controller
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <RadioGroup
                                            onChange={onChange}
                                            value={value ? value : ''}
                                            row
                                          >
                                            <FormControlLabel
                                              value={1}
                                              control={
                                                <Radio sx={styleRadio} />
                                              }
                                              label="1"
                                            />
                                            <FormControlLabel
                                              value={2}
                                              control={
                                                <Radio sx={styleRadio} />
                                              }
                                              label="2"
                                            />
                                            <FormControlLabel
                                              value={3}
                                              control={
                                                <Radio sx={styleRadio} />
                                              }
                                              label="3"
                                            />
                                            <FormControlLabel
                                              value={4}
                                              control={
                                                <Radio sx={styleRadio} />
                                              }
                                              label="4"
                                            />
                                          </RadioGroup>
                                        )}
                                        name="colposcopia.aspectos_achados_anormais.n_quadrantes"
                                        control={control}
                                      />
                                    </Grid>
                                  </Box>
                                )}
                                <Controller
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          {...field}
                                          value={
                                            field.value ? field.value : false
                                          }
                                          sx={styleCheckBox}
                                        />
                                      }
                                      label="Vagina"
                                    />
                                  )}
                                  name="colposcopia.aspectos_achados_anormais.localizacao_vagina"
                                />
                              </Box>
                              <FormHelperText style={{ color: '#d32f2f' }}>
                                {
                                  errors.colposcopia?.aspectos_achados_anormais
                                    ?.localizacao?.message
                                }
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel sx={styleFormLabel}>
                              Grau 1 (Menor)
                            </FormLabel>
                            <Box sx={{ marginLeft: '15px' }}>
                              {grau_menor.map((item: any, index: number) => {
                                return (
                                  <Grid item xs={12}>
                                    <Controller
                                      key={item.id}
                                      name={`colposcopia.grau_menor.${index}.value`}
                                      control={control}
                                      render={({ field }) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              {...field}
                                              sx={styleCheckBox}
                                            />
                                          }
                                          label={item.grau_menor.nome}
                                        />
                                      )}
                                    />
                                  </Grid>
                                );
                              })}
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel sx={styleFormLabel}>
                              Grau 2 (Maior)
                            </FormLabel>
                            <Box sx={{ marginLeft: '15px' }}>
                              {grau_maior.map((item: any, index: number) => {
                                return (
                                  <Grid item xs={12}>
                                    <Controller
                                      key={item.id}
                                      name={`colposcopia.grau_maior.${index}.value`}
                                      control={control}
                                      render={({ field }) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              {...field}
                                              sx={styleCheckBox}
                                            />
                                          }
                                          label={item.grau_maior.nome}
                                        />
                                      )}
                                    />
                                  </Grid>
                                );
                              })}
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel sx={styleFormLabel}>
                              Não especifico
                            </FormLabel>
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <RadioGroup
                                  onChange={onChange}
                                  value={value ? value : ''}
                                  sx={{ marginLeft: '15px' }}
                                >
                                  <FormControlLabel
                                    value={NaoEspecifico.Leucoplasia}
                                    control={<Radio sx={styleRadio} />}
                                    label="Leucoplasia (queratose, hiperqueratose)"
                                  />
                                  <FormControlLabel
                                    value={NaoEspecifico.Erosao}
                                    control={<Radio sx={styleRadio} />}
                                    label="Erosão"
                                  />
                                </RadioGroup>
                              )}
                              name="colposcopia.aspectos_achados_anormais.nao_especifico"
                              control={control}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel sx={styleFormLabel}>
                              Suspeita de Invasão
                            </FormLabel>
                            <Box sx={{ marginLeft: '15px' }}>
                              {suspeitas_invasao.map(
                                (item: any, index: number) => {
                                  return (
                                    <Controller
                                      key={item.id}
                                      name={`colposcopia.suspeitas_invasao.${index}.value`}
                                      control={control}
                                      render={({ field }) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              {...field}
                                              sx={styleCheckBox}
                                            />
                                          }
                                          label={item.suspeitas_invasao.nome}
                                        />
                                      )}
                                    />
                                  );
                                }
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  )}
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox {...field} sx={styleCheckBox} />}
                          label="Miscelânea"
                        />
                      )}
                      name="colposcopia.miscelania"
                    />
                    {watch('colposcopia.miscelania') && (
                      <Box
                        sx={{
                          marginLeft: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {resultados_miscelania.map(
                          (item: any, index: number) => {
                            return (
                              <Grid item xs={12}>
                                <Controller
                                  key={item.id}
                                  name={`colposcopia.resultados_miscelania.${index}.value`}
                                  control={control}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          {...field}
                                          sx={styleCheckBox}
                                        />
                                      }
                                      label={item.miscelania.nome}
                                    />
                                  )}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error={!!errors.colposcopia?.captacao_lugol}>
                      <FormLabel sx={styleFormLabel} required>
                        Captação da solução de lugol
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            row
                            sx={{ marginLeft: '15px' }}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio sx={styleRadio} />}
                              label="Positiva (corado)"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio sx={styleRadio} />}
                              label="Negativa (não corado)"
                            />
                          </RadioGroup>
                        )}
                        name="colposcopia.captacao_lugol"
                        control={control}
                      />
                      <FormHelperText>
                        {errors.colposcopia?.captacao_lugol?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error={!!errors.colposcopia?.teste_schiller}>
                      <FormLabel sx={styleFormLabel} required>
                        Teste de Schiller
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            row
                            sx={{ marginLeft: '15px' }}
                          >
                            <FormControlLabel
                              value={false}
                              control={<Radio sx={styleRadio} />}
                              label="Negativo"
                            />
                            <FormControlLabel
                              value={true}
                              control={<Radio sx={styleRadio} />}
                              label="Positivo"
                            />
                          </RadioGroup>
                        )}
                        name="colposcopia.teste_schiller"
                        control={control}
                      />
                      <FormHelperText>
                        {errors?.colposcopia?.teste_schiller?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Box
                    sx={{
                      marginLeft: '25px',
                      width: '100%',
                      height: '90px',
                      marginTop: '24px',
                    }}
                  >
                    <Controller
                      name="colposcopia.observacoes"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          rows={3}
                          sx={{ width: '100%' }}
                          label="Observações e informações adicionais"
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Box>
            </Paper>
            <Paper
              variant="outlined"
              sx={{ width: '100%', borderRadius: '10px', marginTop: '24px' }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ margin: '25px' }}
              >
                Encaminhamento da Colposcopia
              </Typography>
              <Divider sx={{ margin: '25px' }} />
              <Box sx={{ margin: '25px', marginBottom: '40px' }}>
                <Grid item xs={12}>
                  <FormLabel
                    sx={styleFormLabel}
                    error={!!errors?.colposcopia?.realizar_biopsia}
                    required
                  >
                    Realizada Biópsia
                  </FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        onChange={(e) => {
                          onChange(e);
                          setValue(
                            'colposcopia.localizacao_biopsia',
                            undefined
                          );
                          setValue('colposcopia.sem_biopsia_previa', undefined);
                          clearErrors('colposcopia.localizacao_biopsia');
                          clearErrors('colposcopia.sem_biopsia_previa');
                        }}
                        value={value}
                        row
                        sx={{ marginLeft: '15px' }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio sx={styleRadio} />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio sx={styleRadio} />}
                          label="Não"
                        />
                      </RadioGroup>
                    )}
                    name="colposcopia.realizar_biopsia"
                    control={control}
                  />
                  <FormHelperText
                    error={!!errors?.colposcopia?.realizar_biopsia}
                  >
                    {errors?.colposcopia?.realizar_biopsia?.message}
                  </FormHelperText>
                </Grid>
                {watch('colposcopia.realizar_biopsia') === 'true' && (
                  <Grid item xs={12}>
                    <FormLabel
                      sx={styleFormLabel}
                      error={!!errors?.colposcopia?.localizacao_biopsia}
                      required
                    >
                      Localização da Realização da Biópsia
                    </FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => {
                        return (
                          <RadioGroup
                            value={value ? value : ''}
                            row
                            sx={{ marginLeft: '15px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={styleCheckBox}
                                  value={LocalBiopsia.Colo}
                                  onChange={(e, checked) => {
                                    clearErrors(
                                      'colposcopia.localizacao_biopsia'
                                    );
                                    setValue(
                                      'colposcopia.localizacao_biopsia',
                                      checked ? LocalBiopsia.Colo : undefined
                                    );
                                    setLocalizacaoBiopsia((prev) => {
                                      return {
                                        ...prev,
                                        colo: checked,
                                      };
                                    });
                                  }}
                                />
                              }
                              label="Colo"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={styleCheckBox}
                                  value={LocalBiopsia.Vagina}
                                  onChange={(_, checked) => {
                                    clearErrors(
                                      'colposcopia.localizacao_biopsia'
                                    );
                                    setValue(
                                      'colposcopia.localizacao_biopsia',
                                      checked ? LocalBiopsia.Vagina : undefined
                                    );
                                    setLocalizacaoBiopsia((prev) => {
                                      return {
                                        ...prev,
                                        vagina: checked,
                                      };
                                    });
                                  }}
                                />
                              }
                              label="Vagina"
                            />
                          </RadioGroup>
                        );
                      }}
                      name="colposcopia.localizacao_biopsia"
                      control={control}
                    />

                    <FormHelperText
                      error={!!errors?.colposcopia?.localizacao_biopsia}
                    >
                      {errors?.colposcopia?.localizacao_biopsia?.message}
                    </FormHelperText>
                  </Grid>
                )}
                {watch('colposcopia.realizar_biopsia') === 'false' && (
                  <Box sx={{ marginLeft: '15px' }}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} required sx={styleCheckBox} />
                          }
                          label={
                            <>
                              <span>
                                Indicado EZT sem Biópsia Prévia (ver e tratar)
                              </span>
                            </>
                          }
                        />
                      )}
                      name="colposcopia.sem_biopsia_previa"
                    />
                    <FormHelperText
                      error={!!errors?.colposcopia?.sem_biopsia_previa}
                    >
                      {errors?.colposcopia?.sem_biopsia_previa?.message}
                    </FormHelperText>
                  </Box>
                )}
              </Box>
            </Paper>

            <Box my={3}>
              <Divider />
            </Box>

            <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
              <Box p={3}>
                <Typography variant="h5" fontWeight="bold">
                  Identificação do Laboratório
                </Typography>

                <Box my={3}>
                  <Divider />
                </Box>

                <FormControl>
                  <FormLabel
                    required
                    sx={styleFormLabel}
                    id="rede-atendimento"
                    error={!!errors?.rede_atendimento}
                  >
                    Rede de Atendimento
                  </FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => {
                      return (
                        <RadioGroup
                          onChange={(e) => {
                            onChange(Number(e.target.value));
                            disableInputsEstabelecimentoByPerfil(
                              e.target.value
                            );
                          }}
                          value={value}
                          row
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio checked={value === 1} />}
                            label="Rede SUS"
                            disabled={isMedicoHospital || isMedicoLaboratorio}
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio checked={value === 2} />}
                            label="Saúde suplementar"
                            disabled={isMedicoHospital || isMedicoLaboratorio}
                          />
                        </RadioGroup>
                      );
                    }}
                    name="rede_atendimento"
                    control={control}
                  />
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {errors.rede_atendimento?.message}
                  </FormHelperText>
                </FormControl>

                <Box mt={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <StyledAsteriskTextField
                        fullWidth
                        label="CNES do Laboratório"
                        required
                        size="small"
                        error={!!errors?.cnes}
                        helperText={errors?.cnes?.message}
                        value={cnesEstabelecimento || ''}
                        variant="outlined"
                        className={styles.textField}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D+/g, '');
                          if (value.length < 8) {
                            setOptionsResponsaveis([]);
                            setValue('cnes', value);
                            clearErrors('cnes');
                            setLoadingLaboratorios(true);
                            setCnesEstabelecimento(value);
                            debounceChange(value);
                            if (value) {
                              setValue('estabelecimentoSaudeId', {
                                nome_fantasia: 'Carregando...',
                              });
                            } else {
                              setValue('estabelecimentoSaudeId', {});
                            }
                          }
                        }}
                        disabled={disableCnes}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Controller
                        name="estabelecimentoSaudeId"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              {modeInputsEstabelecimento === 'search' ? (
                                <TextField
                                  required
                                  disabled
                                  value={value?.nome_fantasia ?? ''}
                                  InputLabelProps={{
                                    shrink: !!value?.nome_fantasia,
                                  }}
                                  variant="outlined"
                                  label="Laboratório responsavél pelo Resultado"
                                  size="small"
                                  error={!!errors?.estabelecimentoSaudeId?.id}
                                  helperText={
                                    errors?.estabelecimentoSaudeId?.id?.message
                                  }
                                  fullWidth
                                />
                              ) : (
                                <Autocomplete
                                  options={optionsEstabelecimentos}
                                  value={value ? value : { nome_fantasia: '' }}
                                  disabled={disableLaboratorio}
                                  noOptionsText={
                                    loadingLaboratorios
                                      ? 'Carregando...'
                                      : 'Não há opções'
                                  }
                                  onChange={(_, newValue: any) => {
                                    onChange(newValue);
                                    clearErrors('cnes');
                                    setCnesEstabelecimento(newValue?.cnes);
                                    getOptionsProfissionais(newValue?.id);
                                    setValue('profissionalId', {
                                      id: undefined,
                                    });
                                    setValue('cnes', newValue?.cnes);
                                    setValue('cbo', '');
                                    setValue('cns', '');
                                  }}
                                  getOptionLabel={(option: any) =>
                                    option?.nome_fantasia
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      variant="outlined"
                                      label="Laboratório responsavél pelo Resultado"
                                      size="small"
                                      error={
                                        !!errors?.estabelecimentoSaudeId?.id
                                      }
                                      helperText={
                                        errors?.estabelecimentoSaudeId?.id
                                          ?.message
                                      }
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            </>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            label="Número do Protocolo do Exame no SISCAN"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            onChange={onChange}
                            value={value ? value : ''}
                            type="number"
                          />
                        )}
                        name="protocolo"
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>

            <Paper
              variant="outlined"
              sx={{ borderRadius: '10px', marginTop: '24px' }}
            >
              <Box p={3}>
                <Typography variant="h5" fontWeight="bold">
                  Profissional Responsável
                </Typography>
                <Box my={3}>
                  <Divider />
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="profissionalId"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Autocomplete
                            options={optionsResponsaveis}
                            value={value ? value : {}}
                            onChange={(_, newValue: any) => {
                              onChange(newValue ?? '');
                              setValue('cbo', newValue?.cbo);
                              setValue('cns', newValue?.cns);
                            }}
                            disabled={
                              disableInputResponsavel || !cnesEstabelecimento
                            }
                            getOptionLabel={(option: any) =>
                              option?.pessoa?.nome || ''
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                error={!!errors?.profissionalId?.id}
                                helperText={errors?.profissionalId?.id?.message}
                                variant="outlined"
                                label="Profissional Responsável pelo Resultado"
                                size="small"
                                fullWidth
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={control}
                      name="cbo"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <StyledAsteriskTextField
                            fullWidth
                            required
                            value={value ? value : ''}
                            label="CBO do Profissional"
                            size="small"
                            disabled
                            variant="outlined"
                            sx={{ backgroundColor: '#F0F0F0' }}
                          />
                        );
                      }}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={control}
                      name="cns"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <StyledAsteriskTextField
                            fullWidth
                            required
                            value={value ? value : ''}
                            label="CNS do Profissional"
                            size="small"
                            disabled
                            variant="outlined"
                            sx={{ backgroundColor: '#F0F0F0' }}
                          />
                        );
                      }}
                    ></Controller>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
          <p style={{ fontSize: '0.75rem', color: '#757575' }}>
            Adaptado da Terminologia IFCPC, Rio 2011
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '68px',
              borderRadius: 1,
            }}
            mb={3}
          >
            <Button
              sx={{
                marginTop: '10px',
                backgroundColor: '#17bebb',
              }}
              variant="contained"
              type="submit"
              startIcon={<CheckIcon />}
            >
              Cadastrar
            </Button>

            <Button
              sx={{ marginTop: '10px' }}
              variant="outlined"
              color="error"
              onClick={handleClickOpenDialogCancel}
              startIcon={<ClearIcon />}
            >
              Cancelar
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
