import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import api from '../../../api';
import { Inputs } from '../../../types/Citologia/types';
import { formatCitologia } from '../../../utils/FormatCitologia';
import { useStyles } from '../ViewExamStyle';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { Escolha, Motivo } from './Hpv';
import DownloadIcon from '@mui/icons-material/Download';
import { getUserToken } from '../../../lib/auth';
import AnmamneseForm from '../../RegisterExam/Anamnese';

const schema = Yup.object({
  dt_coleta: Yup.string().required('Campo Obrigatório'),
  dt_resultado: Yup.string().required('Campo Obrigatório'),
  rede_atendimento: Yup.boolean().required('Campo Obrigatório'),
  laboratorio_responsavel: Yup.string(),
  protocolo: Yup.string(),
  resultado: Yup.string().required('Campo Obrigatório'),
  escamosa_indeterminada: Yup.object({
    ASC_US: Yup.boolean(),
    ASC_H: Yup.boolean(),
  }),
  glandular_indeterminada: Yup.object({
    neoplasicas: Yup.boolean(),
    alto_grau: Yup.boolean(),
  }),
  origem_indefinida: Yup.object({
    neoplasicas: Yup.boolean(),
    alto_grau: Yup.boolean(),
  }),
  atipia_glandular: Yup.object({
    in_situ: Yup.boolean(),
    cervical: Yup.boolean(),
    endometrial: Yup.boolean(),
    outras: Yup.boolean(),
  }),
  atipia_escamosa: Yup.object({
    grau_I: Yup.boolean(),
    grau_II: Yup.boolean(),
    altograu: Yup.boolean(),
    carcinoma: Yup.boolean(),
  }),
  outra_neoplasia_maligna: Yup.boolean(),
  profissionalId: Yup.number(),
  estabelecimentoSaudeId: Yup.number(),
});

export const Citologia = ({ data, examId }) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const location = useLocation();
  const { id } = useParams();

  const [valueResponsavelName, setValueResponsavelName] = useState(
    data.responsavel_coleta?.pessoa?.nome
  );

  const [checkedMenstruacao, setCheckedMenstruacao] = useState(
    data.anamnese?.dt_menstruacao_desconhecida
  );

  const {
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    control,
    watch,
    clearErrors,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  const rhf = watch();

  const handleBackPage = () => {
    navigate(`/pacientes/${id}`);
  };

  const onSubmit = async (data: Inputs) => {
    if (data.rede_atendimento && !data.laboratorio_responsavel) {
      setError('laboratorio_responsavel', {
        type: 'custom',
        message: 'Campo Obrigatório',
      });
    }
    const state: any = location.state;

    formatCitologia(data);

    try {
      await api.post('/citologia', { ...data });
      navigate(`/pacientes/${state.patientData.id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  useEffect(() => {
    if (data.id) {
      setValue('anamnese', data.anamnese);
      setValue('dt_coleta', data.dt_coleta);
      setValue('rede_atendimento', data.rede_atendimento);
      setValue('laboratorio_responsavel', data.laboratorio_responsavel);
      setValue('protocolo', data.protocolo);
      setValue('dt_resultado', data.dt_resultado);
      setValue('resultado', data.resultado);

      setValue('clinico.dst', data.clinico.dst);
      // setValue('clinico.inspecao', data.clinico.inspecao);
      setValueResponsavelName(data.clinico?.responsavel_coleta?.pessoa?.nome);
    }
  }, [data, setValue]);
  const tokenCurrentUser = getUserToken();
  useEffect(() => {
    const getCurrentProfessional = async () => {
      try {
        const currentUser = await api.get(`/profissionais/`, {
          params: {
            cns: tokenCurrentUser.pessoa?.cns,
          },
        });

        setValue('responsavelId', currentUser.data.data[0].id);
        setValue('clinico.responsavel', { id: currentUser.data.data[0] });
      } catch (error) {}
    };

    getCurrentProfessional();
  }, []);

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },

    '& .MuiFormLabel-asterisk': {
      color: '#d32f2f',
    },
  };

  const generatePDF = async (storedExamDataId) => {
    try {
      const response = await api.get(
        `/reports/citologia/exame/${storedExamDataId}`,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  const storedExamDataId = examId;

  return (
    <Box mt={3}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          variant="outlined"
          onClick={() => generatePDF(storedExamDataId)}
          startIcon={<DownloadIcon />}
        >
          EXPORTAR
        </Button>
      </Box>
      <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
        <i>* Resposta obrigatória</i>
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <AnmamneseForm
          errors={errors}
          control={control}
          watch={watch}
          styles={styles}
          checkedMenstruacao={checkedMenstruacao}
          setCheckedMenstruacao={setCheckedMenstruacao}
          clearErrors={clearErrors}
          setValue={setValue}
          idPatient={id}
          exam={data}
          screenMode="view"
        />
        <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
          <Box p={3}>
            <Typography variant="h5" fontWeight="bold">
              Exame Clínico
            </Typography>

            <Box my={3}>
              <Divider />
            </Box>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name="clinico.inspecao"
                  render={({ field: { onChange, value } }) => {
                    const options = [
                      { label: 'Normal', value: 'NORMAL' },
                      {
                        label:
                          'Ausente (anomalias congênitas ou retirado cirurgicamente)',
                        value: 'AUSENTE',
                      },
                      {
                        label: 'Alterado',
                        value: 'ALTERADO',
                      },
                      {
                        label: 'Colo Não Visualizado',
                        value: 'NAO_VISUALIZADO',
                      },
                    ];
                    return (
                      <Autocomplete
                        options={options}
                        size="small"
                        sx={{ width: '100%' }}
                        fullWidth
                        disabled
                        value={options.find(
                          (item) => item.value === data.clinico?.inspecao
                        )}
                        onChange={(_, newValue) => {
                          onChange(newValue?.value ?? null);
                        }}
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            error={!!errors.clinico?.inspecao}
                            label="Inspeção do Colo"
                            fullWidth
                            disabled
                            helperText={errors.clinico?.inspecao?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6.5}>
                <Alert severity="warning">
                  Na presença de colo alterado, com{' '}
                  <b>lesão sugestiva de câncer</b>, não aguardar
                  <br /> o resultado do TESTE HPV para <b>encaminhar</b> a
                  mulher para <b>colposcopia.</b>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="clinico.dst"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <>
                        <FormLabel
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                          required
                          disabled
                          error={!!errors.clinico?.dst}
                        >
                          Sinais sugestivos de doenças sexualmente
                          transmissíveis?
                        </FormLabel>
                        <RadioGroup
                          row
                          onChange={(_, newValue) => {
                            if (newValue === 'true') {
                              onChange(true);
                            } else if (newValue === 'false') {
                              onChange(false);
                            } else if (newValue === undefined) {
                              onChange(null);
                            }
                          }}
                          value={value}
                        >
                          <FormControlLabel
                            value={true}
                            label="Sim"
                            disabled
                            control={
                              <Radio checked={data.clinico.dst === true} />
                            }
                          />
                          <FormControlLabel
                            value={false}
                            label="Não"
                            control={
                              <Radio checked={data.clinico.dst === false} />
                            }
                            disabled
                          />
                        </RadioGroup>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {errors.clinico?.dst?.message}
                        </FormHelperText>
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <Controller
                    control={control}
                    name="clinico.dt_coleta"
                    render={({ field: { onChange, value } }) => {
                      const splitDate = data.dt_coleta.split('-');
                      const defaultDate = `${splitDate[0]}-${
                        splitDate[1]
                      }-${String(Number(splitDate[2]) + 1).padStart(2, '0')}`;

                      return (
                        <DatePicker
                          onChange={(date) => {
                            onChange(date);
                            // setValue('dt_coleta', date);
                          }}
                          disabled
                          mask="__/__/____"
                          value={defaultDate}
                          renderInput={(params) => (
                            <StyledAsteriskTextField
                              required
                              {...params}
                              label="Data da coleta"
                              size="small"
                              variant="outlined"
                              className={styles.textField}
                              fullWidth
                              error={!!errors.clinico?.dt_coleta}
                              helperText={errors.clinico?.dt_coleta?.message}
                            />
                          )}
                        />
                      );
                    }}
                  />
                  <FormHelperText>{errors.dt_coleta?.message}</FormHelperText>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  disabled
                  value={valueResponsavelName ? valueResponsavelName : ''}
                  label="Profissional Responsável pela Coleta"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="clinico.local_coleta"
                  render={({ field: { onChange, value } }) => {
                    const options = [
                      {
                        label: 'Residência da Mulher',
                        value: 'RESIDENCIA',
                      },
                      { label: 'Serviço de Saúde', value: 'SERVICO_SAUDE' },
                      { label: 'Unidade Móvel', value: 'UNIDADE_MOVEL' },
                      { label: 'Outro Local', value: 'OUTRO' },
                    ];
                    return (
                      <Autocomplete
                        options={options}
                        size="small"
                        sx={{ width: '100%' }}
                        disabled
                        value={options.find(
                          (item) => item.value === data.clinico?.local_coleta
                        )}
                        fullWidth
                        onChange={(_, newValue) =>
                          onChange(newValue?.value ?? null)
                        }
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            label="Local da Coleta"
                            disabled
                            fullWidth
                            error={!!errors.clinico?.local_coleta}
                            helperText={errors.clinico?.local_coleta?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="clinico.outro_local_coleta"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        disabled
                        label="Outro Local"
                        size="small"
                        fullWidth
                        onChange={onChange}
                        value={data.clinico.outro_local_coleta}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="clinico.mtd_coleta"
                  render={({ field: { onChange, value } }) => {
                    const options = [
                      {
                        label: 'Convencional',
                        value: { id: 1, nome: 'Convencional' },
                      },
                      {
                        label: 'Meio Líquido',
                        value: { id: 2, nome: 'Meio Líquido' },
                      },
                    ];

                    return (
                      <Autocomplete
                        disabled
                        options={options}
                        size="small"
                        sx={{ width: '100%' }}
                        fullWidth
                        value={options.find(
                          (item) =>
                            item.value.nome === data.clinico?.mtd_coleta?.nome
                        )}
                        onChange={(_, newValue) =>
                          onChange(newValue?.value ?? null)
                        }
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            disabled
                            label="Método de Coleta de Citologia"
                            fullWidth
                            error={!!errors.clinico?.mtd_coleta}
                            helperText={errors.clinico?.mtd_coleta?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Box display="flex" justifyContent="space-between" marginTop="16px">
          <Button
            sx={{
              backgroundColor: '#17BEBB',
            }}
            variant="contained"
            onClick={handleBackPage}
          >
            Voltar
          </Button>
        </Box>
      </form>
    </Box>
  );
};
