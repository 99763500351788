/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import { useStyles } from './InviteUser.style';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { SideMenu } from '../../components/SideMenu';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import { useState } from 'react';
import { toast } from 'react-toastify';
import ReturnButton from '../../components/ReturnButton';
import api from '../../api';
import Template from '../../components/Template';

export function InviteEstablishment() {
  const styles = useStyles();
  let navigate = useNavigate();
  const [openDialogCancel, setOpenDialogCancel] = React.useState(false);
  const [payload, setPayload] = useState<any>({});

  const [loading] = useState(false);
  const [cnesInput, setCnesInput] = useState('');
  const { handleSubmit, control, setValue } = useForm();

  const handlePesquisar = async (cnesValue) => {
    try {
      const response = await api.get(
        `/estabelecimentos-saude/cnes/${cnesValue}`
      );

      if (response.data) {
        // na API: https://apidadosabertos.saude.gov.br/cnes/estabelecimentos/6703194
        // Alguns campos do formulário não existem
        // mantenho os campos, mas informando '———'

        setValue('nomeEstabelecimento', response?.data?.nome_fantasia || '———');
        setValue('nomeEmpresarial', response?.data?.razao_social || '———');
        setValue('naturezaJuridica', response?.data?.naturezaJuridica || '———');
        setValue('CNPJ', response?.data?.cnpj_proprio || '———');
        setValue('logradouro', response?.data?.endereco.logradouro || '———');
        setValue('numero', response?.data?.endereco.numero || '———');
        setValue('complemento', response?.data?.endereco.complemento || '———');
        setValue('bairro', response?.data?.endereco.bairro || '———');
        setValue('municipio', response?.data?.endereco.municipio.nome || '———');
        setValue('UF', response?.data?.endereco.municipio.estadoSigla || '———');
        setValue('CEP', response?.data?.endereco.cep || '———');
        setValue('telefone', response?.data?.telefone || '———');
        setValue('email', response?.data?.email || '———');
        setValue('regional', response?.data?.regional || '———'); // não tem na api
        setValue(
          'tipoEstabelecimento',
          response?.data?.tipoEstabelecimento || '———'
        ); // não tem na api
        setValue(
          'subtipoEstabelecimento',
          response?.data?.subtipoEstabelecimento || '———'
        ); // não tem na api
        setValue(
          'gestao',
          response?.data?.gestao
            ? response?.data?.gestao === 'M'
              ? 'Municipal'
              : response?.data?.gestao === 'E'
              ? 'Estadual'
              : response?.data?.gestao === 'F'
              ? 'Federal'
              : '———'
            : '———'
        );
        setPayload(response.data);
      }
    } catch (error) {
      toast.warning(
        () => (
          <>
            <p style={{ margin: '0' }}>
              Estabelecimento de Saúde com o CNES{' '}
              <b>{cnesValue} não encontrado.</b>
            </p>
            <p style={{ margin: '0' }}>
              Confira se o numero está correto, sem pontuações ou espaçamentos.
            </p>
          </>
        ),
        {
          position: 'bottom-left',
          style: { width: '500px' },
        }
      );
    }
  };

  const onSubmit = async (data) => {
    try {
      if (!payload) {
        console.error('Payload incompleto.');
        return;
      }

      // const response = await api.post('/estabelecimentos-saude', {
      //   ...payload,
      //   cnes: String(data.cnes),
      // });

      navigate('/gerenciar-estabelecimentos');

      toast.success(
        () => (
          <>
            <p style={{ margin: '0' }}>Estabelecimento cadastrado!</p>
            <p style={{ margin: '0' }}>CNES: {data.cnes}</p>
            <p style={{ margin: '0' }}>Nome: {payload.nome_fantasia}</p>
          </>
        ),
        {
          position: 'bottom-left',
          style: { width: '500px' },
        }
      );
    } catch (error) {
      console.error('Erro ao cadastrar estabelecimento:', error);

      toast.error('Erro ao cadastrar estabelecimento. Tente novamente.');
    }
  };

  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate('/gerenciar-estabelecimentos');
  };

  return (
    <SideMenu>
      <Container style={{ marginTop: '96px' }}>
        <ReturnButton backPage="/gerenciar-estabelecimentos" />

        <Box>
          <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
            <DialogTitle>Cancelar cadastro de estabelecimento ?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deseja realmente cancelar o cadastro do estabelecimento?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogCancel}>Não</Button>
              <Button onClick={handleCloseConfirmCancel} autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>

          {/* <Dialog open={openDialogSearch}>
            <DialogTitle>Atenção!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {requestError?.data.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogsearch}>Entendi</Button>
            </DialogActions>
          </Dialog> */}

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{ marginBottom: '5px' }}
              >
                Cadastrar Estabelecimentos de Saúde
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/gerenciar-estabelecimentos"
                >
                  Gerenciamento de Estabelecimentos de Saúde
                </Link>
                <Typography key="3" color="text.primary">
                  Cadastrar Estabelecimentos de Saúde
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
            <i>* Resposta obrigatória</i>
          </p>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Paper
              elevation={0}
              sx={{
                width: '100%',
                border: `1px solid RGBA(0, 0, 0, 0.23)`,
                borderRadius: '10px',
                marginTop: '24px',
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ margin: '25px', paddingTop: '10px' }}
              >
                Informações Gerais de Usuário
              </Typography>

              <Divider
                variant="middle"
                sx={{ width: '96%', margin: '0 auto' }}
              />

              <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={3}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          id="cns"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (typeof value === 'string') {
                              setCnesInput(value.replace(/[a-zA-Z]/g, ''));
                            }
                            onChange(e);
                          }}
                          value={
                            typeof value === 'string'
                              ? value.replace(/[a-zA-Z]/g, '')
                              : ''
                          }
                          label="CNES"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          //error={!!errors.CNES}
                          //helperText={errors.CNES?.message}
                          sx={{ width: '100%' }}
                          //onKeyDown={handleEnterCNES}
                        />
                      )}
                      name="cnes"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={2.5}>
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      style={{
                        backgroundColor: '#238884',
                        color: '#FFFFFF',
                        width: '100%',
                      }}
                      onClick={() => handlePesquisar(cnesInput)}
                    >
                      Pesquisar
                    </Button>
                  </Grid>
                  {loading ? (
                    <Grid item md={2.5} display="flex" alignItems="center">
                      <CircularProgress color="inherit" size={20} />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6.5} />
                  )}
                  <Grid item xs={12} md={9}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="nome"
                          label="Nome"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.nomeEstabelecimento}
                          //helperText={errors.nomeEstabelecimento?.message}
                        />
                      )}
                      name="nomeEstabelecimento" //Ajustar posteriormente
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4.5}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="Nome Empresarial"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.nomeEmpresarial}
                          //helperText={errors.nomeEmpresarial?.message}
                        />
                      )}
                      name="nomeEmpresarial"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4.5}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="Natureza Jurídica (Grupo)"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.naturezaJuridica}
                          //helperText={errors.naturezaJuridica?.message}
                        />
                      )}
                      name="naturezaJuridica"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="CNPJ"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.CNPJ}
                          //helperText={errors.CNPJ?.message}
                        />
                      )}
                      name="CNPJ"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="Logradouro"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.logradouro}
                          //helperText={errors.logradouro?.message}
                        />
                      )}
                      name="logradouro"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="Número"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.numero}
                          //helperText={errors.numero?.message}
                        />
                      )}
                      name="numero"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="Complemento"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.complemento}
                          //helperText={errors.complemento?.message}
                        />
                      )}
                      name="complemento"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="Bairro"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.bairro}
                          //helperText={errors.bairro?.message}
                        />
                      )}
                      name="bairro"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="Município"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.municipio}
                          //helperText={errors.municipio?.message}
                        />
                      )}
                      name="municipio"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="UF"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.UF}
                          //helperText={errors.UF?.message}
                        />
                      )}
                      name="UF"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="CEP"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.CEP}
                          //helperText={errors.CEP?.message}
                        />
                      )}
                      name="CEP"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="Telefone"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.telefone}
                          //helperText={errors.telefone?.message}
                        />
                      )}
                      name="telefone"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="email"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.dependencia}
                          //helperText={errors.dependencia?.message}
                        />
                      )}
                      name="email"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          id="email"
                          label="Regional de Saúde"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.regional}
                          //helperText={errors.regional?.message}
                        />
                      )}
                      name="regional"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled
                          fullWidth
                          size="small"
                          // id="cargo"
                          label="Gestão"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          //error={!!errors.tipoEstabelecimento}
                          //helperText={errors.tipoEstabelecimento?.message}
                        />
                      )}
                      name="gestao"
                      control={control}
                    />
                  </Grid>{' '}
                </Grid>
              </Box>
            </Paper>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 1,
              }}
              mb={3}
              mt={1}
            >
              <Button
                sx={{
                  marginTop: '10px',
                }}
                variant="contained"
                type="submit"
                startIcon={<CheckIcon />}
              >
                Cadastrar
              </Button>

              <Button
                sx={{ marginTop: '10px' }}
                variant="outlined"
                color="error"
                onClick={handleClickOpenDialogCancel}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </SideMenu>
  );
}
