import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TableUserManagements from '../../components/TableUserManagements/TableUserManagements';
import { ContainerChips, useStyles } from './UserManagement.style';
import { SideMenu } from '../../components/SideMenu';
import api from '../../api';
import { Link } from 'react-router-dom';
import ReturnButton from '../../components/ReturnButton';
import { getUserToken } from '../../lib/auth';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import { getUser } from '../../lib/getUser';
import { useEffect, useState } from 'react';
import Template from '../../components/Template';

interface ResponseData {
  ativos: string;
  count: number;
  data: [];
  inativos: string;
  page: number;
  pendentes: string;
  totalItems: number;
  totalPages: number;
}

interface User {
  id: number;
  email: string;
  nome_de_usuario: string;
  perfil: {
    status: number;
    descricao: string;
  };
  pessoa: {
    cns: string;
    nome: string;
    sexo: string;
    estabelecimento_saude?: {
      nome_fantasia: string;
    };
  };
}

interface UserSet {
  ativos: string;
  inativos: string;
  pendentes: string;
}

export function UserManagement() {
  const styles = useStyles();
  const [loading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [users, setUsers] = useState<any | ResponseData>([]);
  const [usersStatus, setUsersStatus] = useState({
    ativos: '',
    inativos: '',
    pendentes: '',
  });
  const [nome, setNome] = useState('');
  const [statesOptions, setStatesOptions] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [permissaoSelected, setPermissaoSelected] = useState<any>();

  const [estadoValue, setEstadoValue] = useState<any>();
  const [municipioValue, setMunicipioValue] = useState<any>();
  const [estabelecimentoValue, setEstabelecimentoValue] = useState<any>();
  const [statusValue, setStatusValue] = useState<any>();

  const [municipiosOptions, setMunicipiosOptions] = useState([]);
  const [estabelecimentosOptions, setEstabelecimentosOptions] = useState([]);
  const user = getUserToken();

  const [payloadFilter, setPayloadFilter] = useState<any>({
    page: 1,
    size: 25,
    estabelecimentoId:
      user.permissao_atual?.nome === 'Admin Estadual'
        ? user.estabelecimentoId
        : null,
  });

  // const getUsers = async (
  //   page?: number,
  //   size?: number,
  //   nome?: string,
  //   cidade?: string,
  //   estado?: string
  // ) => {
  //   const response = await api.get(
  //     `/usuarios?nome=${nome ?? ''}&page=${page ?? ''}&size=${
  //       size ?? ''
  //     }&cidade=${cidade ?? ''}&estado=${estado ?? ''}&mostrarTodos=todos`
  //   );

  //   setUsers(response.data);

  //   const responseData: ResponseData = response.data;
  //   const usersData = responseData.data.map((user: User) => {
  //     return {
  //       id: user.id,
  //       email: user.email,
  //       nome_de_usuario: user.nome_de_usuario,
  //       cns: user.pessoa.cns,
  //       nome: user.pessoa.nome,
  //       sexo: user.pessoa.sexo,
  //       status: user?.perfil?.status,
  //       descricao: user?.perfil?.descricao,
  //       estabelecimento: user?.pessoa?.estabelecimento_saude?.nome_fantasia,
  //     };
  //   });

  //   const { ativos, inativos, pendentes } = response.data as UserSet;
  //   setUsersStatus({
  //     ativos,
  //     inativos,
  //     pendentes,
  //   });
  // };

  // const searchUser = async () => {
  //     const response = await api.get('/usuarios', {
  //       params: payloadFilter,
  //     });
  //     setUsers(response.data);
  //     setUsersStatus({ ...response.data } as UserSet);
  //   };
  //useEffect(() => {
  //
  //   getUsers();
  // }, [payloadFilter]);

  const userMunicipal = user.permissao_atual?.nome === 'Admin Municipal';

  const userEstadual = user.permissao_atual?.nome === 'Admin Estadual';

  const handleSearch = async ({ clear, payloadCleared }: any) => {
    setLoadingTable(true);
    const response = await api.get('/usuarios', {
      params: clear ? payloadCleared : payloadFilter,
    });
    setUsers(response.data);
    setUsersStatus({ ...response.data } as UserSet);
    setLoadingTable(false);
  };

  const handleClear = () => {
    setPayloadFilter({
      page: 1,
      size: 25,
      cidade:
        user.permissao_atual?.nome === 'Admin Estadual'
          ? user.endereco?.municipio_nome
          : null,

      estabelecimentoId:
        user.permissao_atual?.nome === 'Admin Estadual'
          ? user.estabelecimentoId
          : null,
    });
    setEstadoValue(null);
    setMunicipioValue(null);
    setEstabelecimentoValue(null);
    setStatusValue(null);
    setPermissaoSelected(null);
    setNome('');
    handleSearch({
      clear: true,
      payloadCleared: {
        page: 1,
        size: 25,
        cidade:
          user.permissao_atual?.nome === 'Admin Municipal'
            ? user.endereco?.municipio_nome
            : null,

        estabelecimentoId:
          user.permissao_atual?.nome === 'Admin Estadual'
            ? user.estabelecimentoId
            : null,
      },
    });
  };

  const handleChangeState = async (newValue) => {
    setEstadoValue(newValue);
    if (newValue) {
      const responseMunicipios = await api.get('/municipios', {
        params: { sigla: newValue.sigla },
      });
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estado: newValue.sigla,
          cidade: null,
        };
      });
      setMunicipiosOptions(responseMunicipios.data);
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estado: null,
          cidade: null,
        };
      });
    }
  };

  const handleChangeMunicipio = async (newValue) => {
    setMunicipioValue(newValue);
    if (newValue) {
      const responseEstabelecimentos = await api.get(
        '/estabelecimentos-saude/unpaged',
        {
          params: { municipio: newValue.nome },
        }
      );
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estado: newValue.sigla,
          cidade: null,
        };
      });
      setEstabelecimentosOptions(responseEstabelecimentos.data.data);
      setPayloadFilter((prev) => {
        return {
          ...prev,
          cidade: newValue.nome,
        };
      });
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          cidade: null,
        };
      });
    }
  };

  const handleChangeStatusField = async (newValue) => {
    setStatusValue(newValue);
    if (newValue) {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          status: newValue.value,
        };
      });
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          status: null,
        };
      });
    }
  };

  const handleChangeEstabelecimento = async (newValue) => {
    setEstabelecimentoValue(newValue);
    if (newValue) {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estabelecimentoId: newValue.id,
        };
      });
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estabelecimentoId: null,
        };
      });
    }
  };

  const loadPermissoes = async () => {
    try {
      const response = await api.get('/permissoes');

      if (getUserToken().permissao_atual?.id === 6) {
        setPermissoes(
          response.data.filter(
            (permissao) => permissao.contexto === 'LABORATÓRIO'
          )
        );
      } else {
        setPermissoes(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    loadPermissoes();
  }, []);

  useEffect(() => {
    const getStates = async () => {
      const response = await api.get('/estados');
      if (response.status === 200) {
        setStatesOptions(response.data);
      }
    };
    getStates();
  }, []);

  const getUsers = async (payload) => {
    setLoadingTable(true);
    const response = await api.get('/usuarios', {
      params: payload,
    });
    setUsers(response.data);
    setUsersStatus({ ...response.data } as UserSet);

    setLoadingTable(false);
  };

  useEffect(() => {
    getUsers(payloadFilter);
  }, []);

  useEffect(() => {
    if (user.permissao_atual?.nome === 'Admin Municipal') {
      handleChangeMunicipio({
        nome: user.endereco?.municipio_nome,
        sigla: 'PE',
      });
      setPayloadFilter({
        page: 1,
        size: 25,
        estado: user.endereco?.estado,
        municipio: user.endereco?.municipio_nome,
      });
    }

    if (user.permissao_atual?.nome === 'Admin Estadual') {
      setPayloadFilter({
        page: 1,
        size: 25,
        estado: user.endereco?.estado,
        municipio: user.endereco?.municipio_nome,
        estabelecimentoId: user.estabelecimentoId,
      });
    }

    // console.log(user.permissao_atual);
  }, []);

  if (loading) {
    return <h1>Carregando...</h1>;
  } else {
    return (
      <SideMenu>
        <Container>
          <Box mt={12}>
            <ReturnButton backPage="/home" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography variant="h4" fontWeight="bold">
                  Gerenciamento de Usuários
                </Typography>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{ marginBottom: '16px', marginTop: '16px' }}
                  sx={{ marginBottom: '24px' }}
                >
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to="/home"
                  >
                    Home
                  </Link>
                  <Typography key="3" color="text.primary">
                    Gerenciamento de Usuários
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
            <Divider />
            <Box mt={4} mb={4}>
              <form>
                <Grid container spacing={2} justifyContent="end">
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      label="Pesquisar nome de usuário"
                      size="small"
                      variant="outlined"
                      className={styles.textField}
                      value={nome}
                      onChange={({ target }) => {
                        setNome(target.value);
                        if (target.value) {
                          setPayloadFilter((prev) => {
                            return { ...prev, nome: target.value };
                          });
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <Button
                            onClick={() => handleSearch({ clear: false })}
                            sx={{
                              marginRight: '1px',
                              backgroundColor: 'white',
                              zIndex: 1,
                            }}
                          >
                            <SearchIcon sx={{ color: 'gray' }} />
                          </Button>
                        ),
                      }}
                    />
                  </Grid>
                  {userMunicipal || userEstadual ? (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={3}>
                        <TextField
                          label="Estado"
                          size="small"
                          fullWidth
                          disabled
                          InputLabelProps={{ shrink: true }}
                          value={user.endereco?.estado}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={3}>
                        <TextField
                          label="Município"
                          size="small"
                          fullWidth
                          disabled
                          InputLabelProps={{ shrink: true }}
                          value={user.endereco?.municipio_nome}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={3}>
                        <Autocomplete
                          options={statesOptions}
                          disabled={userMunicipal || userEstadual}
                          renderOption={(props, option: any) => (
                            <li {...props}>{option.nome}</li>
                          )}
                          value={estadoValue ? estadoValue : null}
                          onChange={(e, newValue) => {
                            handleChangeState(newValue);
                          }}
                          getOptionLabel={(option) => option.nome}
                          isOptionEqualToValue={(option) => option.nome}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Estado"
                              size="small"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={3}>
                        <Autocomplete
                          disabled={userMunicipal || userEstadual}
                          options={municipiosOptions}
                          value={municipioValue ? municipioValue : null}
                          getOptionLabel={(option: any) => option.nome}
                          onChange={(e, newValue) => {
                            handleChangeMunicipio(newValue);
                          }}
                          renderOption={(props, option: any) => (
                            <li {...props}>{option.nome}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Município"
                              size="small"
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}

                  {userEstadual ? (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={3}>
                        <TextField
                          label="Estabelecimento Vinculado"
                          size="small"
                          fullWidth
                          disabled
                          InputLabelProps={{ shrink: true }}
                          value={user.estabelecimento_saude?.nome_fantasia}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={3}>
                      <Autocomplete
                        options={estabelecimentosOptions}
                        value={
                          estabelecimentoValue ? estabelecimentoValue : null
                        }
                        getOptionLabel={(option: any) => option.nome_fantasia}
                        onChange={(e, newValue) => {
                          handleChangeEstabelecimento(newValue);
                        }}
                        renderOption={(props, option: any) => (
                          <li {...props}>{option.nome_fantasia}</li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Estabelecimento Vinculado"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {user.permissao_atual?.nome === 'SuperAdmin' && (
                    <>
                      {' '}
                      <Grid item xs={12} sm={12} md={12} lg={3}>
                        <Autocomplete
                          value={permissaoSelected ? permissaoSelected : null}
                          onChange={(_, newValue) => {
                            if (newValue) {
                              setPermissaoSelected(newValue);
                              setPayloadFilter((prev) => {
                                return {
                                  ...prev,
                                  permissaoId: newValue?.id,
                                  permissaoContexto: newValue?.contexto,
                                };
                              });
                            } else {
                              setPermissaoSelected(null);
                              setPayloadFilter((prev) => {
                                return {
                                  ...prev,
                                  permissaoId: null,
                                  permissaoContexto: null,
                                };
                              });
                            }
                          }}
                          options={
                            permissoes
                              ? permissoes
                              : [
                                  {
                                    id: 0,
                                    descricao: '',
                                    contexto: '',
                                    label: 'Não há opções',
                                  },
                                ]
                          }
                          getOptionLabel={(option: any) =>
                            `${option.contexto} - ${option.nome}`
                          }
                          renderInput={(params) => (
                            <StyledAsteriskTextField
                              {...params}
                              // required={getValues().status[indexer]}
                              fullWidth
                              size="small"
                              label="Perfil do Usuário"
                              variant="outlined"
                              className={styles.textField}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Autocomplete
                      options={[
                        { label: 'Ativo', value: '0' },
                        { label: 'Inativo', value: '1' },
                        { label: 'Pendente', value: '2' },
                      ]}
                      value={statusValue ? statusValue : null}
                      getOptionLabel={(option: any) => option.label}
                      onChange={(e, newValue) => {
                        handleChangeStatusField(newValue);
                      }}
                      renderOption={(props, option: any) => (
                        <li {...props}>{option.label}</li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Status" size="small" />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={9}
                    display="flex"
                    gap="16px"
                    justifyContent="end"
                  >
                    <Button
                      onClick={() => handleSearch({ clear: false })}
                      sx={{ padding: '8px 22px' }}
                      variant="contained"
                    >
                      Filtrar
                    </Button>
                    <Button
                      sx={{ padding: '8px 22px' }}
                      variant="outlined"
                      color="error"
                      onClick={() => handleClear()}
                    >
                      Limpar
                    </Button>
                  </Grid>
                  {/* <Grid item xs={12} lg={4.5}></Grid> */}
                </Grid>
              </form>

              <ContainerChips>
                <Box
                  component="div"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography style={{ fontSize: '13px' }}>
                    Usuários Ativos
                  </Typography>
                  <Chip
                    color="success"
                    id="ativos"
                    style={{ margin: '0px 35px 0px 10px' }}
                    label={usersStatus.ativos}
                  />
                </Box>
                <Box
                  component="div"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography style={{ fontSize: '13px' }}>
                    Usuários Pendentes
                  </Typography>
                  <Chip
                    color="warning"
                    label={usersStatus.pendentes}
                    style={{ margin: '0px 35px 0px 10px' }}
                  />
                </Box>
                <Box
                  component="div"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography style={{ fontSize: '13px' }}>
                    Usuários Inativos
                  </Typography>
                  <Chip
                    color="error"
                    label={usersStatus.inativos}
                    style={{ margin: '0px 35px 0px 10px' }}
                  />
                </Box>
              </ContainerChips>
            </Box>
            <TableUserManagements
              loadingTable={loadingTable}
              setLoadingTable={setLoadingTable}
              payloadFilter={payloadFilter}
              getUsers={getUsers}
              list={users}
              setPayloadFilter={setPayloadFilter}
              //list={{ data: users }}
              nome={nome}
            />
          </Box>
        </Container>
      </SideMenu>
    );
  }
}
