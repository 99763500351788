/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Fragment, useEffect, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import { useStyles } from './InviteUser.style';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import { SideMenu } from '../../components/SideMenu';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import { schema } from './schema';
import { returnCpfValidation } from '../../utils/validateCPF';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import ReturnButton from '../../components/ReturnButton';
import { createUser } from '../../lib/inviteUser';
import { EstabelecimentoSaude } from '../RegisterPatient/types';
import { getUserToken } from '../../lib/auth';
import AlertInfo from '../../assets/imgs/AlertInfo.svg';
import Template from '../../components/Template';

interface Inputs {
  cpf: string;
  email: string;
  nome_de_usuario: string;
  nome_completo: string;
  estabelecimento_funcao: any;

  uf: any;
  municipio: any;
  perfil: any;
  cargo: any;
  status: any;
}

export interface RootObject {
  id: number;
  created_at: string;
  updated_at: string;
  nome: string;
  apelido: string;
  cpf: string;
  dt_nascimento: string;
  cns: string;
  raca: string;
  nacionalidade: string;
  nome_mae: string;
  sexo: string;
  contato_preferencial: string;
  escolaridade: string;
  orientacao: number;
  identidade_genero: number;
  usuario: Usuario;
  estabelecimento_saude?: any;
}

export interface Usuario {
  id: number;
  created_at: string;
  updated_at: string;
  nome_de_usuario: string;
  email: string;
}

export interface Perfil {
  id?: number;
  descricao;
  estabelecimento: EstabelecimentoSaude;
  usuarioId: number;
  status: 0 | 1 | 2;
  permissao: Permissao;
}
export interface Permissao {
  id?: number;
  nome: string;
  descricao: string;
  contexto: string;
}

export function InviteUser() {
  // const loading = open && options.length === 0;

  const styles = useStyles();
  let navigate = useNavigate();
  const location = useLocation();

  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [CPF, setCPF] = useState<any>('');
  const [disableAllFields, setDisableAllFields] = useState(true);
  const [permissoesSelect, setPermissoesSelect] = useState<Permissao[]>([]);
  const [perfis, setPerfis] = useState<Perfil[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
    setError,
    clearErrors,
    setFocus,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  // const [requestError, setRequestError] = useState<any>();

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {}, [watch().status]);

  async function load() {
    await api
      .get('/permissoes', {
        params: {
          contexto: getUserToken().permissao_atual?.contexto,
        },
      })
      .then((res) => setPermissoesSelect(res.data));
  }

  const clearValues = () => {
    setValue('nome_de_usuario', '');
    setValue('nome_completo', '');
    setValue('email', '');
    setValue('perfil', []);
    setValue('status', []);
    setPerfis([]);
    setDisableAllFields(true);
  };

  const searchCPFInDatabase = async () => {
    setIsLoading(false);
    const cpfIsValid = returnCpfValidation(getValues);

    if (cpfIsValid) {
      try {
        const findedUser = await api.get('/usuarios', {
          params: {
            cpf: CPF.replace(/[-.]/g, ''),
          },
        });
        const findedPeople = await api.get('/profissionais', {
          params: {
            cpf: CPF.replace(/[-.]/g, ''),
          },
        });

        if (findedUser?.data?.data?.length) {
          clearValues();
          toast.error('Já existe um usuário com este CPF.');
          return;
        }
        if (findedPeople?.data?.data?.length) {
          setDisableAllFields(false);

          clearErrors('nome_completo');
          clearErrors('estabelecimento_funcao');
          clearErrors('email');
          clearErrors('nome_de_usuario');

          setValue('email', findedPeople.data.data[0]?.pessoa.usuario?.email);
          setValue(
            'nome_de_usuario',
            findedPeople.data.data[0].pessoa.apelido ||
              (findedPeople.data.data[0].pessoa.nome + ' ')
                .split(' ')[0]
                .slice(0, 20)
          );
          setValue(
            'nome_completo',
            findedPeople.data.data[0].pessoa.nome ?? ''
          );
          setValue(
            'email',
            findedPeople.data.data[0].pessoa?.contatos?.filter(
              (item) => item.forma_contato === 'EMAIL'
            )?.[0]?.descricao
          );

          setPerfis(
            findedPeople.data.data[0].estabelecimentos?.map((est, index) => {
              const indexer = est.estabelecimento.id;
              setValue(`status.${indexer}`, true);
              setValue(`cargo.${indexer}`, ''); //findedPeople.data.data[0].cargo);
              return {
                descricao: '',
                estabelecimento: est.estabelecimento,
                status: 2,
                permissao: undefined,
              };
            })
          );
        } else {
          clearValues();
          toast.warn('Nenhum profissional encontrado com este CPF');
        }
      } catch (error) {}
    } else {
      setError('cpf', { message: 'Digite um CPF válido' });
    }
  };

  const validateCpfWhenUserTypeElevenNumbers = () => {
    const cpfIsValid = returnCpfValidation(getValues);

    const CpfWithoutChar = CPF.replace(/[-.]/g, '');

    const setOrClearErrorsOnCpfInput = () => {
      cpfIsValid === false
        ? setError('cpf', { type: 'manual', message: 'CPF inválido' })
        : clearErrors('cpf');
    };

    if (CpfWithoutChar.length === 11) setOrClearErrorsOnCpfInput();
  };

  const onSubmit = async (data: Inputs) => {
    const cpfIsValid = returnCpfValidation(getValues);
    if (!cpfIsValid) {
      toast.error('CPF Inválido');
      return;
    }
    if (!data.status.some((s) => s)) {
      toast.error('Ative ao menos um Estabelecimento');
      return;
    }
    setIsLoading(true);

    const perfs = perfis
      .map((perf) => {
        const indexer = perf.estabelecimento.id;
        if (!data.status[indexer]) return undefined;
        return {
          ...perf,
          status: data.status[indexer],
          permissao: data.perfil[indexer],
          descricao: data.cargo[indexer] || data.perfil[indexer]?.descricao,
        };
      })
      .filter((p) => p);

    const payload = {
      perfis: perfs,
      cpf: data.cpf?.replace(/[-.]/g, ''),
      email: data.email,
      nome_de_usuario: data.nome_de_usuario,
    };

    //return setIsLoading(false);
    const createdUser = await createUser(payload);
    if (createdUser?.success === true) {
      toast.success(createdUser?.message, {
        position: 'bottom-right',
      });
      navigate('/usuarios');
    } else {
      toast.error(
        createdUser?.error?.message ||
          'Erro ao criar usuário, contacte um administrador',
        {
          position: 'bottom-right',
        }
      );
    }
    setIsLoading(false);
    return;
  };

  const handleOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCancelInviteUser = () => {
    navigate('/usuarios');
  };

  useEffect(() => {
    if (CPF) {
      validateCpfWhenUserTypeElevenNumbers();
    }
  }, [CPF]);

  return (
    <SideMenu>
      <Container sx={{ marginTop: '96px' }}>
        <ReturnButton backPage="/usuarios" />

        <Box>
          <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
            <DialogTitle>Cancelar cadastro de usuário ?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deseja realmente cancelar o cadastro de usuário ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogCancel}>Não</Button>
              <Button onClick={handleCancelInviteUser} autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{ marginBottom: '5px' }}
              >
                Cadastro de Usuários
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/usuarios"
                >
                  Gerenciamento de Usuários
                </Link>
                <Typography key="3" color="text.primary">
                  Cadastro de Usuários
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
            <i>* Resposta obrigatória</i>
          </p>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Paper
              elevation={0}
              sx={{
                width: '100%',
                border: `1px solid RGBA(0, 0, 0, 0.23)`,
                borderRadius: '10px',
                marginTop: '24px',
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ margin: '25px', paddingTop: '10px' }}
              >
                Informações Gerais de Usuário
              </Typography>

              <Divider
                variant="middle"
                sx={{ width: '96%', margin: '0 auto' }}
              />

              <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={8} md={4}>
                    <Controller
                      render={({ field }) => (
                        <StyledAsteriskTextField
                          required
                          id="cns"
                          onChange={(e) => {
                            const cpf = e.target.value.replace(/_/g, ''); // Remover os "_"
                            setCPF(cpf);
                            field.onChange(e.target.value);
                          }}
                          value={field.value ? field.value : ''}
                          label="CPF"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          error={!!errors.cpf}
                          helperText={errors.cpf?.message}
                          sx={{ width: '100%' }}
                          InputProps={{
                            autoComplete: 'off',
                            inputComponent: MaskedInput as any,
                            inputProps: {
                              mask: [
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                              ],
                            } as MaskedInputProps,
                          }}
                        />
                      )}
                      name="cpf"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2.5}>
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      style={{
                        backgroundColor: '#238884',
                        color: '#FFFFFF',
                        width: '100%',
                      }}
                      onClick={searchCPFInDatabase}
                    >
                      Pesquisar
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled={disableAllFields}
                          aria-readonly="true"
                          fullWidth
                          id="nome"
                          label="Nome Completo do Profissional de Saúde"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          //onChange={onChange}
                          value={value ? value : ''}
                          helperText={errors.nome_completo?.message}
                          InputProps={{
                            autoComplete: 'off',
                          }}
                        />
                      )}
                      name="nome_completo" //Ajustar posteriormente
                      control={control}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          disabled={disableAllFields}
                          required
                          fullWidth
                          id="email"
                          label="E-mail"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                          InputProps={{
                            autoComplete: 'off',
                          }}
                        />
                      )}
                      name="email"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Paper
              elevation={0}
              sx={{
                width: '100%',
                border: `1px solid RGBA(0, 0, 0, 0.23)`,
                borderRadius: '10px',
                marginTop: '24px',
              }}
            >
              <Grid item>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  style={{ margin: '25px', paddingTop: '10px' }}
                >
                  Estabelecimento{perfis?.length > 1 ? 's' : ''} Vinculado
                  <Tooltip
                    title={`Para adicionar mais um estabelecimento ao usuário, abra a tela “Cadastro de Profissional de Saúde”`}
                    placement="top"
                  >
                    <img
                      src={AlertInfo}
                      alt=""
                      style={{ marginLeft: '13px' }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
              <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                <Grid container>
                  <Grid
                    container
                    // key={estabelecimento.id}
                    spacing={2}
                    my={1}
                  >
                    {perfis.length > 0
                      ? perfis?.map((perfil, index) => {
                          const indexer = perfil.estabelecimento.id;
                          return (
                            <Fragment key={perfil.estabelecimento.id}>
                              <Divider
                                variant="middle"
                                sx={{
                                  width: '96%',
                                  margin: '2rem auto 1rem',
                                }}
                              />
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                key={perfil.estabelecimento.id}
                              >
                                <Grid
                                  item
                                  xs={1}
                                  md={0.5}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Controller
                                    name={`status.${indexer}`}
                                    control={control}
                                    render={({
                                      field: { onChange, value, ...rest },
                                    }) => (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={() => {
                                              onChange(!value);
                                            }}
                                            checked={value}
                                            {...rest}
                                          />
                                        }
                                        label={''}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  container
                                  item
                                  spacing={2}
                                  xs={11}
                                  md={11.5}
                                >
                                  <Grid item xs={5} md={3}>
                                    <Autocomplete
                                      readOnly={true}
                                      disabled={!getValues().status[indexer]}
                                      placeholder="UF"
                                      className={styles.noSelectClamp}
                                      value={
                                        perfil.estabelecimento.endereco
                                          ?.municipio?.estadoSigla
                                      }
                                      options={[
                                        perfil.estabelecimento.endereco
                                          ?.municipio?.estadoSigla,
                                      ]}
                                      renderInput={(params) => (
                                        <StyledAsteriskTextField
                                          {...params}
                                          required={getValues().status[indexer]}
                                          fullWidth
                                          size="small"
                                          label="UF"
                                          variant="outlined"
                                          className={styles.textField}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={7} md={3}>
                                    <Autocomplete
                                      readOnly={true}
                                      disabled={!getValues().status[indexer]}
                                      placeholder="Municipio"
                                      className={styles.noSelectClamp}
                                      value={
                                        perfil.estabelecimento.endereco
                                          ?.municipio?.nome
                                      }
                                      options={[
                                        perfil.estabelecimento.endereco
                                          ?.municipio?.nome,
                                      ]}
                                      renderInput={(params) => (
                                        <StyledAsteriskTextField
                                          {...params}
                                          required={getValues().status[indexer]}
                                          fullWidth
                                          size="small"
                                          label="Municipio"
                                          variant="outlined"
                                          className={styles.textField}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    onClick={() =>
                                      toast.warn(
                                        'Somente Alterável na Edição De Profissional'
                                      )
                                    }
                                  >
                                    <Autocomplete
                                      readOnly={true}
                                      disabled={!getValues().status[indexer]}
                                      placeholder="Estabelecimento de Saúde"
                                      className={styles.noSelectClamp}
                                      value={
                                        perfil.estabelecimento?.nome_fantasia
                                      }
                                      options={[
                                        perfil.estabelecimento?.nome_fantasia,
                                      ]}
                                      renderInput={(params) => (
                                        <StyledAsteriskTextField
                                          {...params}
                                          required={getValues().status[indexer]}
                                          fullWidth
                                          size="small"
                                          label="Estabelecimento de Saúde"
                                          variant="outlined"
                                          className={styles.textField}
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid item xs={12} md={6}>
                                    <Controller
                                      name={`perfil.${indexer}`}
                                      control={control}
                                      render={({
                                        field: { onChange, value },
                                      }) => {
                                        return (
                                          <Autocomplete
                                            placeholder="Perfil"
                                            disabled={
                                              !getValues().status[indexer]
                                            }
                                            value={value ? value : null}
                                            onChange={(_, newValue) => {
                                              newValue &&
                                                setValue(
                                                  `cargo.${indexer}`,
                                                  newValue.descricao
                                                );
                                              onChange(
                                                newValue
                                                  ? newValue
                                                  : location.state?.sla ||
                                                      undefined //todo mudar quando for editar
                                              );
                                              setFocus(`cargo.${indexer}`, {
                                                shouldSelect: true,
                                              });
                                            }}
                                            options={
                                              permissoesSelect
                                                ? permissoesSelect
                                                : [
                                                    {
                                                      id: 0,
                                                      descricao: '',
                                                      contexto: '',
                                                      label: 'Não há opções',
                                                    },
                                                  ]
                                            }
                                            getOptionLabel={(option) =>
                                              `${option.contexto} - ${option.nome}`
                                            }
                                            renderInput={(params) => (
                                              <StyledAsteriskTextField
                                                {...params}
                                                required={
                                                  getValues().status[indexer]
                                                }
                                                fullWidth
                                                size="small"
                                                label="Perfil"
                                                variant="outlined"
                                                className={styles.textField}
                                              />
                                            )}
                                          />
                                        );
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Controller
                                      name={`cargo.${indexer}`}
                                      control={control}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <StyledAsteriskTextField
                                          disabled={
                                            disableAllFields ||
                                            !getValues().perfil[indexer]?.id ||
                                            !getValues().status[indexer]
                                          }
                                          fullWidth
                                          id={`cargo.${indexer}`}
                                          label="Cargo"
                                          size="small"
                                          variant="outlined"
                                          onChange={onChange}
                                          value={value ? value : ''}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Fragment>
                          );
                        })
                      : undefined}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 1,
              }}
              mb={3}
              mt={1}
            >
              <Button
                sx={{
                  marginTop: '10px',
                  backgroundColor: '#238884',
                }}
                variant="contained"
                type="submit"
                startIcon={<CheckIcon />}
                disabled={isLoading}
              >
                Convidar
              </Button>

              <Button
                sx={{ marginTop: '10px' }}
                variant="outlined"
                color="error"
                onClick={handleOpenDialogCancel}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </SideMenu>
  );
}
