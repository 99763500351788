/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Radio,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  RadioGroup,
  TextField,
  Typography,
  Checkbox,
  Button,
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { SideMenu } from '../../components/SideMenu';
import { Controller, useForm } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useStyles } from '../RegisterExam/RegisterExam.style';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReturnButton from '../../components/ReturnButton';
import api from '../../api';
import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CitologiaReflexo from '../RegisterExam/Exams/CitologiaReflexo';
import moment from 'moment';
import { getUserToken } from '../../lib/auth';
import Template from '../../components/Template';

const RegisterResult = () => {
  const [openDialogCancel, setOpenDialogCancel] = useState<boolean>(false);
  const [optionsResponsaveis, setOptionsResponsaveis] = useState([]);
  const [disableInputResponsavel, setDisableInputResponsavel] = useState(false);

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },

    '& .MuiFormLabel-asterisk': {
      color: 'red',
    },
  };

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const rhf = watch();
  const styles = useStyles();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    data.profissionalId = data.profissionalId.id;
    data.dt_resultado = moment(data.dt_resultado).format('YYYY-MM-DD');
    try {
      await api.post(
        `/citologia/${location.state.examData.citologia.id}/resultado`,
        data
      );

      navigate(location.state.url);
    } catch (error) {}
  };

  const location = useLocation();
  const hasResult = location?.state?.resultData;

  const generatePDF = async () => {
    try {
      const response = await api.get(
        `/reports/citologia/exame/resultado/${hasResult.id}`,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  const getOptionsProfissionais = async () => {
    const response = await api.get('/profissionais/cbo/list', {
      params: { cboType: 'MÉDICO', estabelecimentoId: '1474' },
    });

    const currentUser = getUserToken();

    const userLoggedIsMedicoLaboratorio = response.data.find(
      (responsavel) => responsavel?.pessoa?.id === currentUser?.pessoa?.id
    );

    if (!!userLoggedIsMedicoLaboratorio) {
      setValue('profissionalId', userLoggedIsMedicoLaboratorio);
      setDisableInputResponsavel(true);
    }

    setOptionsResponsaveis(response.data);
  };

  const examIsReflexo =
    location?.state?.examData?.citologia?.tipo === 'Reflexo' ? true : false;

  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate(`/pacientes/${location.state.examData.pessoa.id}`);
  };

  const outrasNeoplasias = watch('outras_neoplasias_malignas');
  useEffect(() => {
    if (outrasNeoplasias === true) {
      setValue('atipiaEscamosa', null);
      setValue('atipiaGlandular', null);
    }
  }, [outrasNeoplasias]);

  useEffect(() => {
    getOptionsProfissionais();
  }, []);

  useEffect(() => {
    if (hasResult) {
      setValue('rede_atendimento', hasResult.rede_atendimento);
      setValue('dt_resultado', new Date(hasResult.dt_resultado + 'T00:00:00'));
      setValue('resultado', hasResult.resultado);
      setValue(
        'atipiaEscamosaIndeterminada',
        hasResult.atipiaEscamosaIndeterminada
      );
      setValue(
        'atipiaGlandularIndeterminada',
        hasResult.atipiaGlandularIndeterminada
      );
      setValue('atipiaOrigemIndefinida', hasResult?.atipiaOrigemIndefinida);
      setValue('atipiaGlandular', hasResult?.atipiaGlandular);
      setValue('atipiaEscamosa', hasResult?.atipiaEscamosa);
      setValue('profissionalId', hasResult?.profissional?.pessoa?.nome);
      setValue('cbo', hasResult?.profissional?.cbo);
      setValue('cns', hasResult?.profissional?.cns);
      setValue('protocolo_siscan', hasResult?.protocolo_siscan);
      setValue(
        'outras_neoplasias_malignas',
        hasResult.outras_neoplasias_malignas
      );
    }
  }, []);

  useEffect(() => {
    setValue('tipo', 'Rastreio');
    setValue('cnes_laboratorio', '2712105');
    setValue('rede_atendimento', 'Rede SUS');
    setValue(
      'laboratorio_responsavel',
      'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA'
    );
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <SideMenu>
      <Container>
        {examIsReflexo ? (
          <>
            <Box my={3} marginTop="96px">
              <ReturnButton backPage={location?.state?.url} />

              <Typography variant="h4" fontWeight="bold">
                {examIsReflexo
                  ? 'Resultado da Citologia - Reflexo (após teste de HPV Positivo)'
                  : 'Resultado do Teste de Citologia - Como Rastreio'}
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
              >
                <Link
                  key="1"
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/pacientes"
                >
                  Listagem de Pacientes
                </Link>
                <Link
                  key="2"
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to={location?.state?.url}
                >
                  Ficha Individual da Paciente
                </Link>

                <Typography key="3" color="text.primary">
                  {examIsReflexo
                    ? 'Resultado da Citologia - Reflexo (após teste de HPV Positivo)'
                    : 'Resultado do Teste de Citologia - Como Rastreio'}
                </Typography>
              </Breadcrumbs>

              <CitologiaReflexo
                view
                location={{
                  state: {
                    ...location.state,
                    patientData: { ...location.state.examData.pessoa },
                  },
                }}
                data={location.state}
              />
            </Box>
          </>
        ) : (
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box my={3}>
              <ReturnButton backPage={location?.state?.url} />

              <div>
                <Typography variant="h4" fontWeight="bold">
                  {examIsReflexo
                    ? 'Resultado da Citologia - Reflexo (após teste de HPV Positivo)'
                    : 'Resultado do Teste de Citologia - Como Rastreio'}
                </Typography>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{ marginBottom: '16px', marginTop: '16px' }}
                >
                  <Link
                    key="1"
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to="/pacientes"
                  >
                    Listagem de Pacientes
                  </Link>
                  <Link
                    key="2"
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to={location?.state?.url}
                  >
                    Ficha Individual da Paciente
                  </Link>

                  <Typography key="3" color="text.primary">
                    {examIsReflexo
                      ? 'Resultado da Citologia - Reflexo (após teste de HPV Positivo)'
                      : 'Resultado do Teste de Citologia - Como Rastreio'}
                  </Typography>
                </Breadcrumbs>

                {/* <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  startIcon={<ContentPasteIcon />}
                  // onClick={handleOpenHpvList}
                  variant="contained"
                >
                  VISUALIZAR EXAME: TESTE DE HPV
                </Button>
                <Button
                  variant="outlined"
                  // onClick={() => generatePDF()}
                  startIcon={<DownloadIcon />}
                >
                  EXPORTAR
                </Button>
              </Box> */}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: examIsReflexo
                      ? 'space-between'
                      : 'flex-end',
                    width: '100%',
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => generatePDF()}
                    startIcon={<DownloadIcon />}
                  >
                    EXPORTAR
                  </Button>
                </div>
              </div>
              <p
                style={{
                  fontSize: '12px',
                  textAlign: 'right',
                  color: '#d32f2f',
                }}
              >
                <i>* Resposta obrigatória</i>
              </p>
              <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                <Box p={3}>
                  <Typography variant="h5" fontWeight="bold">
                    Identificação do Laboratório
                  </Typography>

                  <Box my={3}>
                    <Divider />
                  </Box>

                  <FormControl>
                    <FormLabel
                      required
                      sx={styleFormLabel}
                      id="rede-atendimento"
                      error={!!errors.rede_atendimento}
                      disabled={hasResult}
                    >
                      Rede de Atendimento
                    </FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup onChange={onChange} value={'Rede SUS'} row>
                          <FormControlLabel
                            value="Rede SUS"
                            control={<Radio />}
                            label="Rede SUS"
                            disabled
                          />
                          <FormControlLabel
                            value="Saúde Suplementar"
                            control={<Radio />}
                            label="Saúde suplementar"
                            disabled
                          />
                        </RadioGroup>
                      )}
                      name="rede_atendimento"
                      control={control}
                    />
                    <FormHelperText>
                      {errors?.rede_atendimento?.message}
                    </FormHelperText>
                  </FormControl>

                  <Box mt={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          label="CNES do Laboratório"
                          size="small"
                          value="2712105"
                          variant="outlined"
                          className={styles.textField}
                          onChange={() => {}}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <StyledAsteriskTextField
                              fullWidth
                              required
                              disabled
                              label="Laboratório Responsável pelo Laudo"
                              size="small"
                              variant="outlined"
                              name="laboratorio_responsavel"
                              sx={{ backgroundColor: '#F0F0F0' }}
                              onChange={onChange}
                              value={
                                'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA'
                              }
                              error={!!errors?.laboratorio_responsavel}
                              helperText={
                                errors?.laboratorio_responsavel?.message
                              }
                            />
                          )}
                          name="laboratorio_responsavel"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Número do Protocolo do Exame no SISCAN"
                              size="small"
                              disabled={!!hasResult}
                              variant="outlined"
                              className={styles.textField}
                              onChange={onChange}
                              type="number"
                              value={value ? value : ''}
                            />
                          )}
                          name="protocolo_siscan"
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <Box my={3}>
              <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                <Box p={3}>
                  <Typography variant="h5" fontWeight="bold">
                    Resultado do Exame
                  </Typography>

                  <Box my={3}>
                    <Divider />
                  </Box>

                  <FormControl error={!!errors?.dt_coleta && true}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ptBR}
                    >
                      <div>
                        <Controller
                          control={control}
                          name="dt_resultado"
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              onChange={onChange}
                              label="Data do Resultado"
                              mask="__/__/____"
                              disabled={hasResult}
                              value={value ? value : null}
                              renderInput={(params) => (
                                <StyledAsteriskTextField
                                  required
                                  {...params}
                                  style={{ marginTop: '20px' }}
                                  size="small"
                                  variant="outlined"
                                  className={styles.textField}
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        />
                        <FormHelperText>
                          {errors?.dt_resultado?.message}
                        </FormHelperText>
                      </div>
                    </LocalizationProvider>
                  </FormControl>

                  <Box mt={3}>
                    <FormControl error={!!errors?.resultado}>
                      <FormLabel required sx={styleFormLabel} id="resultado">
                        Resultado
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            value={value ? value : ''}
                            row
                          >
                            <FormControlLabel
                              value="Negativo"
                              control={<Radio />}
                              label="Negativo"
                              disabled={hasResult}
                            />
                            <FormControlLabel
                              value="Alterações Epiteliais"
                              control={<Radio />}
                              label="Com Alterações Epiteliais"
                              disabled={hasResult}
                            />
                          </RadioGroup>
                        )}
                        name="resultado"
                        control={control}
                      />
                      <FormHelperText>
                        {errors?.resultado?.message}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  {rhf.resultado === 'Alterações Epiteliais' && (
                    <Box mt={3}>
                      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                        <Box p={3}>
                          <Typography variant="body1" color="text.secondary">
                            Células Atípicas de Significado Indeterminado
                          </Typography>

                          <Box my={3}>
                            <Divider />
                          </Box>

                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                Escamosas:
                              </Typography>

                              <Controller
                                name="atipiaEscamosaIndeterminada"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <RadioGroup
                                      onChange={(_, newValue) => {
                                        onChange({ id: Number(newValue) });
                                      }}
                                      value={value}
                                      row
                                    >
                                      <FormControlLabel
                                        value={1}
                                        control={
                                          <Radio checked={value?.id === 1} />
                                        }
                                        label="Possivelmente não neoplásicas (ASC-US)"
                                        disabled={hasResult}
                                      />
                                      <FormControlLabel
                                        value={2}
                                        control={
                                          <Radio checked={value?.id === 2} />
                                        }
                                        label="Não se pode afastar lesão de alto grau (ASC-H)"
                                        disabled={hasResult}
                                      />
                                    </RadioGroup>
                                  );
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                Glandulares:
                              </Typography>
                              <Controller
                                name="atipiaGlandularIndeterminada"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <RadioGroup
                                      onChange={(_, newValue) => {
                                        onChange({ id: Number(newValue) });
                                      }}
                                      value={value ? value : ''}
                                      row
                                    >
                                      <FormControlLabel
                                        value={1}
                                        control={
                                          <Radio checked={value?.id === 1} />
                                        }
                                        label="Possivelmente não neoplásicas"
                                        disabled={hasResult}
                                      />
                                      <FormControlLabel
                                        value={2}
                                        control={
                                          <Radio checked={value?.id === 2} />
                                        }
                                        disabled={hasResult}
                                        label="Não se pode afastar lesão de alto grau"
                                      />
                                    </RadioGroup>
                                  );
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                De origem indefinida:
                              </Typography>

                              <Controller
                                name="atipiaOrigemIndefinida"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <RadioGroup
                                      onChange={(_, newValue) => {
                                        onChange({ id: Number(newValue) });
                                      }}
                                      value={value ? value : ''}
                                      row
                                    >
                                      <FormControlLabel
                                        value={1}
                                        control={
                                          <Radio checked={value?.id === 1} />
                                        }
                                        label="Possivelmente não neoplásicas"
                                        disabled={hasResult}
                                      />
                                      <FormControlLabel
                                        value={2}
                                        control={
                                          <Radio checked={value?.id === 2} />
                                        }
                                        label="Não se pode afastar lesão de alto grau"
                                        disabled={hasResult}
                                      />
                                    </RadioGroup>
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>

                      <Paper
                        variant="outlined"
                        sx={{ borderRadius: '10px', marginTop: '24px' }}
                      >
                        <Box p={3}>
                          <Grid item xs={12}>
                            <Typography variant="body1" color="text.secondary">
                              Atipias em Células Escamosas
                            </Typography>
                            <Box my={3}>
                              <Divider />
                            </Box>

                            <Controller
                              name="atipiaEscamosa"
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <RadioGroup
                                    onChange={(_, newValue) => {
                                      onChange({ id: Number(newValue) });
                                    }}
                                    value={value ? value : ''}
                                  >
                                    <FormControlLabel
                                      value={1}
                                      control={
                                        <Radio checked={value?.id === 1} />
                                      }
                                      label="Lesão intraepitelial de baixo grau (compreendendo o efeito citopático pelo HPV e noplasia intraepitelial cervical grau I"
                                      disabled={hasResult}
                                    />
                                    <FormControlLabel
                                      value={2}
                                      control={
                                        <Radio checked={value?.id === 2} />
                                      }
                                      label="Lesão intraepitelial de alto grau (compreendendo neoplasias intraepiteliais cervicais grau II e III"
                                      disabled={hasResult}
                                    />
                                    <FormControlLabel
                                      value={3}
                                      control={
                                        <Radio checked={value?.id === 3} />
                                      }
                                      label="Lesão intraepitelial de alto grau, não podendo excluir microinvasão"
                                      disabled={hasResult}
                                    />
                                    <FormControlLabel
                                      value={4}
                                      control={
                                        <Radio checked={value?.id === 4} />
                                      }
                                      label="Carcinoma epidermóide invasor"
                                      disabled={hasResult}
                                    />
                                  </RadioGroup>
                                );
                              }}
                            />
                            {/* <FormGroup>
                            <Controller
                              name="atipia_escamosa.grau_I"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={<Radio {...field} />}
                                  label="Lesão intraepitelial de baixo grau (compreendendo o efeito citopático pelo HPV e noplasia intraepitelial cervical grau I"
                                />
                              )}
                            />
                            <Controller
                              name="atipia_escamosa.grau_II"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={<Radio {...field} />}
                                  label="Lesão intraepitelial de alto grau (compreendendo neoplasias intraepiteliais cervicais grau II e III"
                                />
                              )}
                            />
                            <Controller
                              name="atipia_escamosa.alto_grau"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={<Radio {...field} />}
                                  label="Lesão intraepitelial de alto grau, não podendo excluir microinvasão"
                                />
                              )}
                            />
                            <Controller
                              name="atipia_escamosa.carcinoma"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={<Radio {...field} />}
                                  label="Carcinoma epidermóide invasor"
                                />
                              )}
                            />
                          </FormGroup> */}
                          </Grid>
                        </Box>
                      </Paper>

                      <Paper
                        variant="outlined"
                        sx={{
                          borderRadius: '10px',
                          marginTop: '24px',
                          marginBottom: '24px',
                        }}
                      >
                        <Box p={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                Atipias em Células Glandulares
                              </Typography>
                              <Box my={3}>
                                <Divider />
                              </Box>

                              <Controller
                                name="atipiaGlandular"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <RadioGroup
                                      onChange={(_, newValue) => {
                                        onChange({ id: Number(newValue) });
                                      }}
                                      value={value ? value : ''}
                                    >
                                      <FormControlLabel
                                        value={1}
                                        control={
                                          <Radio checked={value?.id === 1} />
                                        }
                                        label="Adenocarcinoma ‘in situ’"
                                        disabled={hasResult}
                                      />
                                      <FormControlLabel
                                        value={2}
                                        control={
                                          <Radio checked={value?.id === 2} />
                                        }
                                        label="Adenocarcinoma invasor cervical"
                                        disabled={hasResult}
                                      />
                                      <FormControlLabel
                                        value={3}
                                        control={
                                          <Radio checked={value?.id === 3} />
                                        }
                                        label="Adenocarcinoma invasor endometrial"
                                        disabled={hasResult}
                                      />
                                      <FormControlLabel
                                        value={4}
                                        control={
                                          <Radio checked={value?.id === 4} />
                                        }
                                        label="Adenocarcinoma invasor em outras especificações"
                                        disabled={hasResult}
                                      />
                                    </RadioGroup>
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                      <Grid item xs={12}>
                        <Controller
                          name="outras_neoplasias_malignas"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={onChange}
                                    value={value}
                                    checked={value}
                                  />
                                }
                                disabled={hasResult}
                                label="Outras Neoplasias Malignas"
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Paper>
              <Paper
                variant="outlined"
                sx={{ borderRadius: '10px', marginTop: '24px' }}
              >
                <Box p={3}>
                  <Typography variant="h5" fontWeight="bold">
                    Profissional Responsável
                  </Typography>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="profissionalId"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              {!!hasResult ? (
                                <TextField
                                  required
                                  disabled
                                  value={value ? value : ''}
                                  variant="outlined"
                                  label="Profissional Responsável pelo Laudo"
                                  size="small"
                                  fullWidth
                                />
                              ) : (
                                <Autocomplete
                                  options={optionsResponsaveis}
                                  value={value}
                                  onChange={(_, newValue) => {
                                    onChange(newValue);
                                    setValue('cbo', newValue?.cbo);
                                    setValue('cns', newValue?.cns);
                                  }}
                                  disabled={
                                    disableInputResponsavel || !!hasResult
                                  }
                                  sx={{
                                    backgroundColor: !!hasResult
                                      ? '#F0F0F0'
                                      : '',
                                  }}
                                  getOptionLabel={(option: any) =>
                                    option?.pessoa?.nome
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      variant="outlined"
                                      label="Profissional Responsável pelo Laudo"
                                      size="small"
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            </>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        control={control}
                        name="cbo"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <StyledAsteriskTextField
                              fullWidth
                              required
                              value={value ? value : ''}
                              label="CBO do Profissional"
                              size="small"
                              disabled
                              variant="outlined"
                              sx={{ backgroundColor: '#F0F0F0' }}
                            />
                          );
                        }}
                      ></Controller>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        control={control}
                        name="cns"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <StyledAsteriskTextField
                              fullWidth
                              required
                              value={value ? value : ''}
                              label="CNS do Profissional"
                              size="small"
                              disabled
                              variant="outlined"
                              sx={{ backgroundColor: '#F0F0F0' }}
                            />
                          );
                        }}
                      ></Controller>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '14px',
                borderRadius: 1,
              }}
              mb={3}
            >
              {hasResult ? (
                <Button
                  sx={{
                    marginTop: '10px',
                    backgroundColor: '#17bebb',
                  }}
                  variant="contained"
                  type="button"
                  onClick={() => navigate(location.state.url)}
                  startIcon={<CheckIcon />}
                >
                  Voltar
                </Button>
              ) : (
                <>
                  <Button
                    sx={{
                      marginTop: '10px',
                      backgroundColor: '#238884',
                    }}
                    variant="contained"
                    type="submit"
                    startIcon={<CheckIcon />}
                  >
                    Cadastrar
                  </Button>

                  <Button
                    sx={{ marginTop: '10px' }}
                    variant="outlined"
                    color="error"
                    onClick={handleClickOpenDialogCancel}
                    startIcon={<ClearIcon />}
                  >
                    Cancelar
                  </Button>
                </>
              )}
            </Box>
          </form>
        )}
        <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
          <DialogTitle>Cancelar Cadastro de exame</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deseja realmente cancelar o cadastro de exame ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogCancel}>Não</Button>
            <Button onClick={handleCloseConfirmCancel} autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Box mt={12}></Box>
      </Container>
    </SideMenu>
  );
};

export default RegisterResult;
