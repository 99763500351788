import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { SideMenu } from '../../components/SideMenu';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import TableEstablismentsManagements from '../../components/TableEstablismentsManagements';
import ReturnButton from '../../components/ReturnButton';
import api from '../../api';
import Template from '../../components/Template';

const ManageEstablishments = () => {
  const [loading] = React.useState(false);
  const [statesOptions, setStatesOptions] = React.useState<any>([]);
  const [municipiosOptions, setMunicipiosOptions] = React.useState([]);

  const [establishmentList, setEstablishmentList] = React.useState<any>({});
  const [usersStatus, setEstabelecimentosStatus] = React.useState({
    ativos: '',
    inativos: '',
    //  pendentes: '',
  });
  const [nome, setNome] = React.useState('');
  const [payloadFilter, setPayloadFilter] = React.useState({});

  const handleChangeState = async (newValue) => {
    if (newValue) {
      const responseMunicipios = await api.get('/municipios', {
        params: { sigla: newValue.sigla },
      });
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estado: newValue.sigla,
        };
      });
      setMunicipiosOptions(responseMunicipios.data);
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estado: null,
        };
      });
    }
  };

  const handleChangeMunicipio = async (newValue) => {
    if (newValue) {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          municipio: newValue.nome,
        };
      });
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          municipio: null,
        };
      });
    }
  };

  const handleFilterEstablishments = async () => {
    const response = await api.get('/estabelecimentos-saude', {
      params: payloadFilter,
    });

    setEstablishmentList(response.data);
  };

  React.useEffect(() => {
    const getStates = async () => {
      const response = await api.get('/estados');
      if (response.status === 200) {
        setStatesOptions(response.data);
      }
    };
    getStates();
  }, []);

  React.useEffect(() => {
    const getEstablishments = async () => {
      const response = await api.get('/estabelecimentos-saude', {
        params: { size: 5 },
      });
      setEstablishmentList(response.data);

      setEstabelecimentosStatus({
        ativos: response.data.ativo,
        inativos: response.data.inativos,
      });
    };

    getEstablishments();
  }, []);

  if (loading) {
    return <h1>Carregando...</h1>;
  } else {
    return (
      <SideMenu>
        <Container>
          <Box mt={12}>
            <ReturnButton backPage="/home" />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography variant="h4" fontWeight="bold">
                  Gerenciamento de Estabelecimentos de Saúde
                </Typography>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{ marginBottom: '16px', marginTop: '16px' }}
                  sx={{ marginBottom: '24px' }}
                >
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to="/home"
                  >
                    Home
                  </Link>
                  <Typography key="3" color="text.primary">
                    Gerenciamento de Estabelecimentos de Saúde
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>

            <Divider />
            <Box mt={4} mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    sx={{
                      width: '100%',
                      '.MuiOutlinedInput-root': { paddingRight: 0 },
                    }}
                    label="Pesquise pelo nome ou CNES do Estabelecimento de Saúde"
                    size="small"
                    variant="outlined"
                    value={nome}
                    onChange={({ target }) => {
                      setNome(target.value);
                      if (!target.value) {
                        setPayloadFilter((prev) => {
                          return {
                            ...prev,
                            cnes: null,
                            nome: null,
                          };
                        });
                      }
                      if (target.value && !Number.isNaN(Number(target.value))) {
                        setPayloadFilter((prev) => {
                          return { ...prev, cnes: target.value, nome: null };
                        });
                      } else {
                        setPayloadFilter((prev) => {
                          return { ...prev, nome: target.value, cnes: null };
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <Button
                          sx={{
                            marginRight: '1px',
                            backgroundColor: 'white',
                            zIndex: 1,
                          }}
                        >
                          <SearchIcon sx={{ color: 'gray' }} />
                        </Button>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={statesOptions}
                    renderOption={(props, option: any) => (
                      <li {...props}>{option.nome}</li>
                    )}
                    onChange={(e, newValue) => {
                      handleChangeState(newValue);
                    }}
                    getOptionLabel={(option) => option.nome}
                    isOptionEqualToValue={(option) => option.nome}
                    renderInput={(params) => (
                      <TextField {...params} label="Estado" size="small" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={municipiosOptions}
                    getOptionLabel={(option: any) => option.nome}
                    onChange={(e, newValue) => {
                      handleChangeMunicipio(newValue);
                    }}
                    renderOption={(props, option: any) => (
                      <li {...props}>{option.nome}</li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Município" size="small" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={[]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de Estabelecimento"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <Box>
                    <Button
                      variant="contained"
                      sx={{ marginRight: '24px' }}
                      onClick={handleFilterEstablishments}
                    >
                      Filtrar
                    </Button>
                    <Button variant="outlined" color="error">
                      Limpar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} mt={3} sx={{ marginLeft: '0px' }}>
                <Box
                  component="div"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography style={{ fontSize: '13px' }}>
                    Estabelecimentos Ativos
                  </Typography>
                  <Chip
                    color="success"
                    id="ativo"
                    style={{ margin: '0px 35px 0px 10px' }}
                    label={usersStatus.ativos}
                  />
                </Box>
                <Box
                  component="div"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography style={{ fontSize: '13px' }}>
                    Estabelecimentos Inativos
                  </Typography>
                  <Chip
                    color="error"
                    id="inativo"
                    label={usersStatus.inativos}
                    style={{ margin: '0px 35px 0px 10px' }}
                  />
                </Box>
              </Grid>
            </Box>
            <TableEstablismentsManagements
              list={establishmentList}
              setEstablishmentList={setEstablishmentList}
              nome={nome}
              filter={payloadFilter}
            />
          </Box>
        </Container>
      </SideMenu>
    );
  }
};

export default ManageEstablishments;
