import { Box, Container, Divider, Paper, Typography } from '@mui/material';
import { SideMenu } from '../../../components/SideMenu';
import ReturnButton from '../../../components/ReturnButton';

import FiltersReportsConvocatoria from './FiltersReportsConvocatoria';
// import TableReports from '../../../components/TableReports/TableHpv/TableReportsHpv';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import TableReportsConvocatoria from '../../../components/TableReports/TableConvocatoria/TableReportConvocatoria';
import Template from '../../../components/Template';

export const ReportsConvocatoria = () => {
  const [tableData, setTableData] = useState<any[]>([]);

  const { control, handleSubmit, setValue, watch, setError } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: '30px' }}
          >
            Relatório da Convocatória
          </Typography>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />
            <p
              style={{
                fontSize: '12px',
                textAlign: 'right',
                color: '#d32f2f',
                margin: '0',
                marginTop: '20px',
                // marginBottom: '-20px',
              }}
            >
              <i>* Campo obrigatório</i>
            </p>

            <FiltersReportsConvocatoria
              setTableData={setTableData}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              watch={watch}
              setError={setError}
            />

            <TableReportsConvocatoria
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};

export default ReportsConvocatoria;
