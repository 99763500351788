import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { SelectWithSearch } from '../../../../components/SelectWithSearch';
import SearchIcon from '@mui/icons-material/Search';

import { useContext, useEffect, useState } from 'react';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';
import useActions from '../../../../components/MultipleFilters/hooks/useActions';
import useSetPayload from './hooks/useSetPayload';
import useRequestFilter from './hooks/useRequestFilter';
import { PerfilContext } from '../../../../context/PerfilContext';
import useHandleChangeFilters from './hooks/useHandleChangesFilters';
import useClearValues from './hooks/useClearValues';
import api from '../../../../api';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import MultipleFilters from '../../../../components/MultipleFilters';
import { Controller } from 'react-hook-form';
import { origemExame } from '../../../../mocks/origem-exame';
import {
  caraterBenigno,
  escamosa,
  glandular,
} from '../../../../mocks/lesoes-mock';
import { getUserToken } from '../../../../lib/auth';

const FiltersReportsHistopatologia = ({
  clearValuesInputs,
  estadosSelected,
  setEstadosSelected,
  setTableData,
  setShowTableData,
  municipiosSelected,
  setMunicipiosSelected,
  estabelecimentosSelected,
  setEstabelecimentosSelected,
  periodDtResultado,
  periodDtColeta,
  setPeriodDtColeta,
  setPeriodDtResultado,
  control,
  handleSubmit,
  setValue,
  disableInputEstado,
  setSelectedResultado,
  setDataColetaFinal,
  setDataColetaIni,
  setDataResultadoIni,
  setDataResultadoFinal,
  dtColetaIni,
  dtColetaFinal,
  selectedResultado,
  watch,
  setError,
  showTableData,
  listFilter,
  setListFilter,
  setListFilterShow,
  dtResultadoIni,
  dtResultadoFinal,
  setSelectedOrigem,
  setSelectedCarater,
  setSelectedEscamosa,
  setSelectedGlandular,
  selectedGlandular,
  selectedEscamosa,
  selectedCarater,
  selectedOrigem,

  disableMunicipioInput,
  disableEstabelecimentoInput,
  selectedOptionsLaboratorio,
  setSelectedOptionsLaboratorio,
  disabledLaboratorioResultado,
  setDisabledLaboratorioResultado,

  disabledProfissionalResultado,
  selectedLaboratorio,
  setSelectedLaboratorio,
  clearErrors,
  setSelectedHasLesao,
  selectedHasLesao,
  errors,
  setLoadingTable,
  initialStateListFilter,
}) => {
  const [disableOtherInputs, setDisableOtherInputs] = useState(true);

  const [, setFirstLoadPage] = useState(true);

  // const [responsaveis, setResponsaveis] = useState<any>([]);

  const [selectedOptionsOrigem, setSelectedOptionsOrigem] = useState<any[]>([]);
  const [selectedOptionsCarater, setSelectedOptionsCarater] = useState<any[]>(
    []
  );
  const [selectedOptionsEscamosa, setSelectedOptionsEscamosa] = useState<any[]>(
    []
  );
  const [selectedOptionsGlandular, setSelectedOptionsGlandular] = useState<
    any[]
  >([]);

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setSearchAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,
    setLoadingEstados,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,
    errorsFields,
    optionsLaboratorios,

    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    setErrorsFields,
    setOptionsLaboratorios,
    setAllLaboratoriosOptions,
    optionsProfissionaisLaboratorio,
    setOptionsProfisisonaisLaboratorio,
  } = useStatesFields();

  const { validationSubmit } = useActions();

  //   const hange = (event) => {
  //     setProfissional(event.target.value);
  //   };

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    selectedResultado,
    dtColetaFinal,
    dtColetaIni,
    dtResultadoIni,
    dtResultadoFinal,
    selectedGlandular,
    selectedEscamosa,
    selectedCarater,
    selectedOrigem,
    selectedLaboratorio,
  });

  const { loadReportsHistopatologia } = useRequestFilter({
    setTableData,
    setLoadingTable,
  });
  const { getPermissao } = useContext(PerfilContext);
  const { handleSetFilter } = useHandleChangeFilters({ setListFilter });
  const permissao = getPermissao();

  const { clearValues } = useClearValues({
    permissao,
    setValue,
    handleSetFilter,
    watch,
    loadReportsHistopatologia,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    showTableData,
    setShowTableData,
    setTableData,
    setErrorsFields,
    setListFilterShow,
    // setSelectedResultado,
    setDataColetaFinal,
    setDataColetaIni,
    setSelectedOptionsOrigem,
    setSelectedOptionsCarater,
    setSelectedOptionsEscamosa,
    setSelectedOptionsGlandular,
    setDataResultadoFinal,
    setDataResultadoIni,
    // setPayload,
    setSelectedOrigem,
    setSelectedCarater,
    setSelectedEscamosa,
    setSelectedGlandular,
    setSelectedLaboratorio,
    setSelectedOptionsLaboratorio,
    setSelectedHasLesao,
    initialStateListFilter,
  });
  const fnWhenFormIsValid = async () => {
    setTableData([]);

    setListFilterShow(listFilter);

    setShowTableData(true);

    await setPayload().then((payload) => {
      try {
        loadReportsHistopatologia({ payload });
      } catch (error) {}
    });
  };

  const handleSetSelectedOptions =
    ({ setSelected, description, fieldName }) =>
    (options) => {
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${description}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${description}: Vários`;
        }
      };
      handleSetFilter(optionLabel(), fieldName);
    };

  const onSubmit = async () => {
    validationSubmit({
      fnWhenFormIsValid,
      estadosSelected,
      municipiosSelected,
      estabelecimentosSelected,
      setErrorsFields,
      setDisableOtherInputs,
      watch,
      setError,
      clearErrors,
    });
  };

  const handleSetPeriodDtColeta = (e) => {
    setPeriodDtColeta(e.target.value);
    setDataColetaFinal(null);
    setDataColetaIni(null);
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_coleta_inicio');
    });
    handleSetFilter('', 'dt_coleta_inicio');
    handleSetFilter('', 'dt_coleta_fim');
  };
  const handleSetPeriodDtResultado = (e) => {
    setPeriodDtResultado(e.target.value);
    setDataResultadoFinal(null);
    setDataResultadoIni(null);
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_resultado_inicio');
    });
    handleSetFilter('', 'dt_resultado_inicio');
    handleSetFilter('', 'dt_resultado_fim');
  };

  const isLaboratorio =
    getUserToken().permissao_atual?.contexto === 'LABORATÓRIO';

  const isMedicoLaboratorio =
    isLaboratorio && getUserToken().permissao_atual?.id === 7;

  useEffect(() => {
    // const state: any = location.state;
    async function loadResponsaveis() {
      const arrayLaboratorios = selectedLaboratorio?.map((lab) => lab.value);

      const response = await api.get(
        '/dashboard/exames/histopatologia/panel/profissionais-resultado',
        {
          params: {
            estados:
              estadosSelected.length > 0
                ? estadosSelected.join(', ')
                : undefined,
            municipios:
              municipiosSelected?.length > 0
                ? municipiosSelected.join(', ')
                : undefined,
            laboratorioId:
              arrayLaboratorios?.length > 0
                ? arrayLaboratorios.join(', ')
                : undefined,
            periodo_inicio: dtColetaIni
              ? moment(dtColetaIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : undefined,
            periodo_fim: dtColetaFinal
              ? moment(dtColetaFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : undefined,
          },
        }
      );

      const profissionais = response.data.data.map((profissional) => {
        return {
          idprofissional: profissional.prof_id,
          nomeprofissional: `${profissional.pessoa.nome}`,
          label: `${profissional.pessoa.nome}`,
        };
      });

      setOptionsProfisisonaisLaboratorio(profissionais);

      // if (isAdminMunicipal) {
      //   const user: any = getUserToken();
      //   const idMunicipio = user.estabelecimento_saude?.endereco?.municipio?.id;
      //
      //   setResponsaveis(profissionais.data);
      // } else {
      //
      //   setResponsaveis(profissionais.data);
      // }
    }
    // 7
    const { permissao_atual, estabelecimentoId, pessoa } = getUserToken();

    const loadMedicoLaboratorio = async () => {
      const getProfissional = await api.get(
        `/profissionais/estabelecimento/${estabelecimentoId}`
      );

      const profissional = getProfissional.data.find(
        (prof) => prof.idpessoa === pessoa.id
      );

      const objProfissional = {
        idprofissional: profissional.idprofissional,
        nomeprofissional: `${profissional.nomeprofissional}`,
        label: `${profissional.label}`,
      };
      setOptionsProfisisonaisLaboratorio([objProfissional]);

      setValue('profissionalLaudoId', objProfissional);
      setValue('profissional_responsavel_resultado', {
        ...objProfissional,
        label: objProfissional.nomeprofissional,
      });

      // const profissional = {
      //   idprofissional: getProfissional.prof_id,
      //   nomeprofissional: `${getProfissional.pessoa.nome}`,
      //   label: `${getProfissional.pessoa.nome}`,
      // };
    };

    if (
      permissao_atual?.id === 7 &&
      permissao_atual?.contexto === 'LABORATÓRIO'
    ) {
      loadMedicoLaboratorio();
      return;
    }
    if (selectedLaboratorio?.length === 1) {
      loadResponsaveis();
    }
  }, [selectedLaboratorio]);

  // useEffect(() => {
  //   if (
  //     (optionsMunicipios?.length > 0 &&
  //       municipiosSelected?.length === optionsMunicipios?.length) ||
  //     municipiosSelected?.length > 0
  //   ) {
  //     setDisableOtherInputs(false);
  //   }
  // }, [optionsMunicipios, municipiosSelected]);

  // useEffect(() => {
  //   if (
  //     selectedLaboratorio?.length === 0 ||
  //     selectedOptionsLaboratorio?.length === 0
  //   ) {
  //     setValue('profissionalLaudoId', []);
  //     setResponsaveis([]);
  //   }
  // }, [selectedLaboratorio, selectedOptionsLaboratorio]);

  const searchInput = watch('search');

  useEffect(() => {
    if (!searchInput) {
      clearErrors('search');
    }
  }, [searchInput]);

  const [errorInputDateColeta, setErrorInputDateColeta] = useState<any>({});

  useEffect(() => {
    const dtIni = moment(dtColetaIni, 'DD-MM-YYYY');
    const dtFin = moment(dtColetaFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDateColeta({
        fin: 'Data final deve ser maior que a data inicial',
        ini: '',
      });
    } else {
      setErrorInputDateColeta('');
    }
  }, [dtColetaIni, dtColetaFinal]);

  const [errorInputDateResultado, setErrorInputDateResultado] = useState<any>(
    {}
  );

  useEffect(() => {
    const dtIni = moment(dtResultadoIni, 'DD-MM-YYYY');
    const dtFin = moment(dtResultadoFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDateResultado({
        fin: 'Data final deve ser maior que a data inicial',
        ini: '',
      });
    } else {
      setErrorInputDateResultado('');
    }
  }, [dtResultadoIni, dtResultadoFinal]);

  // useEffect(() => {
  //   if (
  //     (optionsMunicipios?.length > 0 &&
  //       municipiosSelected?.length === optionsMunicipios?.length) ||
  //     municipiosSelected?.length > 0
  //   ) {
  //     setDisableOtherInputs(false);
  //   }
  // }, [optionsMunicipios, municipiosSelected]);

  useEffect(() => {
    const loadEstados = async () => {
      // setLoadingEstados(true);
      try {
        const response = await api.get('/estados/histopatologia');
        const estados = response.data;
        setEstadosOptions(estados);
        setAllEstadosOptions(estados);
        setLoadingEstados(false);
      } catch (error) {
        setLoadingEstados(false);

        console.error('Erro ao carregar estados:', error);
        // setEstadosLoadError(true);
      }
    };

    loadEstados();
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <MultipleFilters
          requiredMunicipio={estadosSelected.length > 1 ? false : true}
          setFirstLoadPage={setFirstLoadPage}
          setOptionsLaboratorios={setOptionsLaboratorios}
          setAllLaboratoriosOptions={setAllLaboratoriosOptions}
          routeGetEstabelecimentos="/dashboard/exames/histopatologia/estabelecimentos"
          disableInputEstado={disableInputEstado}
          disableMunicipioInput={disableMunicipioInput}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          handleSetFilter={handleSetFilter}
          // laboratorioField
          mdEstado={4}
          mdMunicipio={4}
          mdEstabelecimento={4}
          mdEquipe={3}
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
          setDisableOtherInputs={setDisableOtherInputs}
          clearValuesInputs={clearValuesInputs}
        />
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ marginTop: '16px' }}>
          <SelectWithSearch
            options={[
              {
                label: 'Sim',
                value: 'true',
              },
              { label: 'Não', value: 'false' },
            ]}
            label="Realizou Tratamento das Lesões Intraepiteliais"
            disabled={disableOtherInputs}
            selectedOptions={selectedHasLesao}
            setSelectedOptions={setSelectedHasLesao}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedHasLesao,
              description: 'Realizou Tratamento das Lesões Intraepiteliais',
              fieldName: 'realizou_tratamento',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
            flexWrap="wrap"
          >
            <Typography fontWeight="bold">Data da Coleta:</Typography>

            <FormControl>
              <RadioGroup
                aria-labelledby="handleSetPeriod"
                name="handleSetPeriod"
                value={periodDtColeta}
                onChange={handleSetPeriodDtColeta}
                row
              >
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  label="Filtrar por Data"
                />
                <FormControlLabel
                  value="period"
                  control={<Radio />}
                  label="Filtrar por período"
                />
              </RadioGroup>
            </FormControl>

            {periodDtColeta === 'period' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex="1"
                flexWrap="wrap"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    onChange={(date) => {
                      handleSetFilter(
                        'Início da Data da Coleta:' +
                          moment(date).format('DD/MM/YYYY'),
                        'dt_coleta_inicio'
                      );
                      setDataColetaIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                    }}
                    disabled={disableOtherInputs}
                    maxDate={new Date()}
                    value={
                      dtColetaIni && moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Início da Data da Coleta"
                        size="small"
                        variant="outlined"
                        sx={{ minWidth: '200px' }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    onChange={(date) => {
                      handleSetFilter(
                        'Fim da Data da Coleta:' +
                          moment(date).format('DD/MM/YYYY'),
                        'dt_coleta_fim'
                      );
                      setDataColetaFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                    }}
                    disabled={disableOtherInputs}
                    maxDate={new Date()}
                    value={
                      dtColetaFinal &&
                      moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtColetaFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Fim da Data da Coleta"
                        size="small"
                        error={!!errorInputDateColeta.fin}
                        helperText={errorInputDateColeta?.fin}
                        variant="outlined"
                        sx={{
                          minWidth: '200px',
                          marginBottom: !!errorInputDateColeta.fin
                            ? '-20px'
                            : '',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {periodDtColeta === 'date' && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    data-testid="datePicker"
                    onChange={(date) => {
                      setDataColetaIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      setDataColetaFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      handleSetFilter(
                        'Data da Coleta:' + moment(date).format('DD-MM-YYYY'),
                        'dt_coleta_fim'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtColetaIni && moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Data da Coleta"
                        size="small"
                        variant="outlined"
                        sx={{ minWidth: '200px', flex: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={origemExame}
            label="Origem"
            disabled={disableOtherInputs}
            selectedOptions={selectedOrigem}
            setSelectedOptions={setSelectedOptionsOrigem}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOrigem,
              description: 'Origem',
              fieldName: 'origem',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={caraterBenigno}
            disabled={disableOtherInputs}
            label="Lesões de Caráter Benigno"
            selectedOptions={selectedCarater}
            setSelectedOptions={setSelectedOptionsCarater}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedCarater,
              description: 'Lesões de Caráter Benigno',
              fieldName: 'lesoes_benigno',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={escamosa}
            disabled={disableOtherInputs}
            selectedOptions={selectedEscamosa}
            setSelectedOptions={setSelectedOptionsEscamosa}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedEscamosa,
              description:
                'Lesões de Caráter Neoplásico ou Pré-Neoplásico - Escamosa',
              fieldName: 'lesoes_neoplasico_escamosa',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
            // options={escamosa}
            label="Lesões de Caráter Neoplásico ou Pré-Neoplásico - Escamosa"
            // setSelected={handleSetSelectedOptions(setLaboratoriosSelecionados)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectWithSearch
            options={glandular}
            disabled={disableOtherInputs}
            selectedOptions={selectedGlandular}
            setSelectedOptions={setSelectedOptionsGlandular}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedGlandular,
              description:
                'Lesões de Caráter Neoplásico ou Pré-Neoplásico - Glandular',
              fieldName: 'lesoes_neoplasico_glandular',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
            label="Lesões de Caráter Neoplásico ou Pré-Neoplásico - Glandular"
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12}>
          <SelectWithSearch
            options={optionsLaboratorios}
            label="Laboratório Responsável pelo Laudo"
            isLaboratorio
            disabled={
              isLaboratorio
                ? true
                : disableOtherInputs || disabledLaboratorioResultado
            }
            selectedOptions={selectedOptionsLaboratorio}
            setSelectedOptions={setSelectedOptionsLaboratorio}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedLaboratorio,
              description: 'Laboratório Responsável pelo Laudo',
              fieldName: 'laboratorio_responsavel_laudo',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="profissional_responsavel_resultado"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  data-testid="inputProfissionalResultadoId"
                  disabled={
                    isMedicoLaboratorio
                      ? true
                      : disableOtherInputs || disabledProfissionalResultado
                  }
                  options={optionsProfissionaisLaboratorio}
                  noOptionsText={
                    selectedLaboratorio?.length > 0
                      ? 'Não há opções'
                      : 'Selecione ao menos um laboratório para visualizar a lista de profissionais'
                  }
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    handleSetFilter(
                      `Profissional Responsável pelo Resultado: ${newValue?.nomeprofissional}`,
                      'profissional_responsavel_resultado'
                    );
                  }}
                  value={value ? value : ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      data-testid="profissionalLaudo"
                      label="Profissional Responsável pelo Resultado"
                    />
                  )}
                />
              );
            }}
          />
          {/* <FormControl fullWidth>
            <InputLabel
              id="profissional-label"
              sx={{
                top: '-7px',
              }}
              required
            >
              Profissional Responsável pelo Resultado
            </InputLabel>
            <Select
              labelId="profissional-label"
              id="profissional"
              //   value={profissional}
              label="Age"
              //   onChange={handleChange}
              size="small"
            >
              <MenuItem value="TODOOS">Todos</MenuItem>
              <MenuItem value="PROFISSIONAL1">Profissional 1</MenuItem>
              <MenuItem value="PROFISSIONAL2">Profissional 2</MenuItem>
            </Select>
          </FormControl> */}
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
            flexWrap="wrap"
          >
            <Typography fontWeight="bold">Data do Resultado:</Typography>

            <FormControl>
              <RadioGroup
                aria-labelledby="handleSetPeriod"
                name="handleSetPeriod"
                value={periodDtResultado}
                onChange={handleSetPeriodDtResultado}
                row
              >
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  label="Filtrar por Data"
                />
                <FormControlLabel
                  value="period"
                  control={<Radio />}
                  label="Filtrar por período"
                />
              </RadioGroup>
            </FormControl>

            {periodDtResultado === 'period' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex="1"
                flexWrap="wrap"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    onChange={(date) => {
                      handleSetFilter(
                        'Início da Data do Resultado:' +
                          moment(date).format('DD/MM/YYYY'),
                        'dt_resultado_inicio'
                      );
                      setDataResultadoIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                    }}
                    disabled={disableOtherInputs}
                    maxDate={new Date()}
                    value={
                      dtResultadoIni &&
                      moment(dtResultadoIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtResultadoIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Início da Data Resultado"
                        size="small"
                        disabled={disableOtherInputs}
                        variant="outlined"
                        sx={{ minWidth: '200px' }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    onChange={(date) => {
                      handleSetFilter(
                        'Fim da Data do Resultado:' +
                          moment(date).format('DD/MM/YYYY'),
                        'dt_resultado_fim'
                      );
                      setDataResultadoFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                    }}
                    disabled={disableOtherInputs}
                    maxDate={new Date()}
                    value={
                      dtResultadoFinal &&
                      moment(dtResultadoFinal, 'DD-MM-YYYY').isValid()
                        ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        disabled={disableOtherInputs}
                        label="Fim da Data de Resultado"
                        size="small"
                        error={!!errorInputDateResultado?.fin}
                        helperText={errorInputDateResultado?.fin}
                        variant="outlined"
                        sx={{
                          minWidth: '200px',
                          marginBottom: !!errorInputDateResultado?.fin
                            ? '-20px'
                            : '',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {periodDtResultado === 'date' && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    onChange={(date) => {
                      setDataResultadoIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      setDataResultadoFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );

                      handleSetFilter(
                        'Data do Resultado:' +
                          moment(date).format('DD-MM-YYYY'),
                        'dt_resultado_fim'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtResultadoFinal &&
                      moment(dtResultadoFinal, 'DD-MM-YYYY').isValid()
                        ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Data de Resultado"
                        disabled={disableOtherInputs}
                        size="small"
                        variant="outlined"
                        sx={{ minWidth: '200px', flex: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gap={2} my={2}>
            <Controller
              name="search"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    data-testid="inputSearchByNameCpf"
                    disabled={disableOtherInputs}
                    label="Pesquisar"
                    size="small"
                    error={!!errors?.search}
                    helperText={errors?.search?.message}
                    placeholder="Pesquise por Nome, Nº do Cartão SUS ou CPF da paciente"
                    sx={{
                      '.MuiOutlinedInput-root': { paddingRight: '9px' },
                    }}
                    value={value ? value : ''}
                    onChange={(e) => {
                      onChange(e.target.value);
                      handleSetFilter(e.target.value, 'search');
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <Button
                          sx={{ padding: 0, minWidth: '24px' }}
                          disabled={disableOtherInputs}
                        >
                          <SearchIcon
                            sx={{
                              cursor: 'pointer',
                              color: 'gray',
                            }}
                          />
                        </Button>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }}
            />

            <Button
              sx={{ padding: '6px 22px' }}
              variant="contained"
              type="submit"
            >
              Filtrar
            </Button>

            <Button
              sx={{ padding: '6px 22px' }}
              variant="outlined"
              color="error"
              onClick={clearValues}
            >
              Limpar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersReportsHistopatologia;
